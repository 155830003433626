<template>
  <v-card class="card" dark>
    <v-card-title class="card-title">
      <section class="w-100 d-flex justify-space-between align-center">
        <h3 class="white--text">Rewards</h3>
        <v-icon @click="$emit('close')">mdi-close-circle-outline</v-icon>
      </section>
    </v-card-title>
    <v-card-text class="py-3 card-text">
      <v-row class="pt-2" no-gutters v-if="rewards.length">
        <Award
          v-for="(reward, i) in rewards"
          :key="i"
          :award="reward"
        ></Award>
      </v-row>
    </v-card-text>

    <v-card-actions class="card-actions">
      <!-- <v-btn
          v-if="$route.name === 'shop'"
          min-width="150"
          class="btn buy-btn mx-auto"
          :loading="isLoading"
          @click="buyItem(selectedItem)"
        >
          Buy Now
          <v-icon right size="20">mdi-cart</v-icon>
        </v-btn>
        <v-btn
          v-if="$route.name === 'assets'"
          min-width="150"
          class="btn sell-btn mx-auto"
          @click="addToSale(selectedItem)"
        >
          Sell
          <v-icon right size="20">mdi-cart</v-icon>
        </v-btn> -->
    </v-card-actions>
  </v-card>
</template>

<script>
import Award from '../../components/shop/detailCard/content/lootboxDetails/tabs/award/Award.vue'
export default {
  props:['rewards'],
  components:{
    Award
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/components/dialogCard.scss";
.card-text{
  max-height: 65vh;
  overflow: auto;
}
</style>
