<template>
  <section class="">
    <v-row class="pt-2" no-gutters v-if="selectedLootbox.awards.length">
      <Award
        v-for="(award, i) in selectedLootbox.awards"
        :key="i"
        :award="award"
      ></Award>
      <v-btn
        class="open-btn mt-2 mx-auto"
        outlined
        color="var(--secondary-color)"
        @click="openLootbox"
        >open lootbox</v-btn
      >
    </v-row>
    <h3 class="no-award" v-else>No award</h3>
  </section>
</template>

<script>
import Award from "../award/Award.vue";
export default {
  props: ["selectedLootbox"],
  components: {
    Award,
  },
  created() {
  },
  methods:{
    openLootbox(){
      this.$router.push({name:'gift-opening',params:{id : this.selectedLootbox.id}})
    }
  }
};
</script>

<style lang="scss" scoped>
.open-btn {
  width: 95%;
  transition: all .3s ease;
  &:hover {
    background-color: var(--secondary-color) !important;
    color: black !important;
    font-weight: 900;
  }
}
.no-award {
  height: 20vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
