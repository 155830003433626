<template>
  <section class="story py-3 px-2">
    <h3 class="text-center">{{ selectedLegend.legend.description }}</h3>
  </section>
</template>

<script>
export default {
  props: ["selectedLegend"],
};
</script>

<style></style>
