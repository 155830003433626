<template>
  <section class="land-details">
    <div class="land-details-head">
      <div style="width: 100%; height: 45vh" v-if="hasAnimate">
        <iframe
          :src="`${storageImageURL(selectedPack.animate)}?autostart=1`"
          frameborder="0"
          width="100%"
          height="100%"
        ></iframe>
      </div>
      <v-img
        :src="storageImageURL(selectedPack.image)"
        width="100%"
        max-width="394"
        min-height="225px"
        max-height="250px"
        contain
        class="land-img mx-auto"
        @load="imgLoaded"
      >
        <v-skeleton-loader
          type="image@2"
          class="land-img-skeleton"
          v-if="!imgIsLoaded"
          width="100%"
          height="225"
        ></v-skeleton-loader>
      </v-img>
      <h3 class="land-name">
        {{ selectedPack.name }}
      </h3>
    </div>
    <main class="land-details-content">
      <div class="base-tabs">
        <ul class="d-flex">
          <li
            v-for="(item, i) in baseTabs"
            :key="i"
            @click="changeTabs(item)"
            class="white--text"
            :class="item.isActive ? 'active' : ''"
          >
            {{ item.name }}
          </li>
        </ul>
      </div>
      <div class="line"></div>
      <PackContent
        v-if="baseTabs[0].isActive"
        :items="selectedPack.items"
        @packIShowDetails="packIShowDetails"
      />
      <Info v-if="baseTabs[1].isActive" :selectedPack="selectedPack" />
    </main>
  </section>
</template>

<script>
import Info from "./tabs/Info.vue";
import PackContent from "./tabs/packContents/PackContents.vue";
import { storageImage } from "@/mixins/storageImage.js";
export default {
  mixins: [storageImage],
  props: ["selectedPack"],
  data() {
    return {
      baseTabs: [
        {
          name: "Content",
          isActive: true,
        },
        {
          name: "Info",
          isActive: false,
        },
      ],
      imgIsLoaded: false,
    };
  },
  components: {
    Info,
    PackContent,
  },
  created() {},
  computed: {
    hasAnimate() {
      return this.selectedPack.animate && !!this.selectedPack.animate.length;
    },
  },
  methods: {
    changeTabs(item) {
      //change tab from old to new and save in session
      let oldTab = this.baseTabs.find((element) => element.isActive === true);
      oldTab.isActive = !oldTab.isActive;
      let newTab = this.baseTabs.find((element) => element.name === item.name);
      newTab.isActive = !newTab.isActive;
    },
    packIShowDetails(item) {
      this.$emit("packItemShowDetails", item);
    },

    imgLoaded() {
      this.imgIsLoaded = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.land-details {
  &-head {
    .land-img {
      border-radius: 10px;
    }
    .land-name {
      color: white;
      margin: 10px;
      text-align: center;
    }
  }
}
</style>
