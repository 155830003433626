<template>
  <section class="filters-box">
    <section
      class="d-flex justify-space-between align-center py-8 px-2"
      style="height: 45px"
    >
      <div class="d-flex">
        <v-icon color="var(--secondary-color)" size="30" class="mr-2"
          >mdi-filter-variant</v-icon
        >
        <h3 style="color: var(--secondary-color)">Filters</h3>
      </div>
      <section class="actions mt-2 d-flex justify-end" v-if="hasFilter">
        <v-btn class="reset-btn" icon @click="resetFilters">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-btn
          icon
          color="var(--secondary-color)"
          class="apply-btn"
          @click="submitFilters"
        >
          <v-icon>mdi-check</v-icon>
        </v-btn>
      </section>
    </section>
    <section class="ex-panels">
      <v-expansion-panels multiple v-model="panel">
        <v-expansion-panel>
          <v-expansion-panel-header>ID</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-text-field
              v-model.trim="id"
              label="ID"
              type="number"
              outlined
              hide-details
              dense
              clearable
              dark
            ></v-text-field>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>Country</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-select
              v-model="selectedCountry"
              :items="countryItems"
              item-text="name"
              item-value="id"
              label="Countries List"
              solo
              dense
              clearable
              hide-details
              item-color="green"
              :menu-props="{ contentClass: 'country-list' }"
            >
              <template v-slot:selection="{ item }">
                <img :src="item.flag" width="20" class="me-1 rounded" />
                {{ item.name }}
              </template>
              <template v-slot:item="{ item }">
                <img :src="item.flag" width="40" class="me-1 rounded" />{{
                  item.name
                }}
              </template>
            </v-select>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>Specification</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-select
              v-model="selectedSpecifications"
              :items="existSpecifications"
              item-text="title"
              item-value="id"
              class="Specifications-select"
              label="Specifications List"
              solo
              dense
              clearable
              multiple
              chips
              small-chips
              deletable-chips
              hide-details
              item-color="green"
            ></v-select>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- <v-expansion-panel>
          <v-expansion-panel-header>Size</v-expansion-panel-header>
          <v-expansion-panel-content>
            <h1 class="white--text mb-3">Width:</h1>
            <v-text-field
              :value="widthRange[0]"
              hide-details
              outlined
              dense
              type="number"
              label="Minimum"
              clearable
              dark
              @change="$set(widthRange, 1, $event)"
            ></v-text-field>
            <v-text-field
              :value="widthRange[1]"
              class="my-2"
              hide-details
              outlined
              dense
              type="number"
              label="Maximum"
              clearable
              dark
              @change="$set(widthRange, 1, $event)"
            ></v-text-field>
            <v-range-slider
              v-model="widthRange"
              :min="minWidth"
              :max="maxWidth"
              hide-details
              class="align-center"
            >
            </v-range-slider>
            <div class="d-flex justify-space-between">
              <div style="width: 60px" class="d-flex">
                <p class="grey--text mr-1">Min:</p>
                <p class="white--text">{{ minWidth }}</p>
              </div>
              <div style="width: 60px" class="d-flex mr-1">
                <p class="grey--text">Max:</p>
                <p class="white--text">{{ maxWidth }}</p>
              </div>
            </div>

            <v-divider class="white my-4"></v-divider>

            <h1 class="white--text mb-3">height:</h1>
            <v-text-field
              :value="heightRange[0]"
              hide-details
              outlined
              dense
              type="number"
              label="Minimum"
              clearable
              dark
              @change="$set(heightRange, 1, $event)"
            ></v-text-field>
            <v-text-field
              :value="heightRange[1]"
              class="my-2"
              hide-details
              outlined
              dense
              type="number"
              label="Maximum"
              clearable
              dark
              @change="$set(heightRange, 1, $event)"
            ></v-text-field>
            <v-range-slider
              v-model="heightRange"
              :min="minHeight"
              :max="maxHeight"
              hide-details
              class="align-center"
            >
            </v-range-slider>
            <div class="d-flex justify-space-between">
              <div style="width: 60px" class="d-flex">
                <p class="grey--text mr-1">Min:</p>
                <p class="white--text">{{ minHeight }}</p>
              </div>
              <div style="width: 60px" class="d-flex mr-1">
                <p class="grey--text">Max:</p>
                <p class="white--text">{{ maxHeight }}</p>
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel> -->

        <v-expansion-panel>
          <v-expansion-panel-header>Name</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-text-field
              v-model.trim="name"
              label="name"
              type="text"
              outlined
              hide-details
              dense
              clearable
              dark
            ></v-text-field>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>Price</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-range-slider
              v-model="priceRange"
              :max="maxPrice"
              :min="minPrice"
              hide-details
              class="align-center green--text"
              color="#45f882"
              track-color="#fff"
            >
            </v-range-slider>
            <div class="d-flex justify-space-between align-center">
              <div style="width: 40%">
                <v-text-field
                  :value="priceRange[0]"
                  hide-details
                  solo
                  type="number"
                  dark
                  class="input"
                  @change="$set(priceRange, 0, $event)"
                ></v-text-field>
              </div>
              <span class="white--text">-</span>
              <div style="width: 40%">
                <v-text-field
                  :value="priceRange[1]"
                  hide-details
                  solo
                  type="number"
                  dark
                  class="input"
                  @change="$set(priceRange, 1, $event)"
                ></v-text-field>
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </section>
  </section>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      panel: [0, 1, 2, 3],
      countryItems: [],
      selectedCountry: 0,
      subCatLoading: false,
      isFirstSubmit: true,
      //id
      id: null,
      // Specification
      selectedSpecifications: [],
      existSpecifications: [],
      // Specification
      // size
      // minWidth: 0,
      // maxWidth: 16,
      // widthRange: [0, 16],
      // minHeight: 0,
      // maxHeight: 16,
      // heightRange: [0, 16],
      // size

      //name
      name: null,

      // price
      minPrice: 1,
      maxPrice: 100000,
      priceRange: [1, 100000],
      // price
    };
  },
  created() {
    if (this.$route.query.type) {
      this.id = this.$route.query.id;
    }
    Promise.all([
      this.fetchCountries(),
      this.fetchPriceRange(),
      this.fetchTags(),
    ]).then(() => {
      if (this.$route.query.type) {
        this.submitFilters();
      }
    });
  },
  methods: {
    ...mapActions([
      "getLegendCountries",
      "getTags",
      "getMaxCordinate",
      "getPriceRange",
    ]),
    fetchCountries() {
      return new Promise((resolve) => {
        this.getLegendCountries().then((result) => {
          this.countryItems = result.result;
          if (this.$route.query.country) {
            this.selectedCountry = this.countryItems.find(
              (ele) => ele.id == this.$route.query.country
            );
          } else {
            resolve();
          }
        });
      });
    },
    fetchTags() {
      return new Promise((resolve) => {
        this.getTags("legend").then((result) => {
          this.existSpecifications = result.result;
          if (this.$route.query.specifications) {
            const urlTags = this.$route.query.specifications;
            urlTags.forEach((uTag) => {
              const findedOjb = this.existSpecifications.find(
                (ele) => ele.id === +uTag
              );
              this.selectedSpecifications.push(findedOjb);
            });
          }
          resolve();
        });
      });
    },
    fetchPriceRange() {
      return new Promise((resolve) => {
        this.getPriceRange("legend").then((result) => {
          this.minPrice = result.result.minPrice;
          this.maxPrice = result.result.maxPrice;
          this.priceRange = [this.minPrice, this.maxPrice];

          if (this.$route.query.minPriceRange) {
            this.priceRange[0] = this.$route.query.minPriceRange;
          }
          if (this.$route.query.maxPriceRange) {
            this.priceRange[1] = this.$route.query.maxPriceRange;
          }
          resolve();
        });
      });
    },
    submitFilters() {
      let submitedFilters = {
        id: this.id,
        name: this.name,
        selectedCountry: this.selectedCountry,
        priceRange: this.priceRange,
        selectedSpecifications: this.selectedSpecifications.map((ele) =>
          typeof ele === "object" ? ele.id : ele
        ),
      };
      this.$emit("submitFilters", submitedFilters);
      if (this.isFirstSubmit) {
        if (!this.$route.query.type) {
          this.addFiltersToUrl();
        }
        this.isFirstSubmit = false;
      } else {
        this.addFiltersToUrl();
      }
    },
    resetFilters() {
      this.selectedCountry = 0;
      this.name = null;
      this.priceRange = [this.minPrice, this.maxPrice];
      this.selectedSpecifications = [];
      this.id = null;
      this.submitFilters();
    },
    addFiltersToUrl() {
      let newUrlQuery = {};
      newUrlQuery.type = "legend"; //this is just for url
      if (this.id) newUrlQuery.id = this.id;
      if (this.selectedCountry)
        //because of vuetify select
        newUrlQuery.country = this.selectedCountry;
      if (this.priceRange[0] !== this.minPrice)
        newUrlQuery.minPriceRange = this.priceRange[0];
      if (this.priceRange[1] !== this.maxPrice)
        newUrlQuery.maxPriceRange = this.priceRange[1];
      if (this.selectedSpecifications)
        newUrlQuery.specifications = this.selectedSpecifications;

      this.$router
        .replace({ name: "shop", query: newUrlQuery })
        .catch(() => {});
    },
  },
  computed: {
    hasFilter() {
      if (
        (this.id && this.id !== null) ||
        (this.name && this.name !== null) ||
        this.selectedCountry ||
        this.priceRange[0] !== this.minPrice ||
        this.priceRange[1] !== this.maxPrice ||
        this.selectedSpecifications.length > 0
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
