<template>
  <section class="mobile-filters px-1 px-sm-6 d-flex justify-space-between align-center">
    <v-btn @click="drawer = !drawer" small outlined class="toggle-btn my-1">
      <v-icon small left>mdi-filter</v-icon> Filters
      <v-icon small right>mdi-chevron-right</v-icon>
    </v-btn>
    <div class="white--text d-md-none">{{ total }} <span class="">Result(s)</span></div>

    <v-navigation-drawer v-model="drawer" absolute class="nav-drawer" height="auto">
      <component
        :is="`${currentComp}Filters`"
        @submitFilters="submitFiltersMobile"
        class="pt-5"
      ></component>

      <!-- <LandFilters
        @submitFilters="submitFiltersMobile"
        class="mt-10"
        v-if="type === 'land' && drawer"
      />
      <LootboxFilters
        @submitFilters="submitFiltersMobile"
        class="mt-10"
        v-if="type === 'lootbox' && drawer"
      />
      , ...
       -->
    </v-navigation-drawer>
  </section>
</template>

<script>
import LandFilters from "../lands/LandFilters.vue";
import LootboxFilters from "../lootboxes/LootboxFilters.vue";
import BuildingFilters from "../buildings/BuildingFilters.vue";
import DecorativeFilters from "../decoratives/DecorativeFilters.vue";
import UnitFilters from "../units/UnitFilters.vue";
import CurrencyFilters from "../currencies/CurrencyFilters.vue";
import PackFilters from "../packs/PackFilters.vue";
import LegendFilters from "../legends/LegendFilters.vue";
export default {
  props: ["total", "type"],
  data() {
    return {
      drawer: false,
      currentComp: this.type,
    };
  },
  components: {
    LandFilters,
    LootboxFilters,
    BuildingFilters,
    DecorativeFilters,
    UnitFilters,
    CurrencyFilters,
    PackFilters,
    LegendFilters
  },
  created() {
    if (this.$route.query.type && window.innerWidth < 979) this.drawer = true;
  },
  methods: {
    submitFiltersMobile(submitedFilters) {
      this.$emit("submitFilters", submitedFilters);
    },
  },
};
</script>

<style lang="scss">
.mobile-filters {
  width: 100%;
  .nav-drawer {
    z-index: 220 !important;
    background: var(--background-color) !important;
  }
  .toggle-btn {
    color: var(--secondary-color);
    transition: all 0.2s linear;
    width: 150px;
    &:hover {
      background: var(--secondary-color);
      color: var(--background-color);
      .v-icon {
        color: var(--background-color);
      }
    }
    @media only screen and(min-width:980px) {
      display: none;
    }
    .v-icon {
      color: var(--secondary-color);
      margin-top: 2px;
    }
  }
}
</style>
