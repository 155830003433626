import mainApi from "../../plugins/axios/axios.js";
const state = {};

const mutations = {};

const actions = {
  getPaymentList(_, page) {
    return new Promise((resolve, reject) => {
        mainApi
            .get(`/users/payments`, {
                params: { page: page }
            })
            .then((result) => {
                resolve(result.data);
            })
            .catch((err) => {
               
                reject();
            });
    });
},
createPayment(_, info) {
    return new Promise((resolve, reject) => {
        mainApi
            .post(`/users/payments`, info)
            .then((result) => {
                resolve(result.data);
            })
            .catch((err) => {
               
                reject();
            });
    });
},
getUserCoins() {
    return new Promise((resolve, reject) => {
        mainApi
            .get(`/users/coins`)
            .then((result) => {
                resolve(result.data);
            })
            .catch((err) => {
               
                reject();
            });
    });
},

getWithdrawHistory(_, page) {
  return new Promise((resolve, reject) => {
      mainApi
          .get(`/users/reports/withdraws`, {
              params: { page: page }
          })
          .then((result) => {
              resolve(result.data);
          })
          .catch((err) => {
             
              reject();
          });
  });
},
getDepositHistory(_, page) {
  return new Promise((resolve, reject) => {
      mainApi
          .get(`/users/reports/deposits`, {
              params: { page: page }
          })
          .then((result) => {
              resolve(result.data);
          })
          .catch((err) => {
             
              reject();
          });
  });
},
};
const getters = {};

export default {
  state,
  getters,
  mutations,
  actions,
};
