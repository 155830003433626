const state = {
  rules: {
    required: [(v) => !!v || "This Field is required"],
    email_rules: [
      (v) => !!v || "Please enter your email address",
      (v) => /.+@.+\..+/.test(v) || "Email Address not valid",
    ],
    wallet_address_rules: [
      (v) => !!v || "This Field is required",
      (v) => /^0x[a-fA-F0-9]{40}$/.test(v) || "Invalid wallet address",
    ],
    name_rules: [(v) => !!v || "Please enter your name"],
    message_rules: [(v) => !!v || "Please enter your message"],
    password_rules: [
      (v) => !!v || "Please enter your password",
      (v) => (v && v.length >= 6) || "Password must be at least 8 characters",
    ],
    password_confirmation_rules: [(v) => !!v || "Please enter your password"],
    amount_rules: [(v) => !!v || "Please enter amount"],
    select: [(v) => !!v || "This Field is required"],
  },
  serverValidationErrors: [],
};

const getters = {
  rules: (state) => state.rules,
  serverValidationErrors: (state) => state.serverValidationErrors,
};

const mutations = {
  setServerValidationError(state, error) {
    state.serverValidationErrors.push(error);
  },
  removeServerValidationError(state,filed){
    let itemShouldRemove = state.serverValidationErrors.find(element => element.filed === filed);
    let index = state.serverValidationErrors.indexOf(itemShouldRemove)
    state.serverValidationErrors.splice(index,1);
  },
  clearServarValidations(state){
    state.serverValidationErrors = []
  }
};

const actions = {
  setServerValidationError({ commit }, error) {
    commit("setServerValidationError", error);
  },
  removeServerValidationError({ commit }, filed) {
    commit("removeServerValidationError", filed);
  },
  clearServarValidations({ commit }){
    commit("clearServarValidations");
  }
};

export default {
  state,
  getters,
  mutations,
  actions,
};
