<template>
  <section class="map-item">
    <v-card class="card" dark>
      <v-card-title class="card-title">
        <section class="w-100 d-flex justify-space-between align-center">
          <h3 class="white--text">Location details</h3>
          <v-btn color="white" outlined icon small @click="close">
            <v-icon small>mdi-close</v-icon>
          </v-btn>
        </section>
      </v-card-title>
      <v-card-text
        class="py-3 card-text"
        :style="
          selectedItem.Land
            ? `background:url(${storageImageLink(
                selectedItem.Land.image
              )}) no-repeat; background-size:100%;`
            : ''
        "
      >
        <main class="main-content">
          <section class="location-details">
            <h3 class="mr-1 text-center" style="color: var(--secondary-color)">
              {{ assignLandRender(selectedItem) }}
            </h3>

            <div class="details" style="z-index: 1000000">
              <section class="my-8">
                <div
                  class="pair yellow--text d-flex justify-space-between"
                  style="min-width: 80px"
                >
                  Location id:
                  <span class="white--text">{{ selectedItem.id }}</span>
                </div>
                <div
                  class="pair yellow--text d-flex justify-space-between"
                  style="min-width: 80px"
                >
                  Country
                  <span class="white--text">{{
                    selectedItem.category ? selectedItem.category.title : "No Country"
                  }}</span>
                </div>
                <div
                  class="pair yellow--text d-flex justify-space-between"
                  style="min-width: 80px"
                >
                  Country id
                  <span class="white--text">{{
                    selectedItem.category ? selectedItem.category.id : "No Country"
                  }}</span>
                </div>
                <div
                  class="pair yellow--text d-flex justify-space-between"
                  style="min-width: 80px"
                >
                  Coordinate X
                  <span class="white--text">{{
                    selectedItem.tile ? selectedItem.tile.coordinate_x : "No Coordinate X"
                  }}</span>
                </div>
                <div
                  class="pair yellow--text d-flex justify-space-between"
                  style="min-width: 80px"
                >
                  Coordinate Y
                  <span class="white--text">{{
                    selectedItem.tile ? selectedItem.tile.coordinate_y : "No Coordinate Y"
                  }}</span>
                </div>
              </section>
            </div>
            <!-- <v-img
              class=""
              :src="`https://test.landsofazolite.com/api/${selectedItem.category.art_portrait}`"
              max-width="220"
              max-height="220"
            ></v-img> -->
          </section>

          <v-expansion-panels v-model="panel" v-if="!selectedItem.Land">
            <v-expansion-panel :disabled="!token">
              <v-expansion-panel-header class="orange--text">
                <h5>
                  {{
                    token
                      ? "Assign your land to this location"
                      : "To assign land to this location you need to Login first"
                  }}
                </h5></v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <div class="tags">
                  <section class="">
                    <h5 class="grey--text mb-2">
                      To assign your lands to this location select your land first.
                    </h5>
                    <v-form ref="assignForm">
                      <v-select
                        v-model="selectedLand"
                        :items="finallLands"
                        :loading="getLandsLoading"
                        item-value="land.id"
                        label="User land List"
                        :menu-props="{ bottom: true, offsetY: true }"
                        :rules="rules.select"
                        outlined
                        dense
                        clearable
                        dark
                      >
                        <template v-slot:selection="{ item }">
                          {{ getText(item) }}
                        </template>
                        <template v-slot:item="{ item }">
                          <h6>{{ getText(item) }}</h6>
                        </template>
                      </v-select>
                      <div class="actions d-flex justify-end">
                        <v-btn
                          color="success"
                          min-width="150"
                          text
                          style="font-weight: 900"
                          :loading="isLoading"
                          @click="assignLandtoThisLocation"
                        >
                          Assign to this location
                        </v-btn>
                      </div>
                    </v-form>
                  </section>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <h3 class="grey darken-4 pa-3 br-5 text-center success--text" v-else>
            This location assigned to land id {{ selectedItem.Land.id }}
          </h3>

          <!-- <v-btn class="w-100 mt-2 black--text" color="success" v-if="selectedItem.land">
          Buy Now
          <v-icon right size="15">mdi-cart</v-icon>
        </v-btn> -->
        </main>
      </v-card-text>
    </v-card>
    <!--quastion dialog -->
    <v-dialog v-model="quastionDialog" width="500">
      <QuastionCard
        @cancel="quastionDialog = false"
        text="Are you sure to assign your land to this location ? "
        :isLoading="isLoading"
        @accept="accept"
      ></QuastionCard>
    </v-dialog>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: ["selectedItem", "finallLands", "getLandsLoading"],
  data() {
    return {
      isLoading: false,
      selectedLand: null,
      panel: 1,
      quastionDialog: false,
    };
  },
  computed: {
    ...mapGetters(["rules", "token"]),
  },
  methods: {
    ...mapActions(["getUserLands", "assignLandToLocation"]),
    getText(item) {
      return ` ID:${item.id} , ${item.land.name}`;
    },
    close() {
      if (this.panel == 0 && !this.selectedItem.land) {
        this.$refs.assignForm.reset();
      }
      this.$emit("close");
    },

    assignLandtoThisLocation() {
      if (this.$refs.assignForm.validate()) {
        this.quastionDialog = true;
      }
    },
    accept() {
      this.quastionDialog = false;
      const info = {
        land_id: this.selectedLand,
        coordinate_x: this.selectedItem.tile.coordinate_x,
        coordinate_y: this.selectedItem.tile.coordinate_y,
      };
      this.isLoading = true;
      this.assignLandToLocation(info)
        .then(() => {})
        .catch(() => {})
        .finally(() => {
          this.isLoading = false;
          this.$emit("finallyAssignDone");
          this.$refs.assignForm.reset();
          this.$refs.assignForm.resetValidation();
        });
    },
    assignLandRender(item) {
      if (!item.Land) {
        return "This Location is Free for assign";
      } else {
        return `Taken with ${item.Land.name}`;
      }
    },
    storageImageLink(img) {
      return `${process.env.VUE_APP_ROOT_API}/${img}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/components/dialogCard.scss";
.map-item {
  .card {
    .card-text {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.322);
        z-index: 0;
      }
      .main-content {
        z-index: 2;
        position: relative;
      }
    }
    .pair {
      // border-bottom: 1px solid rgb(148, 148, 148);
      background: rgba(27, 42, 175, 0.767);
      margin-bottom: 3px;
      border-radius: 5px;
      padding: 0 10px;
    }
  }
}
</style>
