<template>
  <div class="auth-wrapper">
    <section class="auth-card">
      <h2 class="text-center white--text mb-2">Reset Password</h2>
      <template v-if="!isEmailSent">
        <h5 class="text-center grey--text my-5">
          Enter your registered azollite email address to receive password reset
          instruction .
        </h5>
        <v-form @submit.prevent="sendEmail" ref="emailForm">
          <v-text-field
            label="Email"
            type="email"
            :rules="rules.email_rules"
            v-model.trim="enteredEmail"
            outlined
            dense
            dark
            placeholder="Type your email"
            :error-messages="
              serverValidationErrorsRender(serverValidationErrors, 'email')
            "
            @input="removeServerValidation('email')"
          >
            <v-icon slot="append">mdi-email</v-icon></v-text-field
          >
          <v-btn
            type="submit"
            class="login-btn w-100"
            :loading="loading"
            :disabled="loading"
          >
            Send Email
          </v-btn>
        </v-form>
        <div class="text-center mt-10">
          <p class="white--text">Already have account and password?</p>
          <routerLink :to="{ name: 'login' }" class="auth-link"> Sign In </routerLink>
        </div>
      </template>
      <template v-else>
        <v-img
          src="../../../assets/images/resetPassword/email-icon6.png"
          contain
          max-width="160"
          class="mx-auto"
          aspect-ratio="1"
        ></v-img>
        <h3 class="text-center" style="color: #3ce325">Check your email</h3>
        <p class="grey--text text-center">
          We have sent a password recovery instruction to your email.
        </p>
        <routerLink :to="{ name: 'login' }" class="mt-2 d-block text-center">
          Back to login
        </routerLink>
      </template>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      loading: false,
      enteredEmail: "",
      isEmailSent: false,
    };
  },
  computed: {
    ...mapGetters(["rules", "serverValidationErrors"]),
  },

  methods: {
    ...mapActions(["resetPasswordEmailSend", "removeServerValidationError"]),
    serverValidationErrorsRender(errors, filed) {
      let currectValidateError = errors.find((element) => element.filed === filed);
      return currectValidateError ? currectValidateError.message : null;
    },
    removeServerValidation(filed) {
      this.removeServerValidationError(filed);
    },
    sendEmail() {
      if (this.$refs.emailForm.validate()) {
        this.loading = true;
        this.resetPasswordEmailSend({ email: this.enteredEmail })
          .then((result) => {
            
            this.isEmailSent = true;
          })
          .catch((err) => {
            
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.auth-wrapper {
  background-image: url("../../../assets/images/resetPassword/Lock-bg.jpg");
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  .auth-card {
    width: 98%;
    max-width: 400px;
    background: linear-gradient(37deg, black, #004c4f33);
    backdrop-filter: blur(5px);
    border-radius: 8px;
    padding: 30px 35px;
    min-height: 373.8px;
    @media only screen and(max-width : 380px) {
      transform: scale(0.9);
      padding: 20px 25px;
      margin-top: 30px;
    }
    .captcha-container {
      .captch {
        margin: 0 auto;
        @media only screen and(max-width : 380px) {
          transform: scale(0.9);
          transform-origin: left;
        }
        @media only screen and(max-width : 350px) {
          transform: scale(0.8);
        }
      }
    }
    .login-btn {
      background: var(--light-blue) !important;
      color: black;
      font-weight: bolder;
      transition: all 0.2s linear;
      box-shadow: none;

      &:hover {
        letter-spacing: 2px;
        box-shadow: 0 0 12px var(--light-blue);
      }
    }
    .auth-link {
      transition: all 0.2s linear;
      &:hover {
        letter-spacing: 5px;
      }
    }
    .forgot-pass {
      display: flex;
      justify-content: end;
      font-size: 14px;
      color: var(--light-blue);
      margin-bottom: 20px;
    }
  }
}
::v-deep .v-text-field {
  width: 100%;
  input {
    &::placeholder {
      color: rgb(255, 255, 255) !important;
      filter: brightness(0.8);
    }
  }
  fieldset {
    border: 2px solid rgb(255, 255, 255) !important;
  }
  // .v-text-field__details {
  //   margin: 0;
  // }
}
</style>
