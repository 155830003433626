<template>
  <v-card class="card">
    <v-card-title class="card-title">
      <h4 class="white--text">Telegram Bot</h4>
    </v-card-title>

    <v-card-text class="mt-5 white--text text-center">
      <template v-if="!user.telegram">
        <p>
          To connect and recive info in telegram, please send "{{
            telegramCode
          }}" to our telegram bot
          <a :href="telegramURL" target="_blank" class="text-primary">{{
            telegramName
          }}</a>
          we will automatically confirm you after sending the code
        </p>

        <div class="my-3 d-flex align-center justify-center">
          <v-btn color="white" text class="mr-2" @click="openTelegramLink()">
            Open Link
          </v-btn>
        </div>

        <div>
          <p v-if="generate_new_code" class="mb-0 mt-3 mt-sm-0 text-primary">
            <a @click="generate_code_for_telegram" class="pointer">
              Generate New Code
            </a>
          </p>

          <CountDown
            v-else
            :key="generateNewCodeKey"
            @timeIsOver="timeIsOver"
            :time="180"
            subject="New Code Generated In"
          />
        </div>
      </template>

      <template v-else>
        <h3 class="mb-3">Telegram Connected</h3>

        <p>To disable telegram press disable button</p>
      </template>
    </v-card-text>

    <v-divider dark></v-divider>

    <v-card-actions class="card-actions">
      <v-spacer></v-spacer>
      <v-btn color="error" text @click="$emit('close')"> Cancel </v-btn>
      <v-btn
        color="success"
        text
        :loading="loading"
        @click="user.telegram ? disable() : checkConnection()"
      >
        {{ user.telegram ? "Disable" : "Check Connecteion" }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import CountDown from "./CountDown.vue";
export default {
  components: { CountDown },
  props: {
    code: {
      type: Number,
      required: true,
    },
    active: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      telegramCode: "",
      loading: false,
      generateCodeLoading: false,
      generate_new_code: false,
      generateNewCodeKey: 0,
    };
  },

  computed: {
    ...mapGetters(["user"]),
    telegramURL() {
      return process.env.VUE_APP_TELEGRAM_URL;
    },

    telegramName() {
      return process.env.VUE_APP_TELEGRAM_NAME;
    },

    qrCodeSrc() {
      return `https://api.qrserver.com/v1/create-qr-code/?data=${this.telegramURL}&size=220x220&margin=0`;
    },
  },
  methods: {
    ...mapMutations(["changeTelegramStatue"]),
    ...mapActions(["generateNewCodeForTelegram", "getProfileInfo", "disableTelegram"]),
    // Generate Code For Telegram
    generate_code_for_telegram() {
      this.generateNewCodeForTelegram().then((res) => {
        if (res) {
          //
          this.telegramCode = res;
          this.generateNewCodeKey++;
          this.generate_new_code = false;
          this.flip = true;
        } else {
          //
          this.generate_new_code = true;
        }
      });
    },

    openTelegramLink() {
      window.open(this.telegramURL);
    },

    // Check Connection
    async checkConnection() {
      this.loading = true;
      await this.getProfileInfo().then((res) => {
        if (res.result.telegram) {
          this.changeTelegramStatue(true);

          this.$emit("close");
        }
      });
      this.loading = false;
    },
    //

    // Time Is Over
    timeIsOver() {
      this.generate_new_code = true;
    },
    //

    async disable() {
      this.loading = true;

      await this.disableTelegram().then((res) => {
        if (res) {
          this.changeTelegramStatue(false);

          this.$emit("close");
        }
      });

      this.loading = false;
    },
  },
  watch: {
    active() {
      if (this.active && !this.user.telegram) {
        this.telegramCode = this.code;
        this.generateNewCodeKey++;
        this.generate_new_code = false;
      }
    },
  },

  mounted() {
    this.telegramCode = this.code;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/components/dialogCard.scss";
</style>
