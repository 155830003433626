<template>
  <section class="collections sec-wrapper">
    <HeadingTitle>Digital Collection</HeadingTitle>
    <ul>
      <li v-for="(item, i) in items" :key="i" class="observe-item">
        <div class="img-container">
          <img
            :src="require(`@/assets/images/collections/${item.name}.png`)"
            alt=""
            class="image"
          />
        </div>
        <div class="desc">
          <h3>{{ item.name }}</h3>
          <p>{{ item.description }}</p>
        </div>
      </li>
    </ul>
  </section>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          name: "Lands",
          description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi aliquam vel explicabo. Sapiente, cumque! Nihil, aut quisquam. Unde earum porro, est ipsa facere veniam cumque deleniti recusandae architecto assumenda odio.",
        },
        {
          name: "Lootboxes",
          description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi aliquam vel explicabo. Sapiente, cumque! Nihil, aut quisquam. Unde earum porro, est ipsa facere veniam cumque deleniti recusandae architecto assumenda odio.",
        },
        {
          name: "Packs",
          description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi aliquam vel explicabo. Sapiente, cumque! Nihil, aut quisquam. Unde earum porro, est ipsa facere veniam cumque deleniti recusandae architecto assumenda odio.",
        },
        {
          name: "Units",
          description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi aliquam vel explicabo. Sapiente, cumque! Nihil, aut quisquam. Unde earum porro, est ipsa facere veniam cumque deleniti recusandae architecto assumenda odio.",
        },
        {
          name: "Buildings",
          description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi aliquam vel explicabo. Sapiente, cumque! Nihil, aut quisquam. Unde earum porro, est ipsa facere veniam cumque deleniti recusandae architecto assumenda odio.",
        },
        {
          name: "Currencies",
          description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi aliquam vel explicabo. Sapiente, cumque! Nihil, aut quisquam. Unde earum porro, est ipsa facere veniam cumque deleniti recusandae architecto assumenda odio.",
        },
      ],
    };
  },
  async created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.collections {
  background: radial-gradient(ellipse at top left, #00a2ff76 5%, black 70%);
  ul {
    width: 80%;
    margin: 0 auto;
    padding: 0;
    display: grid;
    grid-column-gap: 10px;
    grid-row-gap: 20px;
    grid-template-columns: repeat(2, 1fr);
    @media only screen and(max-width: 500px) {
      grid-template-columns: repeat(1, 1fr);
      width: 95%;
    }
    li {
      display: flex;
      list-style: none;
      margin-bottom: 10px;
      cursor: pointer;
      .img-container {
        transition: all 0.5s ease;
        width: 20%;
        min-width: 80px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .image {
          aspect-ratio: 1 !important;
          width: 100%;
        }
      }
      .desc {
        transition: all 0.5s ease;
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        h3 {
          color: #00c8ff;
          font-size: clamp(11px, 2vw, 18px);
        }
        p {
          color: #7b7b7b;
          font-size: clamp(0.5em, 2vw, 14px);
        }
      }
    }
  }
}
</style>
