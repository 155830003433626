<template>
  <div class="not-found">
    <h1 class="error-status">404</h1>
    <h1 class="white--text">PAGE NOT FOUND</h1>
    <v-btn class="primary mt-5"
      ><router-link to="/" class="white--text">Home page</router-link></v-btn
    >
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.not-found {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .error-status {
    font-size: 100px;
    color: white;
  }
}
</style>
