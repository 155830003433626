<template>
  <v-col cols="6" sm="6" md="4" lg="3" class="col mt-6 mt-sm-10">
    <v-skeleton-loader
      v-if="!bgIsLoaded"
      type="image"
      class="skeleton"
      min-height="220"
    ></v-skeleton-loader>
    <section class="item-card" v-show="bgIsLoaded" @click="showDetails(item)">
      <img
        src="../../../assets/images/cards/land-card-bg.png"
        class="item-card-background"
        @load="mainBgIsLoaded"
      />
      <v-img
        :src="storageImageURL(item.image)"
        contain
        class="item-card-image"
        @load="imgIsLoaded"
      >
        <v-skeleton-loader
          v-if="!imageIsLoaded"
          width="100%"
          height="100%"
          type="image"
          class="skeleton"
        ></v-skeleton-loader>
      </v-img>
      <div v-if="bgIsLoaded">
        <div class="item-card-name-container">
          <span>{{ item.name }}</span>
        </div>
        <!-- <h5 class="item-card-status">
          <span class="">status: {{ item.status }}</span>
        </h5>
        <h5 class="item-card-level">
          <span>Total Awards : {{ item.total_award }}</span>
        </h5> -->
        <h5 class="item-card-id">
          <span>Id: {{ item.id }}</span>
        </h5>
        <!-- <ul class="item-card-price pa-0">
          <li v-for="(value, name, i) in item.price" :key="i">
            <span>{{ value.price }} {{ name }} </span>
          </li>
        </ul>
        <h5 class="item-card-price-description">
          <span v-html="item.price_description"></span>
        </h5> -->
      </div>

      <button
        class="item-card-shop-button"
        style=""
        @click.stop="transferNft(item)"
      >
        <span>Transfer NFT</span>
      </button>
      <button class="item-card-sell-button" @click.stop="sell(item)">
        <span>Sell</span>
      </button>
    </section>
    <!--detail dialog -->
    <v-dialog v-model="detailDialog" width="650">
      <DetailCard
        @close="detailDialog = false"
        @sell="sell"
        :selectedItem="selectedLootbox"
        :isLoading="loading"
        type="lootbox"
      ></DetailCard>
    </v-dialog>

    <!--transfar dialog -->
    <v-dialog v-model="transfarDialog" width="600">
      <TransferNftCard
        :selectedItem="selectedLootbox"
        type="lootbox"
        @close="transfarDialog = false"
        @fetchAgain="fetchAgain"
      ></TransferNftCard>
    </v-dialog>

    <!--sell dialog -->
    <v-dialog v-model="sellDialog" width="500">
      <SellDialogCard
        :selectedItem="selectedLootbox"
        type="lootbox"
        @close="sellDialog = false"
        @sellSuccess="sellSuccess"
      ></SellDialogCard>
    </v-dialog>
  </v-col>
</template>

<script>
import SellDialogCard from "../sellDialogCard/SellDialogCard.vue";
import TransferNftCard from "../transferNftCard/TransferNftCard.vue";
import { mapActions, mapGetters } from "vuex";
import { storageImage } from "@/mixins/storageImage.js";
export default {
  mixins: [storageImage],
  props: ["item"],
  data() {
    return {
      sellDialog: false,
      transfarDialog: false,
      detailDialog: false,

      loading: false,
      selectedLootbox: {},
      transferInfo: {},

      bgIsLoaded: false,
      imageIsLoaded: false,
    };
  },
  computed: {
    ...mapGetters(["wallet_address", "rules"]),
  },
  components: {
    SellDialogCard,
    TransferNftCard,
  },
  methods: {
    ...mapActions(["getItemDetails", "transferNftToWallet"]),
    showDetails(item) {
      this.detailDialog = true;
      this.loading = true;
      this.getItemDetails({ itemsName: "lootboxes", id: item.id }).then(
        (result) => {
          this.selectedLootbox = result.data.result;
          this.loading = false;
        }
      );
    },
    transferNft(item) {
      this.transfarDialog = true;
      this.selectedLootbox = item;
    },
    fetchAgain() {
      this.$emit("fetchUserLootboxes");
    },

    sell(item) {
      this.selectedLootbox = item;
      this.sellDialog = true;
      if (this.$refs.sellForm) {
        this.$refs.sellForm.reset();
      }
    },
    sellSuccess() {
      this.sellDialog = false;
      this.$emit("fetchUserLootboxes");
    },
    mainBgIsLoaded() {
      this.bgIsLoaded = true;
    },
    imgIsLoaded() {
      this.imageIsLoaded = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.col {
  transition: all 0.3s ease;
  @media only screen and(max-width: 500px) {
    font-size: 9px;
  }
  //   .item-card {
  //     position: relative;
  //     // background: red;
  //     .item-card-background {
  //       width: 100%;
  //       cursor: pointer;
  //       position: relative;
  //     }
  //     .item-card-image {
  //       position: absolute;
  //       top: 39%;
  //       bottom: 18.8%;
  //       left: 12%;
  //       right: 12%;
  //       pointer-events: none;
  //       border-radius: 3px;
  //       background: #0e0e20d3;
  //       .skeleton {
  //         position: absolute;
  //         top: 0;
  //         bottom: 0;
  //       }
  //     }
  //     .new-shop-button {
  //       position: absolute;
  //       width: 40%;
  //       height: 15%;
  //       background: url("../../../assets/images/buttons/buy-button-background.png")
  //         no-repeat !important;
  //       background-size: cover !important;
  //       bottom: 5px;
  //       left: 50%;
  //       transform: translateX(-50%);
  //       z-index: 1;
  //       &:hover {
  //         animation: shake 0.4s linear 1;
  //       }
  //       span {
  //         font-size: 10px;
  //         font-weight: 600;
  //         color: #ffd600;
  //         text-shadow: 0px 0px 4px #ffd600;
  //       }
  //     }
  //     .sell-button {
  //       position: absolute;
  //       bottom: -11.5%;
  //       width: 59%;
  //       height: 18%;
  //       left: 50%;
  //       transform: translateX(-50%) rotate(180deg);
  //       background: url("../../../assets/images/buttons/buy-button-background-1.png")
  //         no-repeat !important;
  //       background-size: cover !important;
  //       transition: all 0.5s ease;
  //       opacity: 1;
  //       z-index: 0;
  //       span {
  //         display: inline-block;
  //         transform: rotate(180deg);
  //         font-size: 18px;
  //         font-weight: 900;
  //         color: red;
  //         text-shadow: 0px 0px 5px red;
  //       }
  //       &:hover {
  //         animation: shake-reverse 0.4s linear 1;
  //       }
  //     }
  //     .item-card-name {
  //       position: absolute;
  //       top: 25%;
  //       left: 50%;
  //       transform: translateX(-50%);
  //       font-size: 14px;
  //       font-weight: 9000;
  //       color: #000e44;
  //       text-align: center;
  //       line-height: 12px;
  //     }
  //     .item-card-status {
  //       position: absolute;
  //       top: 29%;
  //       right: 25%;
  //       span {
  //         color: white;
  //         font-size: 11px;
  //       }
  //     }
  //     .item-card-level {
  //       position: absolute;
  //       top: 29%;
  //       left: 25%;
  //       span {
  //         font-size: 10px;
  //         text-align: center;
  //         font-weight: 800;
  //         color: white;
  //       }
  //     }
  //     // .item-card-price {
  //     //   position: absolute;
  //     //   bottom: 8%;
  //     //   left: 14%;
  //     //   li {
  //     //     list-style: none;
  //     //     line-height: 10px;
  //     //     span {
  //     //       color: #000e44;
  //     //       font-size: 11px;
  //     //       font-weight: 900;
  //     //     }
  //     //   }
  //     // }
  //     // .item-card-price-description {
  //     //   position: absolute;
  //     //   bottom: 9%;
  //     //   right: 10%;
  //     // }
  //     .item-card-id {
  //       position: absolute;
  //       bottom: 10%;
  //       right: 14%;
  //     }
  //   }
  //   .v-skeleton-loader {
  //     z-index: 1;
  //   }
  // }
  // @keyframes shake {
  //   0% {
  //     transform: translateX(-47%);
  //   }
  //   25% {
  //     transform: translateX(-50%);
  //   }
  //   50% {
  //     transform: translateX(-47%);
  //   }
  //   75% {
  //     transform: translateX(-53%);
  //   }
  //   100% {
  //     transform: translateX(-47%);
  //   }
  // }
  // @keyframes shake-reverse {
  //   0% {
  //     transform: translateX(-47%) rotate(180deg);
  //   }
  //   25% {
  //     transform: translateX(-50%) rotate(180deg);
  //   }
  //   50% {
  //     transform: translateX(-47%) rotate(180deg);
  //   }
  //   75% {
  //     transform: translateX(-53%) rotate(180deg);
  //   }
  //   100% {
  //     transform: translateX(-47%) rotate(180deg);
  //   }
}
</style>
