import mainApi from "../../plugins/axios/axios.js";
const state = {};

const mutations = {};

const actions = {
  getProfileInfo({ commit }) {
    return new Promise((resolve, reject) => {
      mainApi
        .get(`/users`)
        .then((result) => {
          commit("setUser", result.data.result, { root: true });
          resolve(result.data);
        })
        .catch((err) => {
          reject();
        });
    });
  },

  async updateProfileInfo(_, payload) {
    try {
      await mainApi.post("/users", payload);

      return true;
    } catch (error) {
      return false;
    }
  },

  sendEmailVerify() {
    return new Promise((resolve, reject) => {
      mainApi
        .get(`/users/email/resend`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((err) => {
          reject();
        });
    });
  },
  getEmailVerify(_, info) {
    return new Promise((resolve, reject) => {
      mainApi
        .get(`/users/email/verify`, {
          params: { token: info },
        })
        .then((result) => {
          resolve(result.data);
        })
        .catch((err) => {
          resolve(false);
          reject();
        });
    });
  },

  // Telegram Code Generate For User
  async generateNewCodeForTelegram({ commit }) {
    try {
      const { data } = await mainApi.post("users/telegram/generate-code");

      //
      let code = data.result;

      //
      return code;
    } catch (error) {
      return false;
    }
  },

  async disableTelegram({ commit }) {
    try {
      await mainApi.post("users/telegram/disable");

      //
      return true;
    } catch (error) {
      return false;
    }
  },
};
const getters = {};

export default {
  state,
  getters,
  mutations,
  actions,
};
