<template>
  <section class="overlay">
    <button class="play-btn" @click="togglePP">
      <v-icon class="play-icon">mdi-play-circle</v-icon>
    </button>
  </section>
</template>

<script>
export default {
  methods: {
    togglePP() {
      this.$emit("clicked");
    },
  },
};
</script>

<style lang="scss" scoped>
.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.42);
  display: flex;
  align-items: center;
  justify-content: center;
  .play-btn {
    transition: all 0.5s ease;
    // filter: blur(1px);
    &:hover {
      transform: scale(1.2);
      filter: blur(0px);
    }
    .play-icon {
      color: #0ec4f2;
      font-size: 80px;
      @media only screen and(max-width : 500px) {
        font-size: 45px;
      }
    }
  }
}
</style>
