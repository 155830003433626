<template>
  <div class="d-flex justify-center observe-item">
    <h2><slot></slot></h2>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
h2 {
  font-size: 40px;
  font-weight: 900;
  text-align: center;
  background: radial-gradient(circle at 50%, #0ec4f2, #24358f);
  -webkit-background-clip: text;
  -webkit-text-stroke: 4px transparent;
  padding: 0 2px;
  color: #160f1a;
  margin-bottom: 30px;
  @media only screen and (max-width: 500px) {
    font-size: 30px;
  }
}
</style>
