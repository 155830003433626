<template>
  <section class="home">
    <FirstSection />
    <VideoPlayer></VideoPlayer>
    <Socials />
    <LandsOfAzolite></LandsOfAzolite>
    <GameMode></GameMode>
    <Collections></Collections>
    <SalesLands />
    <Contact></Contact>
    <Partnerships />
  </section>
</template>

<script>
import FirstSection from "../../components/home/FirstSection.vue";
import Socials from "../../components/home/Socials.vue";
import LandsOfAzolite from "../../components/home/LandsOfAzolite.vue";
import GameMode from "../../components/home/GameMode.vue";
import Contact from "../../components/home/Contact.vue";
import VideoPlayer from "../../components/home/videoPlayer/VideoPlayer.vue";
import Partnerships from "../../components/home/Partnerships.vue";
import SalesLands from "../../components/home/SalesLands.vue";
import Collections from "../../components/home/Collections.vue";

export default {
  name: "Home",
  mounted() {
    this.observeAnimation();
  },
  methods: {
    observeAnimation() {
      const cards = document.getElementsByClassName("observe-item");
      let observer = new IntersectionObserver((enteries) => {
        enteries.forEach((entery) => {
          if (entery.isIntersecting) {
            //if object is exsist in view port and threshold is 0
            entery.target.classList.add("observed");
          }
        });
      });
      for (let i = 0; i < cards.length; i++) {
        observer.observe(cards[i]);
      }
    },
  },

  components: {
    FirstSection,
    LandsOfAzolite,
    Socials,
    GameMode,
    Contact,
    VideoPlayer,
    Partnerships,
    SalesLands,
    Collections,
  },
};
</script>
<style lang="scss" scoped></style>
