<template>
  <v-col class="col pa-1" cols="6" md="6">
    <div class="card" v-if="award.item">
      <img :src="award.item.image" alt="" class="img" v-if="award.item.image" />
      <img
        :src="coinIconRender(award.item.name)"
        alt=""
        class="img"
        v-else-if="
          (!award.item.image && award.item.name === 'usdt') ||
          award.item.name === 'ecg'
        "
      />
      <h1 class="no-image" v-else>No image</h1>
      <section class="details">
        <h4 class="cyan--text">{{ award.item.name }}</h4>
        <h5>Chance : {{ award.chances }} %</h5>
        <h5>amount : {{ award.amount }}</h5>
      </section>

      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header>More informations</v-expansion-panel-header>
          <v-expansion-panel-content>
            <AwardMoreInfo :awardInformations="award.item"></AwardMoreInfo>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>

    <div class="card" v-else>
      <h2 class="py-16">
        EMPTY ITEM !
      </h2>
      
      <section class="details">
        <h4 class="cyan--text">{{ award.name }}</h4>
        <h6>Chance : {{ award.chances }} %</h6>
        <h6>amount : {{ award.amount }}</h6>
      </section>

      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header>More informations</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-icon color="warning" small >mdi-alert-octagon</v-icon> <span class="caption warning--text">You maybe win this item and got nothing !</span>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </v-col>
</template>

<script>
import AwardMoreInfo from "./AwardMoreInfo.vue";
export default {
  props: ["award"],
  components: {
    AwardMoreInfo,
  },
  computed: {},
  created() {},
  methods: {
    coinIconRender(name) {
      return `${process.env.VUE_APP_COIN_ICON_ADDRESS}/128/color/${name}.png`;
    },
  },
};
</script>

<style lang="scss" scoped>
.col {
  .card {
    border: 3px solid rgb(0, 247, 255);
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    padding-top: 5px;
    .v-expansion-panels {
      .v-expansion-panel-header {
        user-select: none;
        min-height: 30px;
        height: 30px;
        font-size: 11px;
        padding: 0 8px;
      }
    }
    .img {
      width: 100%;
      max-width: 180px;
      height: 100%;
      object-fit: contain;
      margin: auto;
    }
    .no-image {
      padding: 25%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .details {
      padding: 5px;
      margin-top: auto;
      text-align: center;
    }
  }
}
</style>
