<template>
  <section>
    <h2 class="text-center mt-15" v-if="!owner">No Owner !</h2>
    <div class="Owner mt-10" v-else>
      <div class="d-sm-flex justify-center align-center px-3">
        <v-img
          src="@/assets/images/user/user-avatar.png"
          min-height="100%"
          max-width="120"
          class="mr-5"
        ></v-img>

        <span class="">
          <h3 class="yellow--text mb-1">
            Name:
            <span class="grey--text">{{ owner.name }}</span>
          </h3>
          <h3 class="yellow--text mb-1">
            Email:
            <span class="grey--text">{{ owner.email }}</span>
          </h3>
          <h3 class="yellow--text mb-1">
            ID:
            <span class="grey--text">{{ owner.id }}</span>
          </h3>
          <h3 class="yellow--text wallet-address">
            Wallet:
            <span class="grey--text" style="font-size: 14px">{{
              owner.wallet_address
            }}</span>
          </h3>
        </span>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: ["owner"],
};
</script>

<style lang="scss" scoped>
h3 {
  background: #1f2642;
  border-radius: 5px;
  padding: 0 10px;
}
</style>
