<template>
  <NoBalanceError
    :currencyName="selectedItem.name"
    @close="$emit('close')"
    v-if="type === 'wallet' && !selectedItem.amount"
  ></NoBalanceError>
  <v-card class="card" v-else>
    <v-card-title class="card-title">
      <h3 class="white--text">Sell in market</h3>
    </v-card-title>
    <v-card-text class="py-3 card-text">
      <h3 class="d-flex justify-space-between">
        <span class="orange--text">{{
          `${selectedItem.name ? selectedItem.name : selectedItem[type].name}`
        }}</span>
        <span class="yellow--text"> ID: {{ selectedItem.id }}</span>
      </h3>
      <v-form ref="sellForm">
        <p class="grey--text mb-3">Please insert your information below.</p>
        <v-text-field
          type="number"
          label="Amount"
          :rules="amount_rules"
          v-model.trim="amount"
          placeholder="Enter your amount"
          outlined
          dense
          dark
          v-if="type === 'wallet'"
        >
          <template v-slot:append>
            <i class="fa-solid fa-hands-holding-circle" style="font-size: 20px"></i>
          </template>
        </v-text-field>
        <v-text-field
          type="number"
          label="USDT"
          :rules="rules.required"
          v-model.trim="usdtPrice"
          placeholder="Enter your USDT price"
          outlined
          dense
          dark
        >
          <template v-slot:append>
            <img :src="coinIconRender('usdt')" width="24px" />
          </template>
        </v-text-field>
        <v-text-field
          type="number"
          label="ECG"
          v-model.trim="ecgPrice"
          placeholder="Enter your ECG price"
          outlined
          dense
          dark
        >
          <template v-slot:append>
            <img :src="coinIconRender('ecg')" width="24px" />
          </template>
        </v-text-field>
      </v-form>
    </v-card-text>

    <v-divider dark></v-divider>

    <v-card-actions class="card-actions">
      <v-spacer></v-spacer>
      <v-btn color="error" text @click="$emit('close')"> Cancle </v-btn>
      <v-btn color="success" text @click="submitSell" :loading="sellLoading">
        Sell
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import NoBalanceError from "../currencies/NoBalanceError.vue";
export default {
  props: ["selectedItem", "type"],
  data() {
    return {
      usdtPrice: null,
      ecgPrice: null,
      amount: 1,
      sellLoading: false,
    };
  },
  components: {
    NoBalanceError,
  },
  computed: {
    ...mapGetters(["rules", "user"]),
    amount_rules() {
      return [
        (v) => !!v || "This Field is required",
        (v) => v <= this.selectedItem.amount || "Insufficent balance !",
      ];
    },
  },
  created() {
    
  },
  methods: {
    ...mapActions(["sellItem"]),
    submitSell() {
      this.$refs.sellForm.validate();
      let info = {
        coin_id: 1,
        seller_user_id: this.user.id,
        price: {
          usdt: { coin: "usdt", price: this.usdtPrice, coin_id: 1 },
          ecg: { coin: "ecg", price: this.ecgPrice, coin_id: 2 },
        },
        saleable_type: this.type,
        saleable_id: this.selectedItem.id,
        amount: this.amount,
      };
      if (this.$refs.sellForm.validate()) {
        this.sellLoading = true;
        this.sellItem(info)
          .then(() => {
            this.$emit("sellSuccess");
            this.$refs.sellForm.reset();
            this.$refs.sellForm.resetValidation();
          })
          .finally(() => {
            this.sellLoading = false;
          });
      }
    },
    coinIconRender(name) {
      return `${process.env.VUE_APP_COIN_ICON_ADDRESS}/32/color/${name}.png`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/components/dialogCard.scss";
</style>
