<template>
  <section class="land-details">
    <div class="land-details-head">
      <v-img
        :src="storageImageURL(selectedCurrnecy.icon)"
        width="100%"
        max-width="220"
        contain
        class="land-img mx-auto"
        @load="imgLoaded"
      >
        <v-skeleton-loader
          type="image@2"
          class="land-img-skeleton"
          v-if="!imgIsLoaded"
          width="100%"
          height="225"
        ></v-skeleton-loader>
      </v-img>
      <h3 class="land-name">
        {{ selectedCurrnecy.name }}
      </h3>
      <h5 class="land-name">
        {{ selectedCurrnecy.description }}
      </h5>
      <h4 class="item">
        <span class="yellow--text">Symbol</span>
        <span>{{ selectedCurrnecy.symbol }}</span>
      </h4>
    </div>
  </section>
</template>

<script>
import { storageImage } from "@/mixins/storageImage.js";
export default {
  mixins: [storageImage],
  props: ["selectedCurrnecy"],
  data() {
    return {
      imgIsLoaded: false,
    };
  },
  created() {},
  methods: {
    imgLoaded() {
      this.imgIsLoaded = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.land-details {
  &-head {
    .land-img {
      border-radius: 10px;
    }
    .land-name {
      color: white;
      margin: 10px;
      text-align: center;
    }
  }
  .item {
    display: flex;
    justify-content: space-between;
    margin-top: 1%;
    padding: 5px 20px;
    border-radius: 5px;
    background: linear-gradient(to right, transparent, #131a39, transparent);
    @media only screen and(max-width: 500px) {
      font-size: 12px;
    }
  }
}
</style>
