<template>
  <section class="callback-payment routes d-flex justify-center align-center">
    <div
      class="card d-flex flex-column align-center"
      :class="`${status == 10 ? 'border-success' : 'border-error'}`"
    >
      <v-img
        src="https://www.pngmart.com/files/7/Payment-PNG-Free-Download.png"
        width="50%"
        contain
      ></v-img>
      <h2 class="mb-5">Payment Report</h2>
      <h5 v-if="!haveQuery">No Payment</h5>
      <div v-else>
        <h1 class="text-center mb-5" :class="`${status == 10 ? 'success' : 'red'}--text`">
          {{ message }}
          <v-icon large right color="success" v-if="status == 10"
            >mdi-check-circle</v-icon
          >
          <v-icon color="error" right v-else>mdi-close-circle</v-icon>
        </h1>
        <h3 class="text-center mb-5 grey--text">
          Status:
          <span :class="`${status == 10 ? 'success' : 'red'}--text`">{{
            statusCovertor(status)
          }}</span>
        </h3>
        <h4 class="text-center mb-5 grey--text">
          Oreder Id: <span class="white--text">{{ orderId }}</span>
        </h4>
      </div>
      <v-btn @click="$router.replace('/payment')" color="white" text>Back To Page</v-btn>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      haveQuery: false,
      status: "",
      message: "",
      orderId: "",
    };
  },
  mounted() {
    this.checkQuery();
  },
  methods: {
    checkQuery() {
      let orderId = this.$route.query.orderId;
      let status = this.$route.query.status;
      let message = this.$route.query.message;

      //
      if (orderId && status && message) {
        this.haveQuery = true;
        this.orderId = orderId;
        this.status = status;
        this.message = message;
      }
    },
    // Status convertur for show in payment status
    statusCovertor(status) {
      if (status == 1) return "Expired";
      if (status == 2) return "Canceled";
      if (status == 10) return "Success";
    },
  },
};
</script>

<style lang="scss" scoped>
.callback-payment {
  background: url("../../assets/images/pay-bg.png") no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 100vh !important;
  .card {
    width: 95%;
    max-width: 500px;
    border-radius: 15px;
    padding: 20px;
    margin-top: 40px;
    background: var(--base-color);
    opacity: 0.98;
    box-shadow: 10px 25px 12px 2px rgba(11, 11, 11, 0.856);
    border: none;
    &.border-success {
      // border: 4px solid green;
    }
    &.border-error {
      // border: 4px solid red;
    }
  }
}
</style>
