import mainApi from "../../plugins/axios/axios.js";
import axios from "axios";
const state = {
  token: null,
  user: null,
};

const getters = {
  user: (state) => state.user,
  token: (state) => state.token,
};

const mutations = {
  setUser(state, payload) {
    state.user = payload;
  },

  setToken(state, payload) {
    state.token = payload;
    localStorage.setItem("token", payload);
  },

  changeUser2FA(state) {
    state.user.google2fa = !state.user.google2fa;
  },

  changeTelegramStatue(state, status) {
    state.user.telegram = status;
  },

  changeUserHasPassword(state) {
    state.user.hasPassword = true;
  },
};

const actions = {
  /**
   * User Exist
   * @param {wallet address} payload
   */
  // async userExist(_, payload) {
  //   try {
  //     const { data } = await mainApi.post("users/auth/check_user", payload);

  //     //
  //     let { userExists, twoFactorAuthentication } = data.result;

  //     //
  //     return { userExists, twoFactorAuthentication };
  //   } catch (error) {
  //     return false;
  //   }
  // },

  /**
   * Register
   */
  async register({ commit }, payload) {
    try {
      const { data } = await mainApi.post("users/auth/register", payload);

      //
      console.log({ data });
      let token = data.result.token;
      let user = data.result;

      commit("setUser", user);
      commit("setToken", token);

      //
      return true;
    } catch (error) {
      return false;
    }
  },

  /**
   * Login
   */
  async login({ commit }, payload) {
    try {
      const { data } = await mainApi.post("users/auth/login", payload);

      //
      if (!data.result.google2fa) {
        let token = data.result.token;
        let user = data.result;

        commit("setUser", user);
        commit("setToken", token);

        //
        return true;
      } else {
        return "google";
      }
    } catch (error) {
      return false;
    }
  },

  /**
   * Verify Login
   */
  async verifyLogin({ commit }, payload) {
    try {
      const { data } = await mainApi.post("users/auth/2fa/verify", payload);

      //
      let token = data.result.token;
      let user = data.result;

      commit("setUser", user);
      commit("setToken", token);

      //
      return true;
    } catch (error) {
      return false;
    }
  },
  autoLogin({ commit }) {
    if (localStorage.getItem("token")) {
      commit("setToken", localStorage.getItem("token"));
    }
  },
  logout({ commit }) {
    commit("setToken", null);
    localStorage.clear();
  },

  setThirdpartyToken({ commit }, token) {
    commit("setToken", token);
  },
  setThirdpartyUser({ commit }, user) {
    commit("setUser", user);
  },
  googleLogin({ commit }, payload) {
    return new Promise((resolve, reject) => {
      mainApi
        .post("users/auth/googleLogin", payload)
        .then((result) => {
          let token = result.data.result.token;
          // let user = result.data.result;

          // commit("setUser", user);
          commit("setToken", token);
          resolve();
        })
        .catch((err) => {
          reject();
        });
    });
  },

  async generateTwoFa({ commit }) {
    try {
      const { data } = await mainApi.post("users/auth/2fa/generate");

      return data.result;
    } catch (error) {
      return false;
    }
  },

  async changeTwoFaStatus({ commit }, payload) {
    try {
      await mainApi.post("users/auth/2fa/edit", payload);

      commit("changeUser2FA");

      return true;
    } catch (error) {
      return false;
    }
  },

  resetPasswordEmailSend(_, payload) {
    return new Promise((resolve, reject) => {
      mainApi
        .post("users/auth/password-reset/send-email", payload)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject();
        });
    });
  },
  resetPasswordCheckToken(_, payload) {
    return new Promise((resolve, reject) => {
      mainApi
        .post("users/auth/password-reset/check-token", payload)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject();
        });
    });
  },
  resetPasswordConfirmNewPassword(_, payload) {
    return new Promise((resolve, reject) => {
      mainApi
        .post("users/auth/password-reset/change", payload)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject();
        });
    });
  },
  async changePassword({ commit }, payload) {
    try {
      let link = "";
      const hasPassword = payload.hasPassword;
      let config = {};

      if (hasPassword) {
        link = 'users/auth/change-password'
        config = payload.config
      } else {
        link = "users/auth/set-password";
        config = {
          password: payload.config.newPassword,
        };
      }

      await mainApi.post(link, config);

      commit("changeUserHasPassword");

      return true;
    } catch (error) {
      return false;
    }
  },

  async withdraw(_, payload) {
    try {
      await mainApi.post("users/withdraw-nft", payload);

      return true;
    } catch (error) {
      return false;
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
