<template>
  <section class="unchain-nft">
    <section class="content">
      <section v-if="loading">
        <div class="no-item">
          <loading-spinner></loading-spinner>
        </div>
      </section>
      <section v-else>
        <!-- wallet not connected -->
        <div class="no-item" v-if="!connectedTo">
          <p class="alert-text orange--text">
            <v-icon class="mb-1" color="orange" size="18">mdi-alert</v-icon>
            Load Nfts list is required an metamask wallet address. please
            connect to your metamask wallet .
          </p>
          <v-btn color="success" class="mt-3" text @click="walletDialog = true"
            >Connect to wallet <v-icon right small>mdi-wallet</v-icon></v-btn
          >
        </div>

        <!-- wallet connected but there is no nft -->
        <div class="no-item" v-else-if="listOfNFtUrls.length <= 0">
          <h4 class="white--text">There is no item !</h4>
        </div>

        <!-- wallet connected and have atleast one nft -->
        <v-row class="row" no-gutters v-else>
          <NftItem
            v-for="(item, i) in listOfNFtUrls"
            :key="i"
            :item="item"
            @fetchNfts="fetchNfts"
          ></NftItem>
        </v-row>
      </section>
      <!-- <v-divider class="white mt-2"></v-divider>
      <div class="table-paginate-box">
        <div class="table-pagination-total">
          {{ total }} <span class="">result(s)</span>
        </div>
        <div class="paginate-box">
          <v-pagination
            v-model="page"
            class="pagination"
            :length="lastPage"
            total-visible="5"
            @input="changePage()"
            color="blue"
            circle
          ></v-pagination>
        </div>
      </div> -->
    </section>
    <!--wallet dialog -->
    <v-dialog v-model="walletDialog" width="500">
      <WalletDialogCard @close="walletDialog = false"></WalletDialogCard>
    </v-dialog>
    <!--wallet dialog -->
  </section>
</template>

<script>
import { callContractMixin } from "../../../mixins/callContractMixin.js";
import { mapActions, mapGetters } from "vuex";
// import Moralis from "moralis";
import axios from "axios";
import NftItem from "./NftItem.vue";
export default {
  mixins: [callContractMixin],
  data() {
    return {
      loading: false,
      listOfNFtUrls: [],
      walletDialog: false,

      //for polygon nfts with moralis and pagination
      // polygonNFTS: [],
      // polygonNFTSForThisPage: [],
      // goalNfts: [],

      //paginations
      // total: 0,
      // page: 1,
      // lastPage: 0,
      // startIndex: 0,
      // endIndex: 8,
    };
  },
  components: {
    NftItem,
  },
  computed: {
    ...mapGetters(["wallet_address", "connectedTo", "configs"]),
  },
  watch: {
    connectedTo(val) {
      if (val) {
        this.getListOfBalance();
      }
    },
    configs() {
      if (this.connectedTo) {
        this.getListOfBalance()
      }
    }
  },
  created() {
    if (this.connectedTo) this.getListOfBalance();
  },
  methods: {
    ...mapActions(["depositNft"]),
    fetchNfts() {
      this.getListOfBalance();
    },
    async getListOfBalance() {
      if (this.configs.nftContractAddressEth) {
        this.loading = true;
        await this.callContract(
          this.configs.nftContractAddressEth, //contract address
          "balanceOf",
          [this.wallet_address],
          async (res) => {
            let balanceCount = parseInt(res);
            if (balanceCount <= 0) {
              this.loading = false;
            } else {
              await this.getDetailById(balanceCount);
            }
          },
          false //read false & write true
        );
      }
    },
    async getDetailById(index) {
      const helperArray = [];
      for (let i = 0; i < index; i++) {
        const element = i;
        helperArray.push(element);
      }
      helperArray.forEach(async (element) => {
        await this.callContract(
          this.configs.nftContractAddressEth,
          "tokenOfOwnerByIndex",
          [this.wallet_address, element],
          (res) => {
            let id = parseInt(res);
            this.getTokenUrlById(id);
          },
          false //read false & write true
        );
      });
    },
    async getTokenUrlById(id) {
      this.listOfNFtUrls = [];
      await this.callContract(
        this.configs.nftContractAddressEth,
        "tokenURI",
        [id],
        async (res) => {
          let tokenUrl = res;
          if (tokenUrl) {
            axios.get(tokenUrl).then((res) => {
              this.listOfNFtUrls.push({ ...res.data, nft_id: id });
              this.loading = false;
            });
          }
        },
        false //read false & write true
      );
    },

    //approche with moralis and fucking pagination

    // async getNFTs() {
    //   const serverUrl = "https://tps5x5defrri.usemoralis.com:2053/server";
    //   const appId = "c99iNEMJfpms2GBle4yIQxEvm6Q32ssHA2K8jsW9";
    //   Moralis.start({ serverUrl, appId });
    //   const options = {
    //     chain: "MATIC",
    //     address: "0xa34f511dF58f835cf0Ede77C583632F466057C28",
    //     // address: this.wallet_address,
    //   };
    //   this.polygonNFTS = await Moralis.Web3.getNFTs(options);
    //   this.total = this.polygonNFTS.length;
    //   this.lastPage = Math.ceil(this.polygonNFTS.length / 8);
    //   this.changePage();
    // },
    // changePage() {
    //   this.startIndex = (this.page - 1) * 8;
    //   this.endIndex = this.startIndex + 8;
    //   this.polygonNFTSForThisPage = this.polygonNFTS.slice(
    //     this.startIndex,
    //     this.endIndex
    //   );
    //   this.loading = true;
    //   this.getUriData(this.polygonNFTSForThisPage);
    //   setTimeout(() => {
    //     this.loading = false;
    //   }, 3500);
    // },
    // getUriData(array) {
    //   this.goalNfts = [];
    //   this.loading = true;
    //   array.forEach((element) => {
    //     if (element.token_uri) {
    //       axios.get(element.token_uri).then((res) => {
    //         this.goalNfts.push({ ...res.data, token_id: element.token_id });
    //       });
    //     }
    //   });
    // },
  },
};
</script>

<style lang="scss" scoped>
.content {
  .no-item {
    width: 100%;
    min-height: 40vh;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .alert-text {
      font-size: 15px;
      text-align: center;
    }
  }
}
</style>
