<template>
  <section class="land-info">
    <ul>
      <li class="item" v-for="(item, i) in informations" :key="i">
        <span class="yellow--text">{{ item.label }}</span>
        <span class="value">{{ item.value }}</span>
      </li>
    </ul>
  </section>
</template>

<script>
export default {
  props: ["selectedBuilding"],
  data() {
    return {
      informations: [
        { label: "Capacity", value: this.selectedBuilding.capacity },
        { label: "Construction time", value: this.selectedBuilding.construction_time },
        { label: "Depreciation time", value: this.selectedBuilding.depreciation_time },
        { label: "Destruction time", value: this.selectedBuilding.destruction_time },
        { label: "experience", value: this.selectedBuilding.experience },
        { label: "Level", value: this.selectedBuilding.level },
        { label: "Type", value: this.selectedBuilding.type },
        { label: "Power", value: this.selectedBuilding.power },
        { label: "Prestige", value: this.selectedBuilding.prestige },
      ],
    };
  },
  methods: {
    coinIconRender(name) {
      return `${process.env.VUE_APP_COIN_ICON_ADDRESS}/32/color/${name}.png`;
    },
  },
};
</script>

<style lang="scss" scoped>
.land-info {
  text-align: center;
  .item {
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    margin-top: 1%;
    padding: 3px 20px;
    border-radius: 5px;
    font-weight: 900;
    background: linear-gradient(to right, transparent, #243061, transparent);
    @media only screen and(max-width: 500px) {
      font-size: 12px;
    }
    .value {
      max-width: 50%;
    }
  }
}
</style>
