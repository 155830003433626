<template>
  <section class="land-info">
    <!-- <h2 class="item"><span class="yellow--text">Type :</span> Land</h2> -->
    <h3 class="item">
      <span class="yellow--text">Id</span>
      <span>{{ selectedUnit.id }}</span>
    </h3>
    <h3 class="item">
      <span class="yellow--text">Name</span>
      <span>{{ selectedUnit.unit.name }}</span>
    </h3>
    <h3 class="item">
      <span class="yellow--text">Housing space</span>
      <span>{{ selectedUnit.unit.housing_space }}</span>
    </h3>
    <h3 class="item">
      <span class="yellow--text">Description</span>
      <span style="max-width: 60%">{{ selectedUnit.unit.description }}</span>
    </h3>
    <div class="item" v-if="selectedUnit.sale">
      <h3 class="yellow--text mr-1">Price</h3>
      <div class="d-flex justify-center align-center">
        <h3 v-for="(value, name, i) in JSON.parse(selectedUnit.sale.price)" :key="i">
          <section v-if="value.price" class="d-flex align-center">
            <!-- <span> {{ name }} : </span> -->
            <span>{{ value.price }}</span>
            <img :src="coinIconRender(name)" width="17px" class="mx-1" />
            <span v-if="i > 1"> , </span>
          </section>
        </h3>
        <h1 class="ml-1" v-html="selectedUnit.sale.price_description"></h1>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: ["selectedUnit"],
  methods: {
    coinIconRender(name) {
      return `${process.env.VUE_APP_COIN_ICON_ADDRESS}/32/color/${name}.png`;
    },
  },
};
</script>

<style lang="scss" scoped>
.land-info {
  text-align: center;
  .item {
    display: flex;
    justify-content: space-between;
    margin-top: 2.5%;
    padding: 5px 20px;
    border-radius: 5px;
    background: linear-gradient(to right, transparent, #243061, transparent);
    @media only screen and(max-width: 500px) {
      font-size: 12px;
    }
  }
}
</style>
