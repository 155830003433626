import axios from "axios";
import store from "../../store/index.js";
import router from "../../router/index.js";

// origin
// if on local= "http://localhoset:8080/api/login"
// if on production="https://panel.invalert.com/api/login"

// VUE_APP_ROOT_API
//  if on local = "https://panel.invalert.com/api"
//  in on production = "/api"

const origin = window.location.origin;
const baseURL = process.env.VUE_APP_ROOT_API.includes("http")
  ? process.env.VUE_APP_ROOT_API
  : `${origin}/${process.env.VUE_APP_ROOT_API}`;

const mainApi = axios.create({
  // baseURL: process.env.VUE_APP_ROOT_API,
  baseURL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

mainApi.interceptors.request.use(
  (config) => {
    const token = store.getters.token;
    if (token) {
      config.headers.common["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
mainApi.interceptors.response.use(
  (response) => {
    //if request done successfully with success message and method is post or delete
    if (
      (response.data.success &&
        response.data.message &&
        response.request.__sentry_xhr__.method === "POST") ||
      response.request.__sentry_xhr__.method === "DELETE" ||
      response.request.__sentry_xhr__.method === "PUT"
    ) {
      store.dispatch("setAlert", {
        type: "success",
        message: response.data.message,
      });
    }
    return Promise.resolve(response);
  },
  (error) => {
    let status = error.response.status;
    let errors = error.response.data.errors;
    let message = error.response.data.message
    switch (status) {
      case 401:
        //Unauthenticated, token expire or wrong token(کاربر لاگین نیست)
        store.dispatch("logout");
        if (router.history.current.name !== "login") {
          router.push({ name: "Home" }).catch(() => {}); //go to landing;
        }
        break;
      case 402:
        //No balance موجودی ندارد
        router.push({ name: "payment" }).catch(() => {});
        break;
      case 403:
        //Unauthorized (لاگین هست اما دسترسی نداره)
        store.dispatch("setAlert", {
          title: "Unauthorized",
          message:
            "Unauthorized error!! you are not allow to access to this proccess.",
          type: "error",
        });
        break;
      case 422:
        //Validation error
        store.dispatch("clearServarValidations");
        for (const key in error.response.data.errors) {
          store.dispatch("setServerValidationError", {
            filed: key,
            message: error.response.data.errors[key][0],
          });
        }
        break;

      default:
        break;
    }

    if (typeof errors != 'string' && Object.values(errors).length) {
      for (const [key, value] of Object.entries(errors)) {
        let val = value.toString();

        store.dispatch("setAlert", {
          title: key,
          message: val,
          type: "error",
        });
      }
    } else {
      store.dispatch("setAlert", {
        message: message,
        type: "error",
      });
    }

    return Promise.reject(error);
  }
);

export default mainApi;
