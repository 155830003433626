var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"market-history routes"},[_c('v-data-table',{staticClass:"table elevation-1 mt-3 white--text",attrs:{"headers":_vm.headers,"items":_vm.saleHistoryList,"loading":_vm.isLoading,"items-per-page":15,"hide-default-footer":"","dark":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('section',{staticClass:"head d-flex justify-center pa-3 white--text"},[_c('h3',[_vm._v("Market History")])])]},proxy:true},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center align-center"},_vm._l((item.price),function(value,name,i){return _c('p',{key:i},[(value.price)?_c('section',{staticClass:"d-flex align-center"},[_c('span',[_vm._v(_vm._s(value.price))]),_c('img',{staticClass:"mx-1",attrs:{"src":_vm.coinIconRender(name),"width":"15px"}}),(i > 1)?_c('span',[_vm._v(" , ")]):_vm._e()]):_vm._e()])}),0)]}},{key:"item.price_description",fn:function(ref){
var item = ref.item;
return [(item.price_description)?_c('p',{domProps:{"innerHTML":_vm._s(item.price_description)}}):_c('span',[_vm._v("-")])]}},{key:"item.activity",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.action === 'Sell' ? item.status === 'pending' ? 'Listed for sale' : 'Sold' : 'Bought')+" ")]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.created_at))+" ")]}}])}),_c('div',{staticClass:"table-paginate-box"},[_c('div',{staticClass:"table-pagination-total"},[_vm._v(" "+_vm._s(_vm.total)+" "),_c('span',{},[_vm._v("result(s)")])]),_c('div',{staticClass:"paginate-box"},[_c('v-pagination',{staticClass:"pagination",attrs:{"length":_vm.lastPage,"total-visible":6,"color":"blue","circle":""},on:{"input":function($event){return _vm.fetchMarketHistory()}},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }