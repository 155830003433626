<template>
  <p id="timer" class="mb-0 mt-3 mt-sm-0">
    {{ subject }} {{ timer | minutesAndSeconds }}
  </p>
</template>

<script>
export default {
  props: {
    time: {
      type: Number,
      require: true
    },
    subject: {
      type: String
    }
  },
  data() {
    return {
      timer : '',
      interval: null
    };
  },
  filters: {
    minutesAndSeconds(value) {
      var minutes = Math.floor(parseInt(value, 10) / 60);
      var seconds = parseInt(value, 10) - minutes * 60;
      return `${minutes < 10 ? `0${minutes}` : minutes}:${
        seconds < 10 ? `0${seconds}` : seconds
      }`;
    }
  },
  beforeMount() {
    this.timer = this.time
  },
  mounted() {
    this.interval = setInterval(() => {
      this.timer -= 1;

      if (this.timer == 0) {
        clearInterval(this.interval);
        this.$emit("timeIsOver");
      }
    }, 1000);
  },
};
</script>
