<template>
  <v-col cols="12" lg="4" class="withdraw-account">
    <v-card outlined dark>
      <v-card-title class="grey darken-4 py-3">
        <div class="d-flex align-center justify-space-between w-100">
          <h4>Withdraw</h4>

          <ul>
            <li class="grey--text">
              <div class="balance-item">
                <span class="mr-1"> {{ withdrawCoinInfo.symbol }} : </span>
                <span class="white--text">{{ withdrawCoinInfo.amount }}</span>
                <img class="ml-1" :src="withdrawCoinInfo.image" width="15px" />
              </div>
            </li>
          </ul>
        </div>
      </v-card-title>

      <v-card-text>
        <p class="mb-4">
          Your Balance Must Be Grather Than 500 ECG For Withdraw
        </p>
        <p class="m-0" v-if="user">
          Wallet Address: {{ user.wallet_address || "Not Set" }}
        </p>

        <v-divider class="my-3" />

        <div>
          <div class="form-group">
            <label>Amount</label>
            <v-text-field
              type="text"
              v-model="withdrawCoinInfo.amount"
              outlined
              dense
              readonly
            ></v-text-field>
          </div>
          <div class="d-flex">
            <v-btn class="charge-btn" :loading="loading" :disabled="loading" @click="withdrawECG">
              Withdraw
            </v-btn>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      loading: false,
      withdrawCoinInfo: {},
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },

  methods: {
    ...mapActions(["withdraw"]),
    coinIconRender(name) {
      return `${process.env.VUE_APP_COIN_ICON_ADDRESS}/32/color/${name}.png`;
    },

    getECGInfo() {
      if (this.user) {
        let coins = this.user.coins;

        for (let i = 0; i < coins.length; i++) {
          const element = coins[i];
          if (element.name === "ecg") {
            this.withdrawCoinInfo = element;

            break;
          }
        }
      }
    },

    async withdrawECG() {
      // start loading
      this.loading = true;

      // set variable
      let content = {
        withdrawable_type: "wallet",
        withdrawable_id: this.withdrawCoinInfo.id,
      };

      // request
      await this.withdraw(content).then((res) => {
        if (res) {
          this.withdrawCoinInfo.amount = 0;
        }
      });

      // stop loading
      this.loading = false;
    },
  },
  mounted() {
    this.getECGInfo();
  },
  watch: {
    user() {
      this.getECGInfo();
    },
  },
};
</script>
<style lang="scss" scoped>
.withdraw-account {
  margin: 12px 0 0 0;
  .v-card {
    height: 100%;
  }
  .v-select {
    ::v-deep .v-input__slot {
      background: var(--base-color);
    }
    ::v-deep .v-input__control {
      min-height: none;
    }
  }
  ::v-deep.v-text-field {
    width: 100% !important;
  }
  ul {
    li {
      list-style: none;
      .balance-item {
        width: 100%;
        font-size: 12px;
        line-height: 14px;
        font-weight: 900;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 3px;
      }
    }
  }
  .charge-btn {
    background: var(--third-color);
    color: black;
    font-weight: bolder;
    width: 100%;
  }
}
</style>
