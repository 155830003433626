<template>
  <v-card class="card" dark>
    <v-card-title class="card-title d-flex justify-space-between">
      <h4 class="white--text">Confirmation</h4>
      <v-icon @click="cancel">mdi-close-circle-outline</v-icon>
    </v-card-title>
    <v-card-text class="py-6 card-text">
      <h4>{{ text }}</h4>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
            color="error"
            text
            @click="cancel"
            >cancel</v-btn
          >
      <v-btn
            color="success"
            text
            @click="accept"
            :loading="isLoading"
            >Accept</v-btn
          >
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: ["text", "isLoading"],
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    accept() {
      this.$emit("accept");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/components/dialogCard.scss";

</style>
