import mainApi from "../../plugins/axios/axios.js";
const state = {};

const mutations = {};

const actions = {
  getMapItems(_, info) {
    return new Promise((resolve, reject) => {
      mainApi
        .get(`/market/maps`, {
          params: info,
        })
        .then((result) => {
          resolve(result.data);
        })
        .catch((err) => {
         
          reject();
        });
    });
  },
  getDetails(_, info) {
    return new Promise((resolve, reject) => {
      mainApi
        .get(`/users/maps`, {
          params: info,
        })
        .then((result) => {
          resolve(result.data);
        })
        .catch((err) => {
         
          reject();
        });
    });
  },

  //assign land to location
  assignLandToLocation(_, info) {
    return new Promise((resolve, reject) => {
      mainApi
        .post(`/users/maps`, info)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
         
          reject();
        });
    });
  },
 
};
const getters = {};

export default {
  state,
  getters,
  mutations,
  actions,
};
