<template>
  <section class="legend-info">
    <!-- <h2 class="item"><span class="yellow--text">Type :</span> legend</h2> -->
    <h3 class="item">
      <span class="yellow--text">Id</span>
      <span>{{ selectedLegend.id }}</span>
    </h3>
    <h3 class="item">
      <span class="yellow--text">Country</span>
      <div class="d-flex align-center">
        <!-- <img
          :src="selectedLegend.legend.country.flag"
          :alt="selectedLegend.legend.country.name || ''"
          class="ms-1"
          height="25"
        /> -->
        <span>{{ selectedLegend.legend.country.name || "" }}</span>
      </div>
    </h3>
    <h3 class="item">
      <span class="yellow--text">Name</span>
      <span>{{
        selectedLegend.legend ? selectedLegend.legend.name : selectedLegend.name
      }}</span>
    </h3>
    <h3 class="item">
      <span class="yellow--text">Gender</span>
      <span>{{
        selectedLegend.legend ? selectedLegend.legend.gender : ""
      }}</span>
    </h3>

    <h3 class="item">
      <span class="yellow--text">Total</span>
      <span>{{ selectedLegend.legend ? selectedLegend.legend.total : 0 }}</span>
    </h3>
    <h3 class="item">
      <span class="yellow--text">Available</span>
      <span>{{
        selectedLegend.legend ? selectedLegend.legend.available : 0
      }}</span>
    </h3>
    <div class="item" v-if="selectedLegend.sale">
      <h3 class="yellow--text mr-1">Price</h3>
      <div class="d-flex justify-center align-center">
        <h3
          v-for="(value, name, i) in JSON.parse(selectedLegend.sale.price)"
          :key="i"
        >
          <section v-if="value.price" class="d-flex align-center">
            <!-- <span> {{ name }} : </span> -->
            <span>{{ value.price }}</span>
            <img :src="coinIconRender(name)" width="17px" class="mx-1" />
            <span v-if="i > 1"> , </span>
          </section>
        </h3>
        <h1 class="ml-1" v-html="selectedLegend.sale.price_description"></h1>
        {{ infoShow }}
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: ["selectedLegend"],
  data() {
    return {
      info: 1,
    };
  },
  computed: {
    infoShow() {
      console.log(this.info);
      return "12";
    },
  },
  methods: {
    coinIconRender(name) {
      return `${process.env.VUE_APP_COIN_ICON_ADDRESS}/32/color/${name}.png`;
    },
  },
  mounted() {
    setInterval(() => {
      this.info = +1;
    }, 2000);
  },
};
</script>

<style lang="scss" scoped>
.legend-info {
  text-align: center;
  .item {
    display: flex;
    justify-content: space-between;
    margin-top: 2.5%;
    padding: 5px 20px;
    border-radius: 5px;
    background: linear-gradient(to right, transparent, #243061, transparent);
    @media only screen and(max-width: 500px) {
      font-size: 12px;
    }
  }
}
</style>
