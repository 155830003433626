<template>
  <section class="sales sec-wrapper">
    <HeadingTitle>Sales Lands</HeadingTitle>
    <div class="row mx-auto observe-item">
      <div
        v-for="(item, index) in landsReport"
        :key="index"
        cols="6"
        md="2.5"
        class="item"
      >
        <div class="content">
          <h1>{{ item.totalSales }}</h1>
          <span class="">of {{ item.totalLands }}</span>
        </div>
        <p class="size">{{ item.title }} Lands</p>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      loading: false,
      landsReport: [],
    };
  },
  async created() {
    
    await this.get_lands_report();
  },
  methods: {
    ...mapActions(["getLandsReports"]),

    imageSrc(img) {
      return `${process.env.VUE_APP_ROOT_API}/${img}`;
    },

    calcValue(sales, total) {
      return (sales * 100) / total;
    },

    // Get Lands Report
    async get_lands_report() {
      this.loading = true;

      await this.getLandsReports().then((res) => {
        if (res) {
          this.landsReport = res;
          
        }
      });
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.sales {
  background: radial-gradient(ellipse at bottom right, #00a2ff76 5%, black 70%);
  color: yellow;
  .row {
    width: 95%;
    display: grid;
    grid-row-gap: 50px;
    grid-template-columns: repeat(5, 1fr);
    // grid-template-rows: repeat(2, 1fr);
    margin-top: 20px;
    @media only screen and (max-width: 960px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media only screen and (max-width: 550px) {
      grid-template-columns: repeat(2, 1fr);
      grid-row-gap: 30px;
    }
  }
  .item {
    text-align: center;
    margin: 0 auto;
    background: radial-gradient(circle at 50%, #0ec4f2, rgb(93, 93, 93));
    -webkit-background-clip: text;
    -webkit-text-stroke: 4px transparent;
    padding: 0 2px;
    color: #160f1a;
    filter: drop-shadow(0 5px 20px rgba(204, 0, 255, 0.15));
    .content {
      display: flex;
      align-items: baseline;
      justify-content: center;
      h1 {
        font-size: 30px;
        margin-right: 4px;
      }
    }

    @media only screen and (max-width: 960px) {
      &:last-child {
        grid-column: 2;
      }
    }
    @media only screen and (max-width: 550px) {
      font-size: 10px;
    }
  }
}
</style>
