import mainApi from "../../plugins/axios/axios.js";
const state = {};

const mutations = {};

const actions = {
  getMarketData(_, info) {
    return new Promise((resolve, reject) => {
      mainApi
        .get(`/market/sales`, {
          params: info,
        })
        .then((result) => {
          resolve(result.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getSellersList(_, payload) {
    return new Promise((resolve, reject) => {
      mainApi
        .get(`/market/sales/extra/${payload.id}`, {
          params: payload.params,
        })
        .then((result) => {
          resolve(result.data);
        })
        .catch((err) => {
          reject();
        });
    });
  },
  getMarketHistory(_, params) {
    return new Promise((resolve, reject) => {
      mainApi
        .get(`/users/sales/histories`, {
          params: params,
        })
        .then((result) => {
          resolve(result.data);
        })
        .catch((err) => {
          reject();
        });
    });
  },
  getItemDetails(_, payload) {
    return new Promise((resolve, reject) => {
      mainApi
        .get(`market/${payload.itemsName}/${payload.id}`)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject();
        });
    });
  },
  getCategories() {
    return new Promise((resolve, reject) => {
      mainApi
        .get(`market/categories`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((err) => {
          reject();
        });
    });
  },
  getLegendCountries() {
    return new Promise((resolve, reject) => {
      mainApi
        .get(`/market/legends/countries`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((err) => {
          reject();
        });
    });
  },
  getSubCategories(_, payload) {
    return new Promise((resolve, reject) => {
      mainApi
        .get(`market/categories/${payload}/subcategories`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((err) => {
          reject();
        });
    });
  },
  getTags(_, type) {
    return new Promise((resolve, reject) => {
      mainApi
        .get(`/market/tags/${type}`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((err) => {
          reject();
        });
    });
  },

  getMaxCordinate() {
    return new Promise((resolve, reject) => {
      mainApi
        .get(`market/max-coordinate`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((err) => {
          reject();
        });
    });
  },
  getPriceRange(_, type) {
    return new Promise((resolve, reject) => {
      mainApi
        .get(`market/range-price/${type}`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((err) => {
          reject();
        });
    });
  },

  getBuildingTypes() {
    return new Promise((resolve, reject) => {
      mainApi
        .get(`market/buildings/types`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((err) => {
          reject();
        });
    });
  },

  getBuildingFunctionalities() {
    return new Promise((resolve, reject) => {
      mainApi
        .get(`market/buildings/functionalities`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((err) => {
          reject();
        });
    });
  },

  buy(_, payload) {
    return new Promise((resolve, reject) => {
      mainApi
        .put(`/users/sales/${payload.id}`, { buyer_land_id: "" }, {
          params: payload.params
        })
        .then((result) => {
          resolve(result.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  async getLandsReports() {
    try {
      const { data } = await mainApi.get(
        "/lands/reportWithTag?tagIds[]=1&tagIds[]=2&tagIds[]=3&tagIds[]=4&tagIds[]=5&tagIds[]=6&tagIds[]=7&tagIds[]=8&tagIds[]=9&tagIds[]=10"
      );

      return data.result;
    } catch (error) {
      return false;
    }
  },

  getLootboxes(_, params) {
    return new Promise((resolve, reject) => {
      mainApi
        .get(`/market/lootboxes`, {
          params: params,
        })
        .then((result) => {
          resolve(result.data);
        })
        .catch((err) => {
          reject();
        });
    });
  },
};
const getters = {};

export default {
  state,
  getters,
  mutations,
  actions,
};
