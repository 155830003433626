<template>
  <section class="land-details">
    <div class="land-details-head">
      <div style="width: 100%; height: 45vh" v-if="hasAnimate">
        <iframe
          :src="`${storageImageURL(
            selectedDecorative.decorative.animate
          )}?autostart=1`"
          frameborder="0"
          width="100%"
          height="100%"
        ></iframe>
      </div>
      <v-img
        :src="storageImageURL(selectedDecorative.decorative.image)"
        width="100%"
        max-width="190"
        contain
        class="land-img mx-auto"
        @load="imgLoaded"
        v-else
      >
        <v-skeleton-loader
          type="image@2"
          class="land-img-skeleton"
          v-if="!imgIsLoaded"
          width="100%"
          height="225"
        ></v-skeleton-loader>
      </v-img>

      <!-- <h3 class="land-name">
        {{ selectedDecorative.decorative.name }}
      </h3> -->
    </div>
    <main class="land-details-content mt-1">
      <div class="base-tabs">
        <ul class="d-flex">
          <li
            v-for="(item, i) in baseTabs"
            :key="i"
            @click="changeTabs(item)"
            class="white--text"
            :class="item.isActive ? 'active' : ''"
          >
            {{ item.name }}
          </li>
        </ul>
      </div>
      <div class="line"></div>
      <Info
        v-if="baseTabs[0].isActive"
        :selectedDecorative="selectedDecorative"
      />
      <InGameInfo
        v-if="baseTabs[1].isActive"
        :selectedDecorative="selectedDecorative.decorative"
      />
      <Owner v-if="baseTabs[2].isActive" :owner="selectedDecorative.user" />
      <Sell
        v-if="baseTabs[3].isActive"
        :selectedDecorative="selectedDecorative"
      />
      <ForSell
        v-if="baseTabs[4].isActive"
        :selectedDecorative="selectedDecorative"
        @buy="buyItem"
      />
    </main>
  </section>
</template>

<script>
import Info from "./tabs/Info.vue";
import Owner from "./tabs/Owner.vue";
import Sell from "./tabs/Sell.vue";
import ForSell from "./tabs/ForSell.vue";
import InGameInfo from "./tabs/InGameInfo.vue";
import { storageImage } from "@/mixins/storageImage.js";
export default {
  mixins: [storageImage],
  props: ["selectedDecorative"],
  data() {
    return {
      baseTabs: [
        {
          name: "Info",
          isActive: true,
        },
        {
          name: "InGame Info",
          isActive: false,
        },
        {
          name: "Owner",
          isActive: false,
        },
        {
          name: "Sale info",
          isActive: false,
        },
        {
          name: "For Sell",
          isActive: false,
        },
      ],
      imgIsLoaded: false,
      mainApi: process.env.VUE_APP_ROOT_API,
    };
  },
  components: {
    Info,
    Owner,
    Sell,
    InGameInfo,
    ForSell,
  },
  computed: {
    hasAnimate() {
      return (
        this.selectedDecorative.decorative.animate &&
        !!this.selectedDecorative.decorative.animate.length
      );
    },
  },
  mounted() {},
  methods: {
    buyItem(info) {
      this.$emit("buy", info);
    },
    changeTabs(item) {
      //change tab from old to new and save in session
      let oldTab = this.baseTabs.find((element) => element.isActive === true);
      oldTab.isActive = !oldTab.isActive;
      let newTab = this.baseTabs.find((element) => element.name === item.name);
      newTab.isActive = !newTab.isActive;
    },
    imgLoaded() {
      this.imgIsLoaded = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.land-details {
  &-head {
    .land-img {
      border-radius: 10px;
    }
    .land-name {
      color: white;
      margin: 10px;
      text-align: center;
    }
  }
}
</style>
