<template>
  <section class="shop">
    <!-- main-shop -->

    <section class="main-shop mx-auto">
      <!-- tabs -->
      <div class="tabs">
        <ul class="d-flex pa-0">
          <li
            v-for="(item, i) in tabs"
            :key="i"
            @click="changeTabs(item)"
            :class="item.name === currentTabComponent.name ? 'active' : ''"
          >
            <i :class="`fa-solid ${item.icon}`" class="tab-icon"></i>
            <span>{{ item.name }}</span>
          </li>
        </ul>
      </div>
      <component v-bind:is="currentTabComponent.name"></component>
    </section>
    <!-- main-shop -->
  </section>
</template>
<script>
import Legend from "../../components/shop/legends/Legend.vue";
import Lands from "../../components/shop/lands/Lands.vue";
import Lootboxes from "../../components/shop/lootboxes/Lootboxes.vue";
import Buildings from "../../components/shop/buildings/Buildings.vue";
import Decoratives from "../../components/shop/decoratives/Decoratives.vue";
import Units from "../../components/shop/units/Units.vue";
import Currencies from "../../components/shop/currencies/Currencies.vue";
import Packs from "../../components/shop/packs/Packs.vue";
export default {
  data() {
    return {
      //tabs
      tabs: [
        {
          name: "Legend",
          icon: "fa-bolt",
        },
        {
          name: "Lands",
          icon: "fa-map-location-dot",
        },
        {
          name: "Lootboxes",
          icon: "fa-gift",
        },
        {
          name: "Buildings",
          icon: "fa-building",
        },
        {
          name: "Decoratives",
          icon: "fa-wand-magic-sparkles",
        },
        {
          name: "Units",
          icon: "fa-house",
        },
        {
          name: "Currencies",
          icon: "fa-coins",
        },
        {
          name: "Packs",
          icon: "fa-box",
        },
      ],
      currentTabComponent: {
        name: "Lands",
        icon: "fa-map-location-dot",
      },
    };
  },
  components: {
    Legend,
    Lands,
    Lootboxes,
    Buildings,
    Decoratives,
    Units,
    Currencies,
    Packs,
  },

  created() {
    if (this.$route.query.type) {
      const tabName = this.$route.query.type;
      const findedElement = this.tabs.find(
        (element) => element.name.toLowerCase() === tabName.toLowerCase()
      );
      this.currentTabComponent = findedElement;
    } else if (sessionStorage.getItem("shop-tab")) {
      const previuosSavedTab = JSON.parse(sessionStorage.getItem("shop-tab"));
      this.changeTabs(previuosSavedTab);
    }
  },

  methods: {
    changeTabs(item) {
      this.currentTabComponent = item;
      this.saveTab();
      this.$router.push({ name: "shop" }).catch(() => {});
    },
    saveTab() {
      sessionStorage.setItem(
        "shop-tab",
        JSON.stringify(this.currentTabComponent)
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.shop {
  border: 5px solid rgb(157, 157, 157);
  border-radius: 3px;
  margin: 105px auto 0 auto;
  width: 100%;
  max-width: 1800px;
  @media only screen and(max-width: 420px) {
    border: none;
    border-top: 3px solid grey;
    border-bottom: 3px solid grey;
  }
  .tabs {
    ul {
      li {
        span {
          font-size: clamp(8px, 1.8vw, 15px);
          @media only screen and(max-width: 420px) {
            display: none;
          }
        }
        .tab-icon {
          font-size: clamp(12px, 2.5vw, 15px);
          margin-right: 5px;
          @media only screen and(max-width: 420px) {
            margin-right: 0;
          }
        }
      }
    }
  }
}
</style>
