<template>
  <div class="game-container routes">
    <HeadingTitle class="head">LANDS OF AZOLLITE</HeadingTitle>
    <p class="observe-item i-1">
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Itaque fuga accusamus
      ratione modi dolore! Natus distinctio magni, ab consequuntur maxime recusandae
      libero. Nisi perspiciatis, laboriosam officiis alias soluta quaerat id!
    </p>
    <GameMode class="observe-item i-2"></GameMode>
  </div>
</template>

<script>
import GameMode from "./GameMode.vue";
export default {
  data() {
    return {};
  },
  components: {
    GameMode,
  },
  mounted() {
    this.observeAnimation();
  },
  computed: {},
  methods: {
    observeAnimation() {
      const cards = document.getElementsByClassName("observe-item");
      let observer = new IntersectionObserver((enteries) => {
        enteries.forEach((entery) => {
          if (entery.isIntersecting) {
            //if object is exsist in view port and threshold is 0
            entery.target.classList.add("observed");
          }
        });
      });
      for (let i = 0; i < cards.length; i++) {
        observer.observe(cards[i]);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  min-height: 100vh;
  width: 100vw;
  background: url("../../assets/images/game/game-bg.jpg") no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  .head {
    &::v-deep {
      h2 {
        margin: 10px;
      }
    }
  }
  p {
    font-size: 15px;
    font-weight: 500;
    width: 95%;
    max-width: 800px;
    text-align: center;
    color: #ffffff;
    text-shadow: 0px 10px 5px rgba(0, 0, 0, 0.553);
    @media only screen and(max-width:500px) {
      font-size: 12px;
    }
  }
}
</style>
