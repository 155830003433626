<template>
  <section>
    <h2 class="grey--text text-center mt-15" v-if="!selectedLegend.sale">
      No sale information!
    </h2>

    <div class="sell" v-else>
      <div class="px-3">
        <h3 class="item yellow--text">
          Status
          <span class="grey--text">{{ selectedLegend.sale.status }}</span>
        </h3>
        <div class="item" v-if="selectedLegend.sale">
          <h3 class="yellow--text mr-1">Price</h3>
          <div class="d-flex justify-center align-center">
            <h3 v-for="(value, name, i) in JSON.parse(selectedLegend.sale.price)" :key="i">
              <section v-if="value.price" class="d-flex align-center">
                <!-- <span> {{ name }} : </span> -->
                <span>{{ value.price }}</span>
                <img :src="coinIconRender(name)" width="17px" class="mx-1" />
                <span v-if="i > 1"> , </span>
              </section>
            </h3>
            <h1 class="ml-1" v-html="selectedLegend.sale.price_description"></h1>
          </div>
        </div>
        <h3 class="yellow--text">
          <span class="red--text" v-html="selectedLegend.sale.priceDescription"></span>
        </h3>
        <v-data-table
          :headers="headers"
          :items="selectedLegend.sales"
          :items-per-page="15"
          hide-default-footer
          dense
          class="table elevation-1 mt-3 black--text"
          dark
        >
          <template v-slot:top>
            <section class="head d-flex justify-center pa-3 white--text">
              <h3>Transfer List</h3>
            </section>
          </template>
          <template v-slot:item.buyer_user_id="{ item }">
            {{ item.buyer_user_id ? item.buyer_user_id : "-" }}
          </template>
          <template v-slot:item.price="{ item }">
            <span v-for="(value, name, i) in JSON.parse(item.price)" :key="i">
              <span v-if="value.price" class="d-flex">
                <span>{{ value.price }}</span>
                <img :src="coinIconRender(name)" width="13px" class="mx-1" />
              </span>
            </span>
          </template>
          <template v-slot:item.price_description="{ item }">
            <h1 class="ml-1" v-html="item.price_description"></h1>
          </template>
          <template v-slot:item.situation="{ item }">
            {{ situationRender(item) }}
          </template>
        </v-data-table>

        <div class="table-paginate-box">
          <div class="table-pagination-total">
            {{ selectedLegend.sales.length }} <span class="">result(s)</span>
          </div>
          <div class="paginate-box">
            <v-pagination
              v-model="page"
              class="pagination"
              :length="lastPage"
              :total-visible="6"
              @input="fetchPayments()"
              color="blue"
              circle
            ></v-pagination>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: ["selectedLegend"],
  data() {
    return {
      page: 1,
      lastPage: null,
      total: 0,
      headers: [
        {
          text: "ID",
          align: "start",
          sortable: false,
          value: "id",
          class: "black--text",
        },
        {
          text: "seller id",
          value: "seller_user_id",
          class: "black--text",
          align: "center",
          sortable: false,
        },
        {
          text: "buyer id",
          value: "buyer_user_id",
          class: "black--text",
          align: "center",
          sortable: false,
        },
        {
          text: "Price",
          value: "price",
          class: "black--text",
          sortable: false,
        },
        {
          text: "OFF",
          value: "price_description",
          class: "black--text",
          align: "center",
          sortable: false,
        },
        { text: "Status", value: "status", class: "black--text", sortable: false },
        {
          text: "Situation",
          value: "situation",
          class: "black--text",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    coinIconRender(name) {
      return `${process.env.VUE_APP_COIN_ICON_ADDRESS}/32/color/${name}.png`;
    },
    situationRender(item) {
      if (item.status === "pending" && !item.buyer_user_id) return "listed for sell";
      else if (item.status !== "pending " && item.buyer_user_id) return "purchased";
      else if (item.status !== "pending " && !item.buyer_user_id) return "canceled";
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  display: flex;
  justify-content: space-between;
  margin-top: 2.5%;
  padding: 5px 20px;
  border-radius: 5px;
  background: #1f2642;
  @media only screen and(max-width: 500px) {
    font-size: 12px;
  }
}
.table {
  ::v-deep thead th {
    font-size: 9px !important;
    padding: 0 10px !important;
    font-weight: 500;
  }

  ::v-deep tbody td {
    font-size: 9px !important;
    padding: 0 10px !important;
  }
}
.table-paginate-box {
  padding: 3px 10px;
  .table-pagination-total {
    padding: 2px 5px;
  }
}
</style>
