<template>
  <section>
    <div class="n-tabs mb-3">
      <ul class="d-flex pa-0">
        <li
          v-for="(item, i) in tabs"
          :key="i"
          @click="changeTabs(item)"
          :class="item.isActive ? 'active' : ''"
        >
          <span v-if="item.isActive">{{ item.name }}</span>
          <i :class="`fa-solid ${item.icon}`" class="tab-icon" v-else></i>
        </li>
      </ul>
    </div>
    <UserLegends v-if="tabs[0].isActive" />
    <UserLands v-if="tabs[1].isActive" />
    <UserLootboxes v-if="tabs[2].isActive" />
    <UnchainNft v-if="tabs[3].isActive" />
    <UserBuildings v-if="tabs[4].isActive" />
    <UserDecoratives v-if="tabs[5].isActive" />
    <UserUnits v-if="tabs[6].isActive" />
    <UserCurrencies v-if="tabs[7].isActive" />
  </section>
</template>

<script>
import UserLegends from "@/components/assets/legends/UserLegends.vue";
import UserLands from "@/components/assets/lands/UserLands.vue";
import UserLootboxes from "@/components/assets/lootboxes/UserLootboxes.vue";
import UnchainNft from "@/components/assets/nfts/UnchainNft.vue";
import UserBuildings from "@/components/assets/buildings/UserBuildings.vue";
import UserDecoratives from "@/components/assets/decoratives/UserDecoratives.vue";
import UserUnits from "@/components/assets/units/UserUnits.vue";
import UserCurrencies from "@/components/assets/currencies/UserCurrencies.vue";
export default {
  data() {
    return {
      tabs: [
        {
          name: "Legend",
          icon: "fa-bolt",
          isActive: true,
        },
        {
          name: "Lands",
          icon: "fa-map-location-dot",
          isActive: false,
        },
        {
          name: "Lootboxes",
          icon: "fa-gift",
          isActive: false,
        },
        {
          name: "NFTs",
          icon: "fa-cubes",
          isActive: false,
        },
        {
          name: "Buildings",
          icon: "fa-building",
          isActive: false,
        },
        {
          name: "Decoratives",
          icon: "fa-wand-magic-sparkles",
          isActive: false,
        },
        {
          name: "Units",
          icon: "fa-house",
          isActive: false,
        },
        {
          name: "Currencies",
          icon: "fa-coins",
          isActive: false,
        },
      ],
      previousTab: {},
    };
  },
  components: {
    UserLegends,
    UserLands,
    UserLootboxes,
    UnchainNft,
    UserBuildings,
    UserDecoratives,
    UserUnits,
    UserCurrencies,
  },
  created() {
    //get session tab name and find it and change tab to that\
    if (sessionStorage.getItem("assets-tab")) {
      this.previousTab = this.tabs.find(
        (element) => element.name === sessionStorage.getItem("assets-tab")
      );
      this.changeTabs(this.previousTab);
    }
  },
  methods: {
    changeTabs(item) {
      //change tab from old to new and save in session
      let oldTab = this.tabs.find((element) => element.isActive === true);
      oldTab.isActive = !oldTab.isActive;
      let newTab = this.tabs.find((element) => element.name === item.name);
      newTab.isActive = !newTab.isActive;
      this.handleSaveTab();
    },
    handleSaveTab() {
      //save active tab(object) name to session
      this.previousTab = this.tabs.find((element) => element.isActive === true);
      sessionStorage.setItem("assets-tab", this.previousTab.name);
    },
  },
};
</script>

<style lang="scss" scoped>
// tabs
.n-tabs {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  ul {
    border: 3px solid var(--secondary-color) !important;
    padding: 5px 3px !important;
    border-radius: 6px;
    @media only screen and(max-width: 400px) {
      border: 2px solid var(--secondary-color) !important;
      padding: 0;
    }
    li {
      min-width: clamp(32px, 8vw, 120px);
      list-style: none;
      font-weight: 500;
      font-size: 13px;
      padding: 5px 3px;
      margin: 0 1px;
      color: var(--secondary-color);
      cursor: pointer;
      text-align: center;
      transition: all 0.3s ease;
      user-select: none;
      border: 1px solid transparent;
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        border: 1px solid var(--secondary-color);
      }
      &.active {
        border: 1px solid transparent;
        color: var(--base-color);
        background: var(--secondary-color);
        font-weight: bolder !important;
      }
      .tab-icon {
        font-size: 21px;
      }
    }
  }
}
// tabs
</style>
