<template>
  <v-col cols="6" sm="6" md="4" lg="3" class="col px-1 mt-4 mt-sm-8">
    <v-skeleton-loader
      v-if="!bgIsLoaded"
      type="image"
      min-height="220"
    ></v-skeleton-loader>
    <section class="item-card" v-show="bgIsLoaded">
      <img
        src="../../../assets/images/cards/land-card-bg.png"
        class="item-card-background"
        @click="showDetails(item)"
        @load="mainBgIsLoaded"
      />

      <img />
      <v-img
        :src="storageImageURL(item.details.image)"
        class="item-card-image"
        @load="imgIsLoaded"
      >
        <v-skeleton-loader
          v-if="!imageIsLoaded"
          width="100%"
          height="100%"
          type="image"
          class="skeleton"
        ></v-skeleton-loader>
      </v-img>
      <div v-if="bgIsLoaded">
        <div class="item-card-name-container">
          <span>{{ item.details.name }}</span>
        </div>
        <h5 class="item-card-description">
          <span class="text-center">{{ item.details.description }}</span>
        </h5>
        <ul class="item-card-price pa-0">
          <li
            v-for="(value, name, i) in item.price"
            :key="i"
            style="margin-bottom: 1px"
          >
            <div
              v-if="value.price > 0"
              class="d-flex align-center justify-space-between"
            >
              <span class="mr-1">{{ value.price }} </span>
              <img :src="coinIconRender(name)" width="11px" />
            </div>
          </li>
        </ul>
      </div>

      <button
        class="item-card-shop-button"
        @click.stop="buyItem({ id: item.id, auto: true })"
        v-if="$route.name !== 'assets'"
      >
        <span>Buy Now</span>
      </button>
      <button
        class="item-card-shop-button"
        @click.stop="cancelSell(item)"
        v-else
      >
        <span class="error--text" style="text-shadow: 0px 0px 4px red"
          >Cancle</span
        >
      </button>
    </section>
  </v-col>
</template>

<script>
import { storageImage } from "@/mixins/storageImage.js";
export default {
  mixins: [storageImage],
  props: ["item"],
  data() {
    return {
      bgIsLoaded: false,
      imageIsLoaded: false,
    };
  },
  methods: {
    showDetails(item) {
      this.$emit("showDetails", item);
    },
    buyItem(info) {
      this.$emit("buyItem", info);
    },
    cancelSell(item) {
      this.$emit("cancelSell", item);
    },
    mainBgIsLoaded() {
      this.bgIsLoaded = true;
    },
    imgIsLoaded() {
      this.imageIsLoaded = true;
    },
    coinIconRender(name) {
      // return `${process.env.VUE_APP_COIN_ICON_ADDRESS}/32/color/${name}.png`
      return `https://panel.easybitpay.com/icons/32/color/${name}.png`;
    },
  },
};
</script>

<style lang="scss" scoped>
.col {
  transition: all 0.3s ease;
  @media only screen and(max-width: 500px) {
    font-size: 9px;
  }
  .item-card {
    .item-card-name-container {
      top: 3%;
    }
  }
}
</style>
