<template>
  <section class="video-container sec-wrapper">
    <!-- <h2 class="wrapper-head">Toturial</h2> -->
    <HeadingTitle>Toturial</HeadingTitle>
    <div class="video-box observe-item">
      <video
        @click="togglePlayPause"
        class="video"
        :controls="!isPaused"
        src="https://landsofazolite.com/api/storage/landsofazolite.mp4"
        poster="@/assets/images/video/video-poster.jpg"
      ></video>
      <play-video-button v-if="isPaused" @clicked="togglePlayPause"></play-video-button>
    </div>
  </section>
</template>

<script>
import PlayVideoButton from "./PlayVideoButton.vue";
export default {
  data() {
    return {
      isPaused: true,
    };
  },
  components: {
    PlayVideoButton,
  },
  methods: {
    togglePlayPause() {
      var video = document.querySelector("video");
      if (video.paused) {
        this.isPaused = false;
        video.play();
      } else {
        this.isPaused = true;
        video.pause();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.video-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // background: radial-gradient(ellipse at bottom right, #00a2ff76 5%, black 70%);
  background: linear-gradient(to top, #00a2ff76, black);
  .video-box {
    position: relative;
    width: 95%;
    max-width: 1100px;
    border: 2px solid #0ec4f2;
    border-radius: 5px;
    overflow: hidden;
    display: flex;
    align-items: center;
    .video {
      width: 100%;
    }
  }
}
</style>
