<template>
  <v-card-text class="py-3 card-text">
    <div class="loading" v-if="isLoading">
      <loading-spinner></loading-spinner>
    </div>
    <section class="contant d-flex flex-column" v-else>
      <legend-detail
        :selectedLegend="selectedItem"
        v-if="type === 'legend'"
        class="detail-components"
        @buy="buyItem"
      ></legend-detail>
      <land-detail
        :selectedLand="selectedItem"
        v-if="type === 'land'"
        class="detail-components"
        @buy="buyItem"
      ></land-detail>
      <lootbox-detail
        :selectedLootbox="selectedItem"
        v-if="type === 'lootbox'"
        class="detail-components"
      ></lootbox-detail>
      <building-detail
        :selectedBuilding="selectedItem"
        v-if="type === 'building'"
        class="detail-components"
        @buy="buyItem"
      ></building-detail>
      <decorative-detail
        :selectedDecorative="selectedItem"
        v-if="type === 'decorative'"
        class="detail-components"
        @buy="buyItem"
      ></decorative-detail>
      <unit-detail
        :selectedUnit="selectedItem"
        v-if="type === 'unit'"
        class="detail-components"
        @buy="buyItem"
      ></unit-detail>
      <pack-detail
        :selectedPack="selectedItem"
        v-if="type === 'pack'"
        class="detail-components"
        @packItemShowDetails="packItemShowDetails"
      ></pack-detail>
      <currency-detail
        :selectedCurrnecy="selectedItem"
        v-if="type === 'currency'"
        class="detail-components"
      ></currency-detail>
    </section>
  </v-card-text>
</template>

<script>
import LandDetail from "../content/landDetails/LandDetail.vue";
import LegendDetail from "../content/legendDetails/LegendDetail.vue";
import LootboxDetail from "../content/lootboxDetails/LootboxDetail.vue";
import BuildingDetail from "../content/buildingDetails/BuildingDetail.vue";
import DecorativeDetail from "../content/decorativeDetails/DecorativeDetail.vue";
import UnitDetail from "../content/unitDetails/UnitDetail.vue";
import PackDetail from "../content/packDetails/PackDetail.vue";
import CurrencyDetail from "../content/CurrencyDetails/CurrencyDetail.vue";
export default {
  props: ["selectedItem", "isLoading", "type"],
  components: {
    LandDetail,
    LegendDetail,
    LootboxDetail,
    BuildingDetail,
    DecorativeDetail,
    UnitDetail,
    PackDetail,
    CurrencyDetail,
  },
  created() {},
  methods: {
    buyItem(info) {
      this.$emit("buy", info);
    },
    packItemShowDetails(item) {
      this.$emit("packIShowDetails", item);
    },
  },
};
</script>
<style lang="scss" scoped>
.card-text {
  position: absolute;
  padding-bottom: 50px !important;
}
</style>
