<template>
  <section class="profile routes d-flex flex-column aling-center">
    <div class="head">
      <h2 class="text-center">profile</h2>
    </div>
    <div class="line"></div>
    <div class="profile-card mx-auto">
      <v-row>
        <v-col>
          <section class="">
            <div class="loading" v-if="isLoading">
              <loading-spinner></loading-spinner>
            </div>
            <section class="content pa-4" v-else>
              <div class="mb-5" v-if="wallet_address">
                <label class="">Wallet address:</label>
                <p class="wallet">
                  {{ wallet_address }}
                  <i class="icon fa-solid fa-copy" @click="copyWallet">
                    <span class="tooltip" v-if="isWalletCopied"
                      >Wallet address Copied!</span
                    ></i
                  >
                </p>
              </div>
              <v-form @submit.prevent="update_profile" ref="updateProfileForm">
                <section class="email">
                  <label for="">Email</label>
                  <section class="d-flex justify-space-between">
                    <v-text-field
                      v-model="email"
                      type="email"
                      :rules="rules.email_rules"
                      outlined
                      dense
                      clearabl
                      dark
                    ></v-text-field>
                    <v-btn
                      height="40"
                      type="button"
                      :loading="verifyLoading"
                      @click="emailVerify"
                      class="btn regular"
                      :class="emailVerified ? 'email-verified' : ''"
                    >
                      {{ emailVerified ? "Verified" : "Verify" }}
                    </v-btn>
                  </section>
                </section>
                <section class="username">
                  <label for="">Username</label>
                  <section class="d-flex justify-space-between">
                    <v-text-field
                      v-model="username"
                      type="text"
                      :rules="rules.required"
                      outlined
                      dense
                      clearabl
                      dark
                    ></v-text-field>
                  </section>
                </section>

                <section class="pb-4 mb-4 section-space">
                  <v-btn
                    height="40"
                    width="100%"
                    type="submit"
                    :loading="updatePofileLoading"
                    class="btn regular"
                  >
                    update
                  </v-btn>
                </section>
              </v-form>

              <section class="referral-code">
                <label for="">Referral Code</label>
                <section class="d-flex justify-space-between">
                  <v-text-field
                    type=""
                    outlined
                    dense
                    dark
                    placeholder="559127425A"
                    readonly
                    v-model="referral_code"
                  ></v-text-field>
                  <v-btn
                    class="btn regular"
                    height="40"
                    @click="copyReferral"
                    :color="isReferralCopied ? 'success' : ''"
                    >{{ isReferralCopied ? "Copid!" : "Copy" }}</v-btn
                  >
                </section>
              </section>
              <section class="referral-link">
                <label for="">Referral Link</label>
                <section class="d-flex justify-space-between">
                  <v-text-field
                    type=""
                    outlined
                    dense
                    dark
                    placeholder="559127425A"
                    readonly
                    v-model="referralLink"
                  ></v-text-field>
                  <v-btn
                    class="btn regular"
                    height="40"
                    @click="copyReferralLink"
                    :color="isReferralLinkCopied ? 'success' : ''"
                    >{{ isReferralLinkCopied ? "Copid!" : "Copy" }}</v-btn
                  >
                </section>
              </section>
              <section class="tele-2fa d-flex justify-space-between">
                <div class="d-flex flex-column">
                  <label class="">Telegram:</label>
                  <v-btn
                    class="btn"
                    @click="openTelegramDialog"
                    :loading="telegramLoading"
                  >
                    {{ user.telegram ? "Connected" : "Not Connected" }}
                    <i class="fa-brands fa-telegram" style="font-size: 22px"></i
                  ></v-btn>
                </div>
                <div class="d-flex flex-column">
                  <label class="">2Fa Authentication:</label>
                  <v-btn class="btn" @click="twofaDialog = true">
                    {{ user.google2fa ? "Enabled" : "Not Enabled" }}
                    <i
                      class="fa-solid fa-circle-exclamation"
                      style="font-size: 22px"
                    ></i>
                  </v-btn>
                </div>
              </section>
              <!-- <v-btn class="save-btn">Save</v-btn> -->

              <v-form
                @submit.prevent="change_password"
                ref="changePasswordForm"
                class="mt-6"
              >
                <h1 class="pb-2 mb-2 section-space">
                  Change Password
                </h1>

                <section class="password" v-if="user.hasPassword">
                  <label for="">Current Password</label>
                  <div class="w-100 d-flex justify-space-between">
                    <v-text-field
                      v-model.trim="currentPassword"
                      :rules="rules.password_rules"
                      outlined
                      placeholder="A54ssdw34ND"
                      dense
                      dark
                      clearable
                    ></v-text-field>
                  </div>
                </section>
                <section class="password">
                  <label for="">New Password</label>
                  <div class="w-100 d-flex justify-space-between">
                    <v-text-field
                      v-model.trim="newPassword"
                      :rules="rules.password_rules"
                      outlined
                      placeholder="A54ssdw34ND"
                      dense
                      dark
                      clearable
                      required
                    ></v-text-field>
                    <v-btn
                      class="btn regular"
                      height="40"
                      type="submit"
                      :loading="changePasswordLoading"
                      >Update</v-btn
                    >
                  </div>
                </section>
              </v-form>
            </section>
          </section>
        </v-col>
      </v-row>
    </div>

    <v-dialog v-model="twofaDialog" width="600">
      <GoogleTwoFA :active="twofaDialog" @close="twofaDialog = false" />
    </v-dialog>

    <v-dialog v-model="telegramDialog" width="400">
      <TelegramBot
        :active="telegramDialog"
        :code="telegramCode"
        @close="telegramDialog = false"
      />
    </v-dialog>

    <!-- snackbar -->
    <v-snackbar
      v-model="snackbar"
      :timeout="4000"
      color="green accent-4 "
      elevation="100"
      absolute
      centerd
      shaped
      top
      transition="slide-y-transition"
    >
      <div class="d-flex">
        {{ message }}
        <v-spacer></v-spacer>
        <v-icon @click="snackbar = false">mdi-close</v-icon>
      </div>
    </v-snackbar>
    <!-- snackbar -->
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import GoogleTwoFA from "./GoogleTwoFA.vue";
import TelegramBot from "./TelegramBot.vue";
export default {
  components: { GoogleTwoFA, TelegramBot },
  data() {
    return {
      email: "",
      currentPassword: "",
      newPassword: "",
      username: "",
      changePasswordLoading: false,
      updatePofileLoading: false,
      referral_code: null,
      emailVerified: false,
      isLoading: false,
      verifyLoading: false,
      message: "",
      snackbar: false,
      isWalletCopied: false,
      isReferralCopied: false,
      isReferralLinkCopied: false,
      twofaDialog: false,
      telegramDialog: false,
      telegramLoading: false,
      telegramCode: "",
    };
  },
  computed: {
    ...mapGetters(["wallet_address", "rules", "user"]),
    referralLink() {
      return `${process.env.VUE_APP_WEB_URL}/register?referral=${this.referral_code}`;
    },
  },
  created() {
    this.gerUserInfo();
  },
  methods: {
    ...mapActions([
      "getProfileInfo",
      "sendEmailVerify",
      "generateNewCodeForTelegram",
      "changePassword",
      "updateProfileInfo"
    ]),
    gerUserInfo() {
      this.isLoading = true;
      this.getProfileInfo().then((result) => {
        this.email = result.result.email;
        this.username = result.result.username;
        this.referral_code = "25Aese5487";
        if (result.result.email_verified_at) {
          this.emailVerified = true;
        }
        this.isLoading = false;
      });
    },
    emailVerify() {
      if (!this.emailVerified) {
        this.verifyLoading = true;
        this.sendEmailVerify().then((result) => {
          this.verifyLoading = false;
          this.snackbar = true;
          this.message = result.message;
        });
      }
    },
    copyWallet() {
      navigator.clipboard.writeText(this.wallet_address);
      this.isWalletCopied = true;
      setTimeout(() => {
        this.isWalletCopied = false;
      }, 3000);
    },
    copyReferral() {
      navigator.clipboard.writeText(this.referral_code);
      this.isReferralCopied = true;
      setTimeout(() => {
        this.isReferralCopied = false;
      }, 3000);
    },
    copyReferralLink() {
      navigator.clipboard.writeText(this.referralLink);
      this.isReferralLinkCopied = true;
      setTimeout(() => {
        this.isReferralLinkCopied = false;
      }, 3000);
    },

    openTelegramDialog() {
      if (!this.user.telegram) {
        this.generateTelegramCode();
      } else {
        this.telegramDialog = true;
      }
    },

    generateTelegramCode() {
      // Loading
      this.telegramLoading = true;

      this.generateNewCodeForTelegram().then((res) => {
        if (res) {
          this.telegramLoading = false;

          //
          this.telegramCode = res;

          this.telegramDialog = true;
        } else {
          this.telegramLoading = false;
        }
      });
    },

    async change_password() {
      if (this.$refs.changePasswordForm.validate()) {
        const hasPassword = this.user.hasPassword;
        const config = {
          newPassword: this.newPassword,
          currentPassword: this.currentPassword,
        };

        // start loading
        this.changePasswordLoading = true;

        // request
        await this.changePassword({ config, hasPassword });

        // stop loading
        this.changePasswordLoading = false;
      }
    },

    async update_profile() {
      if (this.$refs.updateProfileForm.validate()) {
        const content = {
          email: this.email,
          username: this.username
        }

        // start loading
        this.updatePofileLoading = true;

        // request
        await this.updateProfileInfo(content);

        // stop loading
        this.updatePofileLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.profile {
  color: white;
  .head {
  }
  .line {
    max-width: 700px !important;
    margin: 5px auto;
  }
  .profile-card {
    width: 90%;
    max-width: 500px;
    border-radius: 5px;
    .wallet {
      border: 2px solid var(--third-color);
      color: var(--third-color);
      padding: 9px;
      border-radius: 5px;
      font-size: 13px;
      display: flex;
      justify-content: space-between;
      .icon {
        font-size: 18px;
        cursor: pointer;
        position: relative;
        .tooltip {
          position: absolute;
          background: var(--third-color);
          color: var(--base-color);
          font-size: 12px;
          top: -45px;
          left: 0;
          transform: translateX(-50%);
          white-space: nowrap;
          padding: 8px;
          letter-spacing: 1px;
          border-radius: 2px;
          &::after {
            content: "";
            position: absolute;
            display: flex;
            left: 50%;
            bottom: -10px;
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 10px solid var(--third-color);
          }
        }
      }
    }
    .tele-2fa {
      div {
        width: 45%;
      }
      .btn {
        display: flex;
        justify-content: space-around;
      }
    }
    label {
      margin-left: 7px;
    }
    .loading {
      height: 256px;
    }
    .btn {
      background: var(--secondary-color);
      font-weight: bolder;
      color: var(--base-color);
      &.regular {
        width: 20%;
      }
      &.email-verified {
        background: var(--base-color);
        color: var(--secondary-color);
        cursor: not-allowed;
      }
    }
    .save-btn {
      width: 100%;
      font-weight: bolder;
      margin-top: 40px;
      background: var(--third-color);
    }
  }
}
::v-deep.v-text-field {
  width: 100%;
  max-width: 75%;
  input {
    &::placeholder {
      color: grey !important;
      filter: brightness(0.4);
    }
  }
  fieldset {
    border: 2px solid var(--light-blue) !important;
  }
}

.section-space {
  border-bottom: 1px solid;
}
</style>
