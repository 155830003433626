<template>
  <section class="not-installed">
    <div class="box d-flex justify-space-between py-5">
      <v-img
        src="@/assets/images/wallets/metamask.png"
        alt="Meta mask"
        max-width="50px"
      />
      <div>
        <div>Your metamask wallet is not installed!</div>
        <p><a href="https://metamask.io/download/">Click here</a> to install.</p>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.not-installed {
  .box {
    width: 90%;
    box-shadow: 0 0px 41px 0 rgb(255 255 255 / 6%);
    border-radius: 20px;
    padding: 0px 30px;
    margin: 30px auto;
    a{
      &:hover{
        text-decoration: underline;
      }
    }
  }
}
</style>
