<template>
  <div class="container routes">
    <v-col><h1 class="white--text text-center">Gift Box Opening</h1></v-col>
    <v-row>
      <v-col cols="12" class="d-flex justify-center align-center">
        <div class="box" :class="isOpen ? 'open' : ''" @click="openGiftBox">
          <div class="box-body" :class="isOpen ? 'open' : ''">
            <loading-spinner class="loading" v-if="isLoading"></loading-spinner>

            <section
              class="rewards-box d-flex justify-center"
            >
              <figure
                class="reward-item"
                :class="[{ open: isOpen }]"
                v-for="(reward, i) in rewards"
                :key="i"
              >
                <v-tooltip top v-if="reward.item">
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      class="reward"
                      v-bind="attrs"
                      v-on="on"
                      @click="showRewardsDetail"
                    >
                      <img
                        :src="reward.item.image"
                        alt=""
                        width="100%"
                        height="100%"
                        v-if="reward.item.image"
                      />
                      <img
                        :src="coinIconRender(reward.item.name)"
                        alt=""
                        width="100%"
                        height="100%"
                        v-else-if="
                          (!reward.item.image && reward.item.name === 'usdt') ||
                          reward.item.name === 'ecg'
                        "
                      />
                      <span class="name">{{ reward.item.name }}</span>
                      <span class="amount">{{ reward.amount }}</span>
                    </div>
                  </template>
                  <span>Click to Show Details</span>
                </v-tooltip>
                <div class="reward black" v-else>
                  <span class="name">{{ reward.name }}!</span>
                </div>
              </figure>
            </section>

            <div class="box-lid" :class="isOpen ? 'open' : ''">
              <div class="box-bowtie" :class="isOpen ? 'open' : ''"></div>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
    <!--detail dialog -->
    <v-dialog v-model="detailDialog" width="600">
      <RewardsDetailCard
        :rewards="rewards"
        @close="detailDialog = false"
      ></RewardsDetailCard>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import RewardsDetailCard from "./RewardsDetailCard.vue";
export default {
  data() {
    return {
      isOpen: false,
      isLoading: false,
      detailDialog: false,
      rewards: [
        // {
        //   amount: 1,
        //   chances: 11.5,
        //   description: null,
        //   image: "test.jpg",
        //   item: null,
        //   name: "Empty award",
        //   status: "used",
        // },
        // {
        //   amount: 1,
        //   chances: 38.9,
        //   description: null,
        //   image: null,
        //   item: {
        //     animate:
        //       "http://test.landsofazolite.com/api/storage/lands/REDKINDLES.jpg",
        //     category: "Silent Moor",
        //     coordinateX: null,
        //     coordinateXMap: null,
        //     coordinateY: null,
        //     coordinateYMap: null,
        //     description: null,
        //     id: 157,
        //     image:
        //       "http://test.landsofazolite.com/api/storage/lands/REDKINDLES.jpg",
        //     info: { message: "Assigned from LootboxID:13" },
        //     landscape: "Earth",
        //     mapInfoId: null,
        //     name: "REDKINDLES/Silent Moor(Field)",
        //     parentCategory: "REDKINDLES",
        //     status: "free",
        //     tags: null,
        //     type: "redkindles",
        //   },
        //   name: null,
        //   status: "used",
        // },
        // {
        //   amount: 1,
        //   chances: 38.9,
        //   description: null,
        //   image: null,
        //   item: {
        //     animate:
        //       "http://test.landsofazolite.com/api/storage/lands/REDKINDLES.jpg",
        //     category: "Silent Moor",
        //     coordinateX: null,
        //     coordinateXMap: null,
        //     coordinateY: null,
        //     coordinateYMap: null,
        //     description: null,
        //     id: 157,
        //     image:
        //       "http://test.landsofazolite.com/api/storage/lands/REDKINDLES.jpg",
        //     info: { message: "Assigned from LootboxID:13" },
        //     landscape: "Earth",
        //     mapInfoId: null,
        //     name: "REDKINDLES/Silent Moor(Field)",
        //     parentCategory: "REDKINDLES",
        //     status: "free",
        //     tags: null,
        //     type: "redkindles",
        //   },
        //   name: null,
        //   status: "used",
        // },
        // {
        //   amount: 1,
        //   chances: 38.9,
        //   description: null,
        //   image: null,
        //   item: {
        //     animate:
        //       "http://test.landsofazolite.com/api/storage/lands/REDKINDLES.jpg",
        //     category: "Silent Moor",
        //     coordinateX: null,
        //     coordinateXMap: null,
        //     coordinateY: null,
        //     coordinateYMap: null,
        //     description: null,
        //     id: 157,
        //     image:
        //       "http://test.landsofazolite.com/api/storage/lands/REDKINDLES.jpg",
        //     info: { message: "Assigned from LootboxID:13" },
        //     landscape: "Earth",
        //     mapInfoId: null,
        //     name: "REDKINDLES/Silent Moor(Field)",
        //     parentCategory: "REDKINDLES",
        //     status: "free",
        //     tags: null,
        //     type: "redkindles",
        //   },
        //   name: null,
        //   status: "used",
        // },
        // {
        //   amount: 1,
        //   chances: 38.9,
        //   description: null,
        //   image: null,
        //   item: {
        //     animate:
        //       "http://test.landsofazolite.com/api/storage/lands/REDKINDLES.jpg",
        //     category: "Silent Moor",
        //     coordinateX: null,
        //     coordinateXMap: null,
        //     coordinateY: null,
        //     coordinateYMap: null,
        //     description: null,
        //     id: 157,
        //     image:
        //       "http://test.landsofazolite.com/api/storage/lands/REDKINDLES.jpg",
        //     info: { message: "Assigned from LootboxID:13" },
        //     landscape: "Earth",
        //     mapInfoId: null,
        //     name: "REDKINDLES/Silent Moor(Field)",
        //     parentCategory: "REDKINDLES",
        //     status: "free",
        //     tags: null,
        //     type: "redkindles",
        //   },
        //   name: null,
        //   status: "used",
        // },
        // {
        //   amount: 500,
        //   chances: 36.1,
        //   description: null,
        //   image: null,
        //   item: { name: "usdt", symbol: "USDT" },
        //   name: null,
        //   status: "free",
        // },
      ],
    };
  },
  components: { RewardsDetailCard },
  methods: {
    ...mapActions(["openLootbox"]),
    openGiftBox() {
      if (this.isOpen) {
        return;
      } else {
        this.isOpen = true
          this.isLoading = true;
          const id = this.$route.params.id;
          this.openLootbox(id)
            .then((result) => {
              this.rewards = result.data.result;
              this.isOpen = true;
            })
            .catch(() => {})
            .finally(() => {
              this.isLoading = false;
            });
      }
    },
    showRewardsDetail() {
      this.detailDialog = true;
    },
    coinIconRender(name) {
      return `${process.env.VUE_APP_COIN_ICON_ADDRESS}/128/color/${name}.png`;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.box {
  position: relative;
  &.open {
    // &::before {
    //   transition: all 2.5s ease;
    //   width: 510px;
    //   height: 510px;
    //   top: 50%;
    //   left: 50%;
    //   border-radius: 3%;
    //   box-shadow: 0 0 150px rgb(11, 255, 243);
    //   border: 5px solid rgb(11, 255, 243);

    //   animation: none;
    // }
  }
}
/* background */
.box::before {
  content: "";
  width: 100px;
  height: 100px;
  position: absolute;
  z-index: 0;
  top: 45%;
  left: 35%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0px 180px 100px rgb(11, 255, 243),
    0 0px 180px 100px rgb(11, 255, 243) inset;
  animation: glowing-shadow 3s infinite linear forwards;
}
/* background */

/* box body */
.box-body {
  position: relative;
  height: 300px;
  width: 350px;
  margin-top: 123.3333333333px;
  background-color: var(--third-color);
  border-bottom-left-radius: 5%;
  border-bottom-right-radius: 5%;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.3);
  background: linear-gradient(#762c2c, var(--third-color));
  animation: box-shake 3s 3s linear infinite;
}
.box-body:hover {
  cursor: pointer;
  animation: box-shake-hover 0.5s linear infinite;
}
.box-body .loading {
  position: absolute;
  bottom: 40%;
  left: 42%;
  transform: translate(-40%, -50%);
  z-index: 1000;
  transform: scale(0.6);
}

/* box body */

/* reward */
.rewards-box {
  // width: 100%;
  // overflow: auto;
  transform: translateY(80%) scale(0);
  opacity: 0;
  transition: all 2s;
  transition-delay: 0.5s;
  cursor: pointer;
}
.box-body .reward-item .reward {
  width: 110px;
  height: 110px;
  margin: 0 2px;
  background: black;
  border-radius: 5%;
  border: 3px solid var(--secondary-color);
  // box-shadow: 0 0 5px 3px var(--secondary-color);
  overflow: hidden;
  position: relative;
  .name {
    position: absolute;
    color: white;
    font-size: 10px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-shadow: 2px 2px 5px black;
    text-align: center;
  }
  .amount {
    position: absolute;
    font-size: 10px;
    left: 50%;
    bottom: 0;
    transform: translate(-50%);
    color: white;
    text-shadow: 2px 2px 5px black;
    text-align: center;
  }
}

.box-body.open .rewards-box {
  opacity: 1;
  transform: translateY(-160%) scale(1);
}
/* reward */

/* white line box-body */
.box-body::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 50px;
  background: linear-gradient(red, rgb(68, 24, 24));
}
/* white line box */

/* box lid */
.box-lid {
  position: absolute;
  z-index: 2;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 90%;
  height: 60px;
  width: 380px;
  background-color: var(--third-color);
  border-radius: 5%;
  box-shadow: 0 8px 4px -4px rgba(0, 0, 0, 0.3);
}
/* box lid */

/* white line box-lid */
.box-lid::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 50px;
  background: linear-gradient(rgb(255, 0, 0), red);
}
/* white line box-lid */

/* bowtie پاپیون */
.box-bowtie {
  z-index: 2;
  height: 100%;
}
.box-bowtie::before,
.box-bowtie::after {
  content: "";
  width: 83.3333333333px;
  height: 83.3333333333px;
  border: 18.6666666667px solid red;
  border-radius: 50% 50% 0 50%;
  position: absolute;
  bottom: 100%;
  z-index: 0;
}
.box-bowtie::before {
  left: 50%;
  -webkit-transform: translateX(-100%) skew(10deg, 10deg);
  transform: translateX(-100%) skew(10deg, 10deg);
}
.box-bowtie::after {
  left: 50%;
  -webkit-transform: translateX(0%) rotate(90deg) skew(10deg, 10deg);
  transform: translateX(0%) rotate(90deg) skew(10deg, 10deg);
}
/* bowtie پاپیون */

/* apply animations */
.box-body.open {
  -webkit-animation: box-body 1s forwards ease-in-out;
  animation: box-body 1s forwards ease-in-out;
  cursor: unset;
}
.box-body.open .box-lid {
  -webkit-animation: box-lid 1s forwards ease-in-out;
  animation: box-lid 1s forwards ease-in-out;
}
.box-body.open .box-bowtie::before {
  -webkit-animation: box-bowtie-left 1.1s forwards ease-in-out;
  animation: box-bowtie-left 1.1s forwards ease-in-out;
}
.box-body.open .box-bowtie::after {
  -webkit-animation: box-bowtie-right 1.1s forwards ease-in-out;
  animation: box-bowtie-right 1.1s forwards ease-in-out;
}
/* apply animations */

@keyframes glowing-shadow {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes box-shake {
  0% {
    transform: rotate(0);
  }
  2% {
    transform: rotate(5deg);
  }
  4% {
    transform: rotate(0) scale(1.1);
  }
  6% {
    transform: rotate(-5deg);
  }
  8% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(0);
  }
}
@keyframes box-shake-hover {
  0% {
    transform: rotate(0);
  }
  25% {
    transform: rotate(4deg);
  }
  50% {
    transform: rotate(0deg) scale(1.1);
  }
  75% {
    transform: rotate(-4deg);
  }
  100% {
    transform: rotate(0);
  }
}
@-webkit-keyframes box-lid {
  0%,
  42% {
    -webkit-transform: translate3d(-50%, 0%, 0) rotate(0deg);
    transform: translate3d(-50%, 0%, 0) rotate(0deg);
  }
  60% {
    -webkit-transform: translate3d(-85%, -230%, 0) rotate(-25deg);
    transform: translate3d(-85%, -230%, 0) rotate(-25deg);
  }
  90%,
  100% {
    -webkit-transform: translate3d(-119%, 225%, 0) rotate(-70deg);
    transform: translate3d(-119%, 225%, 0) rotate(-70deg);
  }
}

@keyframes box-lid {
  0%,
  42% {
    -webkit-transform: translate3d(-50%, 0%, 0) rotate(0deg);
    transform: translate3d(-50%, 0%, 0) rotate(0deg);
  }
  60% {
    -webkit-transform: translate3d(-85%, -230%, 0) rotate(-25deg);
    transform: translate3d(-85%, -230%, 0) rotate(-25deg);
  }
  90%,
  100% {
    -webkit-transform: translate3d(-117%, 225%, 0) rotate(-70deg);
    transform: translate3d(-117%, 225%, 0) rotate(-70deg);
  }
}
@-webkit-keyframes box-body {
  0% {
    -webkit-transform: translate3d(0%, 0%, 0) rotate(0deg);
    transform: translate3d(0%, 0%, 0) rotate(0deg);
  }
  25% {
    -webkit-transform: translate3d(0%, 25%, 0) rotate(20deg);
    transform: translate3d(0%, 25%, 0) rotate(20deg);
  }
  50% {
    -webkit-transform: translate3d(0%, -15%, 0) rotate(0deg);
    transform: translate3d(0%, -15%, 0) rotate(0deg);
  }
  70% {
    -webkit-transform: translate3d(0%, 0%, 0) rotate(0deg);
    transform: translate3d(0%, -30%, 0) rotate(0deg);
  }
  100% {
    -webkit-transform: translate3d(0%, 0%, 0) rotate(0deg);
    transform: translate3d(0%, 0%, 0) rotate(0deg);
  }
}
@keyframes box-body {
  0% {
    -webkit-transform: translate3d(0%, 0%, 0) rotate(0deg);
    transform: translate3d(0%, 0%, 0) rotate(0deg);
  }
  25% {
    -webkit-transform: translate3d(0%, 25%, 0) rotate(20deg);
    transform: translate3d(0%, 25%, 0) rotate(20deg);
  }
  50% {
    -webkit-transform: translate3d(0%, -15%, 0) rotate(0deg);
    transform: translate3d(0%, -15%, 0) rotate(0deg);
  }
  70% {
    -webkit-transform: translate3d(0%, 0%, 0) rotate(0deg);
    transform: translate3d(0%, -20%, 0) rotate(0deg);
  }
  100% {
    -webkit-transform: translate3d(0%, 0%, 0) rotate(0deg);
    transform: translate3d(0%, 10%, 0) rotate(0deg);
  }
}
@-webkit-keyframes box-bowtie-right {
  0%,
  50%,
  75% {
    -webkit-transform: translateX(0%) rotate(90deg) skew(10deg, 10deg);
    transform: translateX(0%) rotate(90deg) skew(10deg, 10deg);
  }
  90%,
  100% {
    -webkit-transform: translate(-50%, -15%) rotate(45deg) skew(10deg, 10deg);
    transform: translate(-50%, -15%) rotate(45deg) skew(10deg, 10deg);
    box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.3);
  }
}
@keyframes box-bowtie-right {
  0%,
  50%,
  75% {
    -webkit-transform: translateX(0%) rotate(90deg) skew(10deg, 10deg);
    transform: translateX(0%) rotate(90deg) skew(10deg, 10deg);
  }
  90%,
  100% {
    -webkit-transform: translate(-50%, -15%) rotate(45deg) skew(10deg, 10deg);
    transform: translate(-50%, -15%) rotate(45deg) skew(10deg, 10deg);
    box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.3);
  }
}
@-webkit-keyframes box-bowtie-left {
  0% {
    -webkit-transform: translateX(-100%) rotate(0deg) skew(10deg, 10deg);
    transform: translateX(-100%) rotate(0deg) skew(10deg, 10deg);
  }
  50%,
  75% {
    -webkit-transform: translate(-50%, -15%) rotate(45deg) skew(10deg, 10deg);
    transform: translate(-50%, -15%) rotate(45deg) skew(10deg, 10deg);
  }
  90%,
  100% {
    -webkit-transform: translateX(-100%) rotate(0deg) skew(10deg, 10deg);
    transform: translateX(-100%) rotate(0deg) skew(10deg, 10deg);
  }
}
@keyframes box-bowtie-left {
  0% {
    -webkit-transform: translateX(-100%) rotate(0deg) skew(10deg, 10deg);
    transform: translateX(-100%) rotate(0deg) skew(10deg, 10deg);
  }
  50%,
  75% {
    -webkit-transform: translate(-50%, -15%) rotate(45deg) skew(10deg, 10deg);
    transform: translate(-50%, -15%) rotate(45deg) skew(10deg, 10deg);
  }
  90%,
  100% {
    -webkit-transform: translateX(-100%) rotate(0deg) skew(10deg, 10deg);
    transform: translateX(-100%) rotate(0deg) skew(10deg, 10deg);
  }
}
</style>
