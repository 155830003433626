<template>
  <div class="payment routes">
    <v-container>
      <h2 class="text-center white--text">Payment</h2>
      <div class="line"></div>
      <v-col>
        <div>
          <v-row>
            <ChargeWallet />
            <Withdraw />
          </v-row>
        </div>
      </v-col>

      <v-col>
        <div class="payments">
          <div class="tabs mb-3">
            <ul class="d-flex pa-0">
              <li
                v-for="(item, i) in tabs"
                :key="i"
                @click="changeTabs(item)"
                :class="item.isActive ? 'active' : ''"
              >
                <span>{{ item.name }}</span>
              </li>
            </ul>
          </div>
          <PaymentList v-if="tabs[0].isActive" />
          <WithdrawHistory v-if="tabs[1].isActive" />
          <DepositHistory v-if="tabs[2].isActive" />
        </div>
      </v-col>
    </v-container>
  </div>
</template>

<script>
import ChargeWallet from "../../components/payment/ChargeWallet.vue";
import PaymentList from "../../components/payment/PaymentList.vue";
import WithdrawHistory from "../../components/payment/WithdrawHistory.vue";
import DepositHistory from "../../components/payment/DepositHistory.vue";
import Withdraw from "../../components/payment/Withdraw.vue";
export default {
  data() {
    return {
      tabs: [
        {
          name: "Payments",
          isActive: true,
        },
        {
          name: "Withdraw History",
          isActive: false,
        },
        {
          name: "Deposit History",
          isActive: false,
        },
      ],
      previousTab: {},
    };
  },
  components: {
    PaymentList,
    ChargeWallet,
    WithdrawHistory,
    DepositHistory,
    Withdraw,
  },
  created() {
    //get session tab name and find it and change tab to that\
    if (sessionStorage.getItem("payments-tab")) {
      this.previousTab = this.tabs.find(
        (element) => element.name === sessionStorage.getItem("payments-tab")
      );
      this.changeTabs(this.previousTab);
    }
  },
  methods: {
    changeTabs(item) {
      //change tab from old to new and save in session
      let oldTab = this.tabs.find((element) => element.isActive === true);
      oldTab.isActive = !oldTab.isActive;
      let newTab = this.tabs.find((element) => element.name === item.name);
      newTab.isActive = !newTab.isActive;
      this.handleSaveTab();
    },
    handleSaveTab() {
      //save active tab(object) name to session
      this.previousTab = this.tabs.find((element) => element.isActive === true);
      sessionStorage.setItem("payments-tab", this.previousTab.name);
    },
  },
};
</script>

<style lang="scss">
@import "./payment.scss";
.payments {
  background: #212121;
  margin: 0 auto;
  border-radius: 5px;
  // tabs
  .tabs {
    display: flex;
    justify-content: center;
    ul {
      width: 100%;
      // max-width: 500px;
      li {
        width: 33.33%;
        &:hover {
        }
        &.active {
        }
      }
    }
  }
  // tabs
}
</style>
