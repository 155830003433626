<template>
  <v-col class="charge-account">
    <v-card outlined dark>
      <v-card-title class="grey darken-4 py-3">
        <div class="d-flex align-center justify-space-between w-100">
          <h4>Charge Account</h4>

          <v-skeleton-loader
            v-if="!coins.length"
            type=" divider,divider,divider,divider,divider,divider"
            width="20%"
          ></v-skeleton-loader>

          <ul v-else>
            <li class="grey--text" v-for="(coin, i) in coins" :key="i">
              <div v-if="coin.amount && !+coin.salable" class="balance-item">
                <span class="mr-1"> {{ coin.symbol }} : </span>
                <span class="white--text">{{ coin.amount }}</span>
                <img class="ml-1" :src="coin.image" width="15px" />
              </div>
            </li>
          </ul>
        </div>
      </v-card-title>

      <v-card-text>
        <p class="mb-4">
          You need to charge your wallet to be able to buy payable features. All
          payments will use your wallet balance to pay.
        </p>
        <p class="m-0">
          For now crypto currency payment is available and other payment methods
          will shown here if available.
        </p>

        <v-divider class="my-3" />

        <v-form ref="payment">
          <div class="form-group">
            <v-row>
              <v-col cols="12" sm="6" class=""
                ><label>Amount</label>
                <v-text-field
                  type="email"
                  :rules="rules.amount_rules"
                  v-model="amount"
                  step="0.000001"
                  min="0.000001"
                  outlined
                  dense
                  placeholder="0.00"
                ></v-text-field
              ></v-col>
              <v-col cols="12" sm="6" class="my-auto">
                <label class="">Coin</label>
                <v-select
                  v-model="selectedCoin"
                  :items="coins"
                  :rules="rules.select"
                  item-text="name"
                  item-value="name"
                  label="Coins List"
                  :menu-props="{ bottom: true, offsetY: true }"
                  solo
                  dense
                  clearable
                >
                  <template v-slot:selection="{ item }">
                    <img
                      :src="coinIconRender(item.name)"
                      class="me-1 rounded"
                    />
                    {{ item.name }}
                  </template>
                  <template v-slot:item="{ item }">
                    <img
                      :src="coinIconRender(item.name)"
                      class="me-1 rounded"
                    />{{ item.name }}
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </div>
          <div class="d-flex">
            <v-btn
              class="charge-btn"
              :loading="loading"
              :disabled="loading"
              @click="submitPayment"
            >
              Charge
            </v-btn>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      amount: "",
      loading: false,
      valid: false,
      coins: [],
      selectedCoin: "",
      coin1balance: 0,
      coin2balance: 0,
    };
  },
  computed: {
    ...mapGetters(["rules", "user"]),
  },
  created() {
    this.fetchUserCoins();
  },
  methods: {
    ...mapActions(["createPayment", "getUserCoins"]),
    coinIconRender(name) {
      return `${process.env.VUE_APP_COIN_ICON_ADDRESS}/32/color/${name}.png`;
    },
    fetchUserCoins() {
      this.coins = [];
      this.getUserCoins().then((res) => {
        const allCoins = res.result;
        allCoins.forEach((element) => {
          if (!+element.salable) {
            this.coins.push(element);
          }
        });
      });
    },
    submitPayment() {
      if (this.$refs.payment.validate()) {
        let info = {
          amount: this.amount,
          coin: this.selectedCoin,
        };
        this.createPayment(info).then((res) => {
          location.replace(res.result.url);
          this.$refs.payment.reset();
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.charge-account {
  margin: 12px 0 0 0;
  .v-select {
    ::v-deep .v-input__slot {
      background: var(--base-color);
    }
    ::v-deep .v-input__control {
      min-height: none;
    }
  }
  ::v-deep.v-text-field {
    width: 100% !important;
  }
  ul {
    li {
      list-style: none;
      .balance-item {
        width: 100%;
        font-size: 12px;
        line-height: 14px;
        font-weight: 900;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 3px;
      }
    }
  }
  .charge-btn {
    background: var(--third-color);
    color: black;
    font-weight: bolder;
    width: 100%;
    margin-left: auto;
  }
}
</style>
