<template>
  <section class="lands">
    <section class="d-flex">
      <!-- filters -->
      <section class="filters">
        <LandFilters @submitFilters="submitFilters" />
      </section>
      <!-- filters -->

      <section class="main-shop py-3">
        <section class="">
          <MobileFilters
            @submitFilters="submitFilters"
            :total="total"
            type="land"
          ></MobileFilters>
          <SortBox
            @toggleDrawer="drawer = !drawer"
            @makeSort="makeSort"
            @resetSort="resetSort"
            :total="total"
          />
        </section>

        <section v-if="isLoading">
          <div class="no-item">
            <loading-spinner></loading-spinner>
          </div>
        </section>
        <div class="no-item" v-else-if="!isLoading && lands.length <= 0">
          <h4 class="white--text">There is no item !</h4>
        </div>

        <v-row class="row" no-gutters v-else>
          <LandItem
            v-for="(item, i) in lands"
            :key="i"
            :item="item"
            @buyItem="buyItem"
            @showDetails="showDetails"
          />
        </v-row>
      </section>
    </section>

    <!-- pagination -->
    <div class="line"></div>
    <input-pagination
      :total="total"
      :page="page"
      :lastPage="lastPage"
      @passedPage="inputChangePage"
    ></input-pagination>

    <!--detail dialog -->
    <v-dialog v-model="detailDialog" width="600">
      <DetailCard
        @close="detailDialog = false"
        :selectedItem="selectedLand"
        :isLoading="detailLoading"
        type="land"
        @buy="buyItem"
      ></DetailCard>
    </v-dialog>

    <!--quastion dialog -->
    <v-dialog v-model="quastionDialog" width="500">
      <QuastionCard
        @cancel="quastionDialog = false"
        text="Are you sure to buy this item ? "
        :isLoading="buyLoading"
        @accept="accept"
      ></QuastionCard>
    </v-dialog>
  </section>
</template>

<script>
import LandItem from "./LandItem.vue";
import LandFilters from "./LandFilters.vue";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      lands: [],
      drawer: false,
      detailDialog: false,
      detailLoading: false,
      isLoading: false,
      selectedLand: {},

      // filter
      selectedSubCategory: 0,
      selectedSpecifications: [],
      // widthRange: [0, 0],
      // heightRange: [0, 0],
      name: null,
      id: null,
      // location
      cordinateXSelected: 0,
      cordinateYSelected: 0,
      selectedRadius: 0,
      priceRange: [0, 23300],

      //paginate
      page: 1,
      lastPage: 0,
      total: 0,

      // sort
      selectedSort: null,
      selectedOrder: null,

      //buy
      buyLoading: false,
      quastionDialog: false,
      selectedItemInfo: null,
    };
  },
  components: {
    LandItem,
    LandFilters,
  },

  created() {
    const searched_id = this.$route.query.id;
    if (searched_id) {
      this.id = searched_id;
    }
    this.fetchLandLists();
  },
  methods: {
    ...mapActions(["getItemDetails", "getMarketData", "buy"]),

    fetchLandLists() {
      let params = {
        page: this.page,
        limit: 8,
        type: "land",
        category_id: this.selectedSubCategory,
        search_id: this.id,
        search_name: this.name,
        coordinateX: this.cordinateXSelected,
        coordinateY: this.cordinateYSelected,
        radius: this.selectedRadius,
        from_price: this.priceRange[0],
        to_price: this.priceRange[1],
        tags: this.selectedSpecifications,
      };
      if (this.selectedSort != null) {
        params.sort = this.selectedSort;
        params.order = this.selectedOrder;
      }

      this.isLoading = true;
      this.getMarketData(params)
        .then((result) => {
          this.lands = result.result.data;
          this.total = result.result.total;
          this.lastPage = result.result.last_page;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    submitFilters(submitedFilters) {
      this.selectedSubCategory = submitedFilters.selectedSubCategory;
      this.name = submitedFilters.name;
      this.id = submitedFilters.id;
      this.cordinateXSelected = submitedFilters.cordinateXSelected;
      this.cordinateYSelected = submitedFilters.cordinateYSelected;
      this.selectedRadius = submitedFilters.selectedRadius;
      this.priceRange = submitedFilters.priceRange;
      this.selectedSpecifications = submitedFilters.selectedSpecifications;
      this.fetchLandLists();
    },

    makeSort(order, sort) {
      this.selectedSort = sort;
      this.selectedOrder = order;
      this.fetchLandLists();
    },
    resetSort() {
      this.selectedSort = null;
      this.selectedOrder = null;
      this.fetchLandLists();
    },
    buyItem(info) {
      this.selectedItemInfo = info;
      this.quastionDialog = true;
    },
    accept() {
      this.buyLoading = true;

      const id = this.selectedItemInfo.id;
      let params = new URLSearchParams();
      if (this.selectedItemInfo.auto) {
        params.set("type", "auto");
      }
      this.buy({ id, params })
        .then((result) => {
          this.fetchLandLists();
        })
        .finally(() => {
          this.buyLoading = false;
          this.quastionDialog = false;
          this.detailDialog = false;
        });
    },

    showDetails(item) {
      this.detailDialog = true;
      this.detailLoading = true;
      this.getItemDetails({ itemsName: "lands", id: item.item_id }).then(
        (result) => {
          this.selectedLand = result.data.result;
          this.detailLoading = false;
        }
      );
    },
    inputChangePage(val) {
      this.page = val;
      this.fetchLandLists();
    },
  },
};
</script>

<style lang="scss" scoped>
.lands {
  .filters {
    // margin-top: -70px;
    border-right: 2px solid #6ee6ff;
    // overflow: hidden;
    max-width: 18%;
    @media only screen and(max-width: 980px) {
      display: none;
    }
  }
  .main-shop {
    // position: relative;
    width: 100%;
    min-width: 82%;
  }
}
</style>
