import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/landing/Home.vue";
import GiftOpening from "../views/giftOpening/GiftOpening.vue";
import authCheck from "../utils/authCheck.js";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/authentication/Login.vue"),
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../views/authentication/Register.vue"),
  },
  {
    path: "/gift-opening/:id",
    name: "gift-opening",
    component: GiftOpening,
  },
  {
    path: "/shop",
    name: "shop",
    component: () => import("../views/shop"),
  },
  {
    path: "/map",
    name: "map",
    component: () => import("../views/map"),
  },
  {
    path: "/game",
    name: "game",
    component: () => import("../views/game"),
  },
  {
    path: "/play-azollite",
    name: "play-azollite",
    component: () => import("../views/game/PlayGame.vue"),
  },
  // {
  //     path: "/contact",
  //     name: "contact",
  //     component: () =>
  //         import ( /* webpackChunkName: "contact-us" */ "../views/Contact.vue"),
  // },
  {
    path: "/market-history",
    name: "marketHistory",
    beforeEnter: authCheck,
    component: () => import("../views/marketHistory/MarketHistory.vue"),
  },
  {
    path: "/payment",
    name: "payment",
    beforeEnter: authCheck,
    component: () => import("../views/payment"),
  },
  {
    path: "/payments/redirect",
    name: "paymentCallback",
    component: () => import("../views/payment/PaymentCallback.vue"),
  },
  {
    path: "/profile",
    name: "profile",
    beforeEnter: authCheck,
    component: () => import("../views/profile/Profile.vue"),
  },
  {
    path: "/verify-email",
    name: "verify-email",

    component: () => import("../views/profile/EamilVerifyCallback.vue"),
  },
  {
    path: "/assets",
    name: "assets",
    beforeEnter: authCheck,
    component: () => import("../views/assets/Assets.vue"),
  },
  {
    path: "/thirdparty-login",
    name: "thirdparty-login",
    component: () =>
      import("../views/authentication/thirdparty/ThirdpartyLogin.vue"),
  },
  {
    path: "/reset-password",
    name: "reset-password",
    component: () =>
      import("../views/authentication/resetPassword/ResetPassword.vue"),
  },
  {
    path: "/reset-password/redirect",
    name: "reset-password-redirect",
    component: () =>
      import("../views/authentication/resetPassword/ResetPasswordCallback.vue"),
  },
  {
    path: "/privacyPolicy",
    name: "privacy-policy",
    component: () => import("../views/others/PrivacyPolicy.vue"),
  },
  {
    path: "/termsAndConditions",
    name: "terms-and-conditions",
    component: () => import("../views/others/TermsAndConditions.vue"),
  },
  {
    path: "*",
    name: "not-found",
    component: () => import("../NotFound.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
