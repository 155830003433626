<template>
  <section
    class="navbar d-flex justify-space-around justify-sm-space-between align-center px-sm-10"
    :class="`${drawer ? 'drawered' : ''}`"
  >
    <drawer-toggle-button
      class="d-md-none"
      @click="toggleDrawer"
      :drawer="drawer"
    ></drawer-toggle-button>
    <router-link to="/" class="nav-item">
      <v-img
        class="logo"
        src="@/assets/images/Logo_Azollite.png"
        max-width="150px"
      ></v-img>
    </router-link>
    <div class="nav-items d-none d-md-flex">
      <router-link :to="{ name: 'shop' }" class="nav-item"
        ><a href="">Marketplace</a></router-link
      >
      <router-link :to="{ name: 'map' }" class="nav-item"><a href="">Map</a></router-link>
      <router-link :to="{ name: 'game' }" class="nav-item"
        ><a href="">Game</a></router-link
      >
      <a
        href="https://telegra.ph/LandsofAzollite-07-03"
        target="_blank"
        class="nav-item white--text"
        >White paper</a
      >
      <!-- <router-link to="/journal" class="nav-item"
          ><a href="">Journal</a></router-link
        > -->
      <a href="/#contact" class="nav-item white--text">Contact</a>
    </div>
    <div class="nav-icons">
      <div class="d-none d-md-block">
        <a
          href="https://www.instagram.com/ecosmartecs/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <v-icon class="social-icon">mdi-instagram</v-icon>
        </a>
        <a href="https://t.me/EcoSmartECS" target="_blank" rel="noopener noreferrer">
          <v-icon class="social-icon">mdi-send</v-icon>
        </a>
        <a
          href="https://twitter.com/EcoSmartECS"
          target="_blank"
          rel="noopener noreferrer"
        >
          <v-icon class="social-icon">mdi-twitter</v-icon>
        </a>
        <a
          href="https://www.youtube.com/channel/UCZyavwOXisC7aVVYX8FbnPQ/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <v-icon class="social-icon">mdi-youtube</v-icon>
        </a>
        <a href="https://discord.gg/WJKCk6c" target="_blank" rel="noopener noreferrer">
          <v-icon class="social-icon">mdi-robot</v-icon>
        </a>
      </div>

      <div v-if="$route.name !== 'login' && $route.name !== 'register'">
        <v-btn class="login-btn" v-if="!token" @click="$router.push({ name: 'login' })">
          Login
          <v-icon small right color="black">mdi-login</v-icon>
        </v-btn>
        <v-menu
          v-else
          dark
          transition="slide-y-transition"
          offset-x
          content-class="user-menu"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn dark v-bind="attrs" v-on="on" class="menu-btn">
              <v-icon> mdi-account </v-icon>
              <v-icon
                class="wallet-connected-flag"
                color="success"
                x-small
                v-if="!!connectedTo"
                >mdi-wallet</v-icon
              >
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="$router.push({ name: 'profile' })">
              <v-list-item-title class="d-flex align-center">
                <span> Profile </span>
                <v-spacer></v-spacer>
                <v-icon>mdi-account</v-icon>
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="$router.push({ name: 'assets' })">
              <v-list-item-title class="d-flex align-center">
                <span> Assets </span>
                <v-spacer></v-spacer>
                <v-icon>mdi-shopping</v-icon></v-list-item-title
              >
            </v-list-item>
            <v-list-item @click="$router.push({ name: 'payment' })">
              <v-list-item-title class="d-flex align-center"
                ><span> Payments </span>
                <v-spacer></v-spacer>
                <i class="fa-solid fa-credit-card" style="font-size: 18px"></i
              ></v-list-item-title>
            </v-list-item>
            <v-list-item @click="$router.push({ name: 'marketHistory' })">
              <v-list-item-title class="d-flex align-center"
                ><span> Market history </span>
                <v-spacer></v-spacer>
                <v-icon>mdi-history</v-icon>
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="userLogout">
              <v-list-item-title class="d-flex align-center"
                ><span> Logout </span>
                <v-spacer></v-spacer>
                <i class="fa-solid fa-right-from-bracket" style="font-size: 18px"></i
              ></v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
    <nav-drawer-dialog
      v-if="drawer"
      class="d-md-none"
      @click="handleClick"
    ></nav-drawer-dialog>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DrawerToggleButton from "./DrawerToggleButton.vue";
import NavDrawerDialog from "./NavDrawerDialog.vue";
// import WalletModal from "./WalletModal.vue";
export default {
  data() {
    return {
      drawer: false,
    };
  },
  components: {
    DrawerToggleButton,
    NavDrawerDialog,
    // WalletModal,
  },
  computed: {
    ...mapGetters(["token", "user", "connectedTo"]),
  },
  mounted() {
    this.navbarAnimation();
  },
  created() {
    if (this.token) {
      this.getProfileInfo();
    }
  },
  methods: {
    ...mapActions(["logout", "getProfileInfo"]),
    navbarAnimation() {
      const observeObject = document.querySelector(".observer-object");
      const navbar = document.querySelector(".navbar");
      let navObserver = new IntersectionObserver(
        (enteries) => {
          enteries.forEach((entery) => {
            if (entery.isIntersecting) {
              //if object is exsist in view port and threshold is 0
              navbar.classList.remove("watched");
            } else {
              navbar.classList.add("watched");
            }
          });
        },
        { threshold: 0 }
      );
      navObserver.observe(observeObject);
    },
    toggleDrawer() {
      this.drawer = !this.drawer;
    },
    handleClick(routeName) {
      if (routeName) {
        if (this.$route.name === routeName) {
          return;
        }
        this.$router.push({ name: routeName });
      }
      this.drawer = false;
    },
    userLogout() {
      this.logout();
      if (this.$route.name !== "Home") this.$router.push({ name: "Home" }).catch();
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  height: 90px;
  background-color: transparent;
  position: fixed !important;
  top: -2px;
  z-index: 120;
  width: 100%;
  transition: all 0.4s ease;
  &.drawered {
    transition: unset;
    background-color: var(--background-color);
  }
  &.watched {
    transition: all 0.4s ease;
    background-color: var(--background-color);
    height: 60px;
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%),
      0px 1px 10px 0px rgb(0 0 0 / 12%);
  }

  .nav-items {
    .nav-item {
      position: relative;
      margin-right: 20px;
      color: var(--text-color) !important;
      a {
        color: var(--text-color);
        font-size: 16px;
      }
      &:hover {
        &::after {
          transform: scaleX(1);
          transform-origin: left bottom;
        }
      }
      &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 3px;
        height: 2px;
        width: 100%;
        background: white;
        transform: scaleX(0);
        transition: all 0.3s linear;
      }
      &:active,
      &.router-link-active {
        text-decoration: underline;
        text-shadow: 0 0 15px var(--secondary-color);
        &::after {
          display: none;
        }
      }
    }
  }
  .logo {
    transition: all 1s ease;
    @media only screen and(max-width : 500px) {
      max-width: 100px !important;
    }
  }
  .nav-icons {
    display: flex;
    align-items: center;
    .login-btn {
      background: var(--base-color) !important;
      transition: all 0.3s ease;
      color: white;
      font-size: 12px;
      margin-left: 15px;
      @media only screen and(max-width : 500px) {
        // transform: scaleX(0.6) scaleY(.8);
        width: 80px !important;
        height: 28px;
        margin: 0;
      }
      &:hover {
        background: var(--secondary-color) !important;
        box-shadow: 0 0 10px 2px var(--secondary-color);
        color: black !important;
      }
      i {
        color: white !important;
      }
    }
    .social-icon {
      color: var(--text-color);
      font-size: 19px;
      margin-right: 10px;
      transition: all 0.5s ease;
      &:hover {
        color: var(--light-blue);
        transform: scale(1.2) rotate(360deg);
      }
    }
  }
}
.user-menu {
  .v-list {
    width: 150px;
    .v-list-item {
      min-height: 40px;
      cursor: pointer;
      &:hover {
        background: var(--light-blue);
        color: black;
      }
      .v-list-item__title {
        font-size: 12px;
      }
    }
  }
}
.menu-btn {
  position: relative;
  .wallet-connected-flag {
    position: absolute;
    right: 0;
    bottom: 0;
  }
  @media only screen and(max-width : 500px) {
    transform: scale(0.8);
  }
}
</style>
