<template>
  <section class="market-history routes">
    <v-data-table
      :headers="headers"
      :items="saleHistoryList"
      :loading="isLoading"
      :items-per-page="15"
      hide-default-footer
      class="table elevation-1 mt-3 white--text"
      dark
    >
      <template v-slot:top>
        <section class="head d-flex justify-center pa-3 white--text">
          <h3>Market History</h3>
        </section>
      </template>
      <template v-slot:item.price="{ item }">
        <div class="d-flex justify-center align-center">
          <p v-for="(value, name, i) in item.price" :key="i">
            <section v-if="value.price" class="d-flex align-center">
              <!-- <span> {{ name }} : </span> -->
              <span>{{ value.price }}</span>
              <img :src="coinIconRender(name)" width="15px" class="mx-1" />
              <span v-if="i > 1"> , </span>
            </section>
          </p>
        </div>
      </template>
      <template v-slot:item.price_description="{ item }">
        <p v-html="item.price_description" v-if="item.price_description"></p>
        <span v-else>-</span>
      </template>
      <template v-slot:item.activity="{ item }">
        {{ item.action === 'Sell' ? item.status === 'pending' ? 'Listed for sale' : 'Sold' : 'Bought'}}
      </template>
      <template v-slot:item.updated_at="{ item }">
        {{ formatDate(item.created_at) }}
      </template>
    </v-data-table>

    <div class="table-paginate-box">
      <div class="table-pagination-total">
        {{ total }} <span class="">result(s)</span>
      </div>
      <div class="paginate-box">
        <v-pagination
          v-model="page"
          class="pagination"
          :length="lastPage"
          :total-visible="6"
          color="blue"
          circle
           @input="fetchMarketHistory()"
        ></v-pagination>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      isLoading: false,
      page: 1,
      lastPage: null,
      total: 0,

      saleHistoryList: [],

      headers: [
        {
          text: "ID",
          align: "start",
          sortable: false,
          value: "id",
          class: "white--text",
        },
        { text: "Type", value: "type", class: "white--text",sortable: false, },
        { text: "Name", value: "item.name", class: "white--text",sortable: false, },
        { text: "Price", value: "price", class: "white--text", align: "center",sortable: false, },
        {
          text: "OFF",
          value: "price_description",
          class: "white--text",
          align: "center",
          sortable: false,
        },
        // { text: "Action", value: "action", class: "white--text",sortable: false, },
        // { text: "Status", value: "status", class: "white--text",sortable: false, },
        { text: "Activity", value: "activity", class: "white--text" ,sortable: false,},
        { text: "Last update", value: "updated_at", class: "white--text",sortable: false, },
      ],
    };
  },
  computed: {},
  created() {
    this.fetchMarketHistory();
  },
  methods: {
    ...mapActions(["getMarketHistory"]),
    fetchMarketHistory() {
      let params = new URLSearchParams();
      params.append("page", this.page);
      params.append("per_page", 8);
      this.isLoading = true;
      this.getMarketHistory(params)
        .then((result) => {
          
          this.saleHistoryList = result.data;
          this.lastPage = result.meta.last_page;
          this.total = result.meta.total;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    coinIconRender(name) {
      return `${process.env.VUE_APP_COIN_ICON_ADDRESS}/32/color/${name}.png`;
    },
    formatDate(date) {
      if (date) {
        let newDate = new Date(date);
        return newDate.toLocaleString();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.market-history {
  .table,
  .table-paginate-box {
    width: 95%;
    max-width: 1400px;
    margin: 0 auto;
  }
}
</style>
