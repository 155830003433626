<template>
  <v-col cols="6" sm="6" md="4" lg="3" class="col mt-6 mt-sm-10">
    <v-skeleton-loader
      v-if="!bgIsLoaded"
      type="image"
      class="skeleton"
      min-height="220"
    ></v-skeleton-loader>
    <section class="item-card" v-show="bgIsLoaded" @click="showDetails(item)">
      <img
        src="../../../assets/images/cards/land-card-bg.png"
        class="item-card-background"
        @load="mainBgIsLoaded"
      />
      <v-img
        :src="storageImageURL(item.decorative.image)"
        contain
        class="item-card-image"
        @load="imgIsLoaded"
      >
        <v-skeleton-loader
          v-if="!imageIsLoaded"
          width="100%"
          height="100%"
          type="image"
          class="skeleton"
        ></v-skeleton-loader>
      </v-img>
      <div v-if="bgIsLoaded">
        <div class="item-card-name-container">
          <span>{{ item.decorative.name }}</span>
        </div>
        <h5 class="item-card-status">
          <span class="">Level {{ item.decorative.level }}</span>
        </h5>
        <h5 class="item-card-level">
          <span>{{ item.decorative.type }} </span>
        </h5>
        <h5 class="item-card-id">
          <span>Id: {{ item.id }}</span>
        </h5>
        <!-- <ul class="item-card-price pa-0">
          <li v-for="(value, name, i) in item.price" :key="i">
            <span>{{ value.price }} {{ name }} </span>
          </li>
        </ul>
        <h5 class="item-card-price-description">
          <span v-html="item.price_description"></span>
        </h5> -->
      </div>

      <button
        class="item-card-shop-button"
        style=""
        @click.stop="transferNft(item)"
      >
        <span>Transfer NFT</span>
      </button>
      <button class="item-card-sell-button" @click.stop="sell(item)">
        <span>Sell</span>
      </button>
    </section>
    <!--detail dialog -->
    <v-dialog v-model="detailDialog" width="650">
      <DetailCard
        @close="detailDialog = false"
        @sell="sell"
        :selectedItem="selectedDecorative"
        :isLoading="loading"
        type="decorative"
      ></DetailCard>
    </v-dialog>

    <!--transfar dialog -->
    <v-dialog v-model="transfarDialog" width="600">
      <TransferNftCard
        :selectedItem="selectedDecorative"
        type="decorative"
        @close="transfarDialog = false"
        @fetchAgain="fetchAgain"
      ></TransferNftCard>
    </v-dialog>

    <!--sell dialog -->
    <v-dialog v-model="sellDialog" width="500">
      <SellDialogCard
        :selectedItem="selectedDecorative"
        type="decorative"
        @close="sellDialog = false"
        @sellSuccess="sellSuccess"
      ></SellDialogCard>
    </v-dialog>
  </v-col>
</template>

<script>
import SellDialogCard from "../sellDialogCard/SellDialogCard.vue";
import TransferNftCard from "../transferNftCard/TransferNftCard.vue";
import { mapActions, mapGetters } from "vuex";
import { storageImage } from "@/mixins/storageImage.js";
export default {
  mixins: [storageImage],
  props: ["item"],
  data() {
    return {
      sellDialog: false,
      transfarDialog: false,
      detailDialog: false,

      loading: false,
      selectedDecorative: {},
      transferInfo: {},

      bgIsLoaded: false,
      imageIsLoaded: false,
    };
  },
  computed: {
    ...mapGetters(["wallet_address", "rules"]),
  },
  components: {
    SellDialogCard,
    TransferNftCard,
  },
  methods: {
    ...mapActions(["getItemDetails", "transferNftToWallet"]),
    showDetails(item) {
      this.detailDialog = true;
      this.loading = true;
      this.getItemDetails({ itemsName: "decoratives", id: item.id }).then(
        (result) => {
          this.selectedDecorative = result.data.result;
          this.loading = false;
        }
      );
    },
    transferNft(item) {
      this.transfarDialog = true;
      this.selectedDecorative = item;
    },
    fetchAgain() {
      this.$emit("fetchUserDecoratives");
    },

    sell(item) {
      this.selectedDecorative = item;
      this.sellDialog = true;
      if (this.$refs.sellForm) {
        this.$refs.sellForm.reset();
      }
    },
    sellSuccess() {
      this.$emit("fetchUserDecoratives");
      this.sellDialog = false;
    },
    mainBgIsLoaded() {
      this.bgIsLoaded = true;
    },
    imgIsLoaded() {
      this.imageIsLoaded = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.col {
  transition: all 0.3s ease;
  @media only screen and(max-width: 500px) {
    font-size: 9px;
  }
}
</style>
