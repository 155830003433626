<template>
  <section class="play-game">
    <div class="iframe-wrapper routes">
      <div class="iframe-container">
        <iframe
          src="https://minigames.ecogamecenter.net/games/10"
          class="iframe"
          width="100%"
          height="100%"
          scrolling="no"
          ref="iframeWrapper"
        ></iframe>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.iframe-wrapper {
  position: relative;
  @media only screen and (orientation: portrait) {
    width: 100vh;
    height: 100vw;
    transform: translateX(100vw) rotate(90deg);
    transform-origin: top left;
  }
  @media only screen and (orientation: landscape) {
    height: 100vh;
  }
  .iframe-container {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    .iframe {
    }
  }
}
</style>
