import { render, staticRenderFns } from "./Buildings.vue?vue&type=template&id=5ff77ad3&scoped=true&"
import script from "./Buildings.vue?vue&type=script&lang=js&"
export * from "./Buildings.vue?vue&type=script&lang=js&"
import style0 from "./Buildings.vue?vue&type=style&index=0&id=5ff77ad3&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ff77ad3",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VDialog,VRow})
