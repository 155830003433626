import Vue from "vue";
import Vuex from "vuex";
import mainApi from "../plugins/axios/axios.js";

import tronBot from "./modules/tronBot";
import metaMask from "./modules/metaMask";
import rules from "./modules/rules";
import auth from "./modules/auth";
import shop from "./modules/shop";
import payments from "./modules/payments";
import profile from "./modules/profile";
import assets from "./modules/assets";
import alert from "./modules/alert";
import map from "./modules/map";
import { setTimeout } from "core-js";

import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    walletModal: false,
    newPage: "",
    configs: {},
  },
  getters: {
    walletModal(state) {
      return state.walletModal;
    },
    newPage(state) {
      return state.newPage;
    },
    configs: (state) => state.configs,
  },
  mutations: {
    walletModal(state, payload) {
      state.walletModal = payload;
    },
    changePage(state, payload) {
      state.newPage = payload;
    },
    setConfigs(state, payload) {
      state.configs = payload;
    },
  },
  actions: {
    async sendContactForm(_, payload) {
      try {
        await mainApi.post("landing/contact_us", payload);

        //
        return true;
      } catch (error) {
        return false;
      }
    },

    async getConfigs({ commit }) {
      try {
        const { data } = await mainApi.get("/configs");

        commit("setConfigs", data.result);

        return true;
      } catch (error) {
        return false;
      }
    },

    openWalletModal({ commit }) {
      commit("walletModal", true);
      setTimeout(() => {
        commit("walletModal", false);
      }, 500);
    },
    changePage({ commit }, payload) {
      commit("changePage", payload);

      setTimeout(() => {
        commit("changePage", "");
      }, 100);
    },

    async getNewsInfo() {
      const { origin } = window.location;

      return new Promise((resolve, reject) => {
        axios
          .get(`${origin}/news-api/news/`)
          .then((result) => {
            resolve(result.data.result);
          })
          .catch((err) => {
            reject();
          });
      });
    },
  },
  modules: {
    tronBot,
    metaMask,
    rules,
    auth,
    shop,
    payments,
    profile,
    assets,
    alert,
    map,
  },
});
