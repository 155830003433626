<template>
  <loading-spinner class="spinner" v-if="isLoading"></loading-spinner>
  <div class="auth-wrapper" v-else>
    <section class="auth-card" v-if="!tokenIsValid">
      <template>
        <v-img
          src="../../../assets/images/resetPassword/alert.png"
          contain
          max-width="160"
          class="mx-auto"
          aspect-ratio="1"
        ></v-img>
        <h3 class="text-center mt-3 mb-5" style="color: #ff3750">Invalid access token</h3>
        <p class="grey--text text-center">
          Your can't access to this action. please try again.
        </p>
        <routerLink :to="{ name: 'login' }" class="mt-2 d-block text-center">
          Back to login
        </routerLink>
      </template>
    </section>
    <section class="auth-card" v-else>
      <h2 class="text-center white--text mb-4">Create new Password</h2>
      <v-form
        @submit.prevent="confirmNewPassword"
        ref="passwordForm"
        v-if="!newPasswordConfirmed"
      >
        <h5 class="text-center grey--text mb-8">
          Enter the new password at least 6 characters long.
        </h5>
        <v-text-field
          label="Password"
          type="password"
          :rules="rules.password_rules"
          v-model="form.password"
          outlined
          dense
          dark
          placeholder="Type your password"
          class="mb-2"
        >
          <v-icon slot="append"> mdi-lock </v-icon></v-text-field
        >

        <v-text-field
          label=" Password Confirmation"
          type="password"
          :rules="rules.password_rules.concat(passwordConfirmationRule)"
          v-model="form.password_confirmation"
          outlined
          dense
          dark
          placeholder="Type your password again"
        >
          <v-icon slot="append">mdi-lock-check</v-icon>
        </v-text-field>
        <v-btn
          type="submit"
          class="login-btn w-100 mt-4"
          :loading="confirmLoading"
          :disabled="confirmLoading"
        >
          Create new password
        </v-btn>
      </v-form>
      <template v-else>
        <v-img
          src="../../../assets/images/resetPassword/tick1.png"
          contain
          max-width="160"
          class="mx-auto"
          aspect-ratio="1"
        ></v-img>
        <h3 class="text-center" style="color: #3ce325">Password changed successfully</h3>
        <p class="grey--text text-center">
          Your can login with your email and new password confirmed.
        </p>
        <routerLink :to="{ name: 'login' }" class="mt-2 d-block text-center">
          Back to login
        </routerLink>
      </template>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      isLoading: false,
      confirmLoading: false,
      form: {
        enteredPassword: "",
        password_confirmation: "",
      },
      token: null,
      tokenIsValid: false,
      newPasswordConfirmed: false,
    };
  },
  computed: {
    ...mapGetters(["rules"]),
    passwordConfirmationRule() {
      return () =>
        this.form.password === this.form.password_confirmation ||
        "Passwords dose not match!";
    },
  },
  created() {
    this.token = this.$route.query.token;
    
    this.checkToken();
  },
  methods: {
    ...mapActions(["resetPasswordCheckToken", "resetPasswordConfirmNewPassword"]),
    checkToken() {
      if (this.token) {
        this.isLoading = true;
        this.resetPasswordCheckToken({ token: this.token }).then((result) => {
          
          this.tokenIsValid = result.data; //its 0 or 1
          this.isLoading = false;
        });
      }
    },
    confirmNewPassword() {
      if (this.$refs.passwordForm.validate()) {
        this.confirmLoading = true;
        this.form.token = this.token;
        this.resetPasswordConfirmNewPassword(this.form)
          .then((result) => {
            this.newPasswordConfirmed = true;
          })
          .finally(() => {
            this.confirmLoading = false;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.auth-wrapper {
  background-image: url("../../../assets/images/resetPassword/Lock-bg.jpg");
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  .auth-card {
    width: 98%;
    max-width: 400px;
    background: linear-gradient(37deg, black, #004c4f33);
    backdrop-filter: blur(5px);
    border-radius: 8px;
    padding: 30px 35px;
    min-height: 373.8px;
    @media only screen and(max-width : 380px) {
      transform: scale(0.9);
      padding: 20px 25px;
      margin-top: 30px;
    }
    .captcha-container {
      .captch {
        margin: 0 auto;
        @media only screen and(max-width : 380px) {
          transform: scale(0.9);
          transform-origin: left;
        }
        @media only screen and(max-width : 350px) {
          transform: scale(0.8);
        }
      }
    }
    .login-btn {
      background: var(--light-blue) !important;
      color: black;
      font-weight: bolder;
      transition: all 0.2s linear;
      box-shadow: none;

      &:hover {
        letter-spacing: 2px;
        box-shadow: 0 0 12px var(--light-blue);
      }
    }
    .auth-link {
      transition: all 0.2s linear;
      &:hover {
        letter-spacing: 5px;
      }
    }
    .forgot-pass {
      display: flex;
      justify-content: end;
      font-size: 14px;
      color: var(--light-blue);
      margin-bottom: 20px;
    }
  }
}
::v-deep .v-text-field {
  width: 100%;
  input {
    &::placeholder {
      color: rgb(255, 255, 255) !important;
      filter: brightness(0.8);
    }
  }
  fieldset {
    border: 2px solid rgb(255, 255, 255) !important;
  }
  // .v-text-field__details {
  //   margin: 0;
  // }
}
</style>
