<template>
  <section>
    <h2 class="grey--text text-center mt-15" v-if="!selectedLegend.sale">
      No sale information!
    </h2>

    <div class="sell" v-else>
      <div class="px-3">
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          :items-per-page="5"
          hide-default-footer
          dense
          class="table elevation-1 mt-3 white--text"
          dark
        >
          <template v-slot:top>
            <section class="head d-flex justify-center pa-3 white--text">
              <h3>For Sale List</h3>
            </section>
          </template>
          <template v-slot:item.price="{ item }">
            <span v-for="(value, name, i) in item.price" :key="i">
              <span v-if="value.price" class="d-flex">
                <span>{{ value.price }}</span>
                <img :src="coinIconRender(name)" width="13px" class="mx-1" />
              </span>
            </span>
          </template>
          <template v-slot:item.action="{ item }">
            <v-btn class="btn buy-btn" @click="$emit('buy', { id: item.id })">
              Buy Now
              <v-icon right size="10">mdi-cart</v-icon>
            </v-btn>
          </template>
        </v-data-table>

        <div class="table-paginate-box">
          <div class="table-pagination-total">
            {{ total }} <span class="">result(s)</span>
          </div>
          <div class="paginate-box">
            <v-pagination
              v-model="page"
              class="pagination"
              :length="lastPage"
              :total-visible="6"
              @input="fetchSellersList"
              color="blue"
              circle
            ></v-pagination>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["selectedLegend"],
  data() {
    return {
      page: 1,
      lastPage: null,
      total: 0,
      loading: false,
      items: [],
      headers: [
        {
          text: "ID",
          align: "start",
          value: "id",
          class: "white--text",
        },
        {
          text: "Item ID",
          value: "item_id",
          class: "white--text",
        },
        {
          text: "Total",
          value: "details.total",
          class: "white--text",
          sortable: false,
        },
        {
          text: "Available",
          value: "details.available",
          class: "white--text",
          sortable: false,
        },
        {
          text: "Price",
          value: "price",
          class: "white--text",
          sortable: false,
        },
        {
          text: "Status",
          value: "details.status",
          class: "white--text",
          sortable: false,
        },
        {
          text: "Action",
          value: "action",
          class: "white--text",
          sortable: false,
        },
      ],
    };
  },
  mounted() {
    this.fetchSellersList(1);
  },
  methods: {
    ...mapActions(["getSellersList"]),
    async fetchSellersList(page) {
      const id = this.selectedLegend.sale.id;
      let params = new URLSearchParams();
      params.append("page", page);
      params.append("per_page", 3);

      // start loading
      this.loading = true;

      // request
      await this.getSellersList({ id, params })
        .then((res) => {
          if (res) {
            this.items = res.result.data;
            this.total = res.result.total;
            this.lastPage = res.result.last_page;
          }
        })
        .finally(() => {
          // stop loading
          this.loading = false;
        });
    },
    coinIconRender(name) {
      return `${process.env.VUE_APP_COIN_ICON_ADDRESS}/32/color/${name}.png`;
    },
    situationRender(item) {
      if (item.status === "pending" && !item.buyer_user_id)
        return "listed for sell";
      else if (item.status !== "pending " && item.buyer_user_id)
        return "purchased";
      else if (item.status !== "pending " && !item.buyer_user_id)
        return "canceled";
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  display: flex;
  justify-content: space-between;
  margin-top: 2.5%;
  padding: 5px 20px;
  border-radius: 5px;
  background: #1f2642;
  @media only screen and(max-width: 500px) {
    font-size: 12px;
  }
}
.table {
  ::v-deep thead th {
    font-size: 9px !important;
    padding: 0 10px !important;
    font-weight: 500;
  }

  ::v-deep tbody td {
    font-size: 9px !important;
    padding: 0 10px !important;
  }
}
.table-paginate-box {
  padding: 3px 10px;
  .table-pagination-total {
    padding: 2px 5px;
  }
}

.btn {
  font-weight: bolder;
  color: var(--background-color);
  height: 20px !important;
  font-size: 10px;
  padding: 0px 5px !important;
  > .v-btn__content .v-icon {
    margin-left: 0;
  }
  &.buy-btn {
    background: var(--text-color);
  }
}
</style>
