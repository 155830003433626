<template>
  <section class="decorative">
    <section class="d-flex">
      <!-- filters -->
      <section class="filters">
        <DecorativeFilters @submitFilters="submitFilters" />
      </section>
      <!-- filters -->

      <section class="main-shop py-3">
        <section class="">
          <MobileFilters
            @submitFilters="submitFilters"
            :total="total"
            type="decorative"
          ></MobileFilters>
          <SortBox
            @toggleDrawer="drawer = !drawer"
            @makeSort="makeSort"
            @resetSort="resetSort"
            :total="total"
          />
        </section>
        <div class="no-item" v-if="isLoading">
          <loading-spinner></loading-spinner>
        </div>
        <div class="no-item" v-else-if="!isLoading && decoratives.length <= 0">
          <h4 class="white--text">There is no item !</h4>
        </div>

        <v-row class="row pb-3" no-gutters v-else>
          <DecorativeItem
            v-for="(item, i) in decoratives"
            :key="i"
            :item="item"
            @showDetails="showDetails"
            @buyItem="buyItem"
          />
        </v-row>
      </section>
    </section>
    <div class="line"></div>
    <input-pagination
      :total="total"
      :page="page"
      :lastPage="lastPage"
      @passedPage="inputChangePage"
    ></input-pagination>

    <!--detail dialog -->
    <v-dialog v-model="detailDialog" width="600">
      <DetailCard
        @close="detailDialog = false"
        @buy="buyItem"
        :selectedItem="selectedDecorative"
        :isLoading="detailLoading"
        type="decorative"
      ></DetailCard>
    </v-dialog>

    <!--quastion dialog -->
    <v-dialog v-model="quastionDialog" width="500">
      <QuastionCard
        @cancel="quastionDialog = false"
        text="Are you sure to buy this item ? "
        :isLoading="buyLoading"
        @accept="accept"
      ></QuastionCard>
    </v-dialog>
  </section>
</template>

<script>
import { mapActions } from "vuex";
import DecorativeItem from "./DecorativeItem.vue";
import DecorativeFilters from "./DecorativeFilters.vue";
export default {
  data() {
    return {
      decoratives: [],
      isLoading: false,
      selectedDecorative: {},

      // details
      detailDialog: false,
      detailLoading: false,

      // buy
      quastionDialog: false,
      buyLoading: false,
      selectedItemInfo: null,

      // sort
      selectedSort: null,
      selectedOrder: null,
      //filters
      selectedSpecifications: [],
      priceRange: [0, 23300],
      name: null,
      id: null,
      //paginate
      page: 1,
      lastPage: 0,
      total: 0,
    };
  },
  components: {
    DecorativeItem,
    DecorativeFilters,
  },
  created() {
    this.fetchDecorativesLists();
  },
  methods: {
    ...mapActions(["getItemDetails", "getMarketData", "buy"]),

    fetchDecorativesLists() {
      let params = {
        page: this.page,
        limit: 8,
        type: "decorative",
        search_id: this.id,
        search_name: this.name,
        from_price: this.priceRange[0],
        to_price: this.priceRange[1],
        tags: this.selectedSpecifications,
      };
      if (this.selectedSort != null) {
        params.sort = this.selectedSort;
        params.order = this.selectedOrder;
      }

      this.isLoading = true;
      this.getMarketData(params)
        .then((result) => {
          this.decoratives = result.result.data;
          this.total = result.result.total;
          this.lastPage = result.result.last_page;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    showDetails(item) {
      this.detailDialog = true;
      this.detailLoading = true;
      this.getItemDetails({ itemsName: "decoratives", id: item.item_id }).then(
        (result) => {
          this.selectedDecorative = result.data.result;
          this.detailLoading = false;
        }
      );
    },
    submitFilters(submitedFilters) {
      this.name = submitedFilters.name;
      this.id = submitedFilters.id;
      this.priceRange = submitedFilters.priceRange;
      this.selectedSpecifications = submitedFilters.selectedSpecifications;
      this.fetchDecorativesLists();
    },

    makeSort(order, sort) {
      this.selectedSort = sort;
      this.selectedOrder = order;
      this.fetchDecorativesLists();
    },
    resetSort() {
      this.selectedSort = null;
      this.selectedOrder = null;
      this.fetchDecorativesLists();
    },
    buyItem(info) {
      this.selectedItemInfo = info;
      this.quastionDialog = true;
    },
    accept() {
      this.buyLoading = true;

      const id = this.selectedItemInfo.id;
      let params = new URLSearchParams();
      if (this.selectedItemInfo.auto) {
        params.set("type", "auto");
      }
      this.buy({ id, params })
        .then((result) => {
          this.message = result.message;
          this.snackbar = true;
          this.fetchDecorativesLists();
        })
        .finally(() => {
          this.buyLoading = false;
          this.quastionDialog = false;
        });
    },
    inputChangePage(val) {
      this.page = val;
      this.fetchDecorativesLists();
    },
  },
};
</script>
<style lang="scss" scoped>
.decorative {
  .filters {
    width: 100%;
    max-width: 17%;
    border-right: 2px solid #6ee6ff;
    // overflow: hidden;
    @media only screen and(max-width: 980px) {
      display: none;
    }
  }
  .main-shop {
    // position: relative;
    width: 100%;
    min-width: 83%;
  }
}
</style>
