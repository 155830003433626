<template>
  <v-card class="card" dark>
    <v-card-title class="card-title d-flex justify-space-between">
      <h4 class="white--text">Connect to Wallet</h4>

      <v-icon @click="close">mdi-close-circle-outline</v-icon>
    </v-card-title>

    <v-card-text class="card-text">
      <!-- if wallet not installed -->

      <!-- <tron-web-not-installed
            v-if="!tronLinkNotInstalled()"
          >{{tronLinkNotInstalled}}</tron-web-not-installed> -->
      <meta-mask-not-installd
        v-if="!metamaskNotInstalled()"
      ></meta-mask-not-installd>

      <section v-if="!wallet_address">
        <p class="mt-2 text-center" style="font-size: 11px">
          WalletConnect establishes a secure connection between your Bitcoin.com
          Wallet and ABC Finance, allowing ABC Finance to see the contents of
          your Ethereum Wallet. Then you'll have the option to, for example,
          send ETH from your Bitcoin.com Wallet to ABC Finance's lending smart
          contract
        </p>
        <!-- Metamask Option -->
        <div
          class="option"
          @click="connectToMetaMask"
          v-if="metamaskNotInstalled()"
        >
          <div class="">
            <span v-if="!metaMaskIsLoading">
              MetaMask Wallet
              <div class="error--text" v-if="metaMaskStatus === 'REJECTED'">
                You Rejected Try Again!
              </div>
            </span>
            <span v-else class="d-flex justify-canter">
              <loading-spinner class="spinner"></loading-spinner>
            </span>
          </div>
          <v-img
            src="@/assets/images/wallets/metamask.png"
            alt="Meta mask"
            max-width="40px"
          />
        </div>
        <!-- Metamask Option -->
        <!-- TronLink Option -->
        <!-- <div
              class="option"
              @click="connectToTronLink"
              v-if="tronLinkNotInstalled()"
            >
              <div>
                <span v-if="!tronIsLoading" >
                  TronLink Wallet
                <div class="error--text" v-if="tronWebStatus === 'REJECTED'">
                  You Rejected Try Again!
                </div>
                <div class="error--text" v-if="tronWebStatus === 'LOCKED'">
                  Your Wallet Locked, Unlock it!
                </div>
                </span>
                <span v-else class="d-flex justify-canter">
                  <loading-spinner
                  class="spinner"
                  ></loading-spinner>
                </span>
              </div>
              <img
                :src="require('../assets/images/Tronlink.svg')"
                alt="tronlink"
              />
            </div> -->
        <!-- TronLink Option -->
      </section>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import MetaMaskNotInstalld from "../meta-mask/MetaMaskNotInstalld.vue";
// import TronWebNotInstalled from './tron-web/TronWebNotInstalled.vue';
export default {
  components: {
    // TronWebNotInstalled,
    MetaMaskNotInstalld,
  },
  data() {
    return {
      dialog: false,
      loading: false,
      // tronIsLoading: false,
      metaMaskIsLoading: false,
    };
  },

  computed: {
    ...mapGetters([
      "token",
      "user",
      "tronWebStatus",
      "metaMaskStatus",
      "wallet_address",
      "connectedTo",
    ]),
  },
  created() {},

  methods: {
    ...mapActions([
      "metaMaskConnect",
      "swithToPolygon",
      "tronLinkConnect",
      "setTronWebStatus",
      "setWalletAddress",
      "setConnectedTo",
    ]),
    ...mapMutations(["userClear"]),

    // tronLinkNotInstalled() {
    // return !!window.tronWeb
    // },
    metamaskNotInstalled() {
      return !!window.ethereum;
    },

    connectToMetaMask() {
      this.metaMaskIsLoading = true;
      this.metaMaskConnect()
        .then(() => {
          this.swithToPolygon();
          this.close();
        })
        .finally(() => {
          this.metaMaskIsLoading = false;
        });
    },
    close() {
      this.$emit("close");
    },
    // connectToTronLink() {
    //   this.tronIsLoading = true;
    //   this.tronLinkConnect()
    //     .then(() => {
    //       this.check_user_exist();
    //     })
    //     .finally(() => {
    //       this.tronIsLoading = false;
    //     });
    // },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/components/dialogCard.scss";
.v-list-item {
  &:hover {
    background: var(--base-color);
  }
}
.card {
  .card-text {
    height: auto !important;
    max-height: 520px;
    overflow: auto;
    // background: var(--background-color);
    .logo {
      display: block;
      margin: auto;
      margin-top: 20px;
      width: 200px;
    }
    .option {
      width: 90%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 auto;
      border-radius: 5px;
      padding: 0 30px;
      height: 80px;
      margin-top: 30px;
      cursor: pointer;
      border: 2px solid var(--secondary-color);
      color: var(--secondary-color);
      background: rgba(0, 255, 238, 0.116);
      transition: 0.4s ease;

      &:hover {
        background: var(--secondary-color);
        transform: scale(1.03);
        color: black;
        box-shadow: 0 0 10px 2px var(--secondary-color);
      }
      .spinner {
        transform: scale(0.5);
        &::v-deep .circle.circle-1 {
          border-top-color: var(--base-color) !important;
        }
        &::v-deep .circle.circle-2 {
          border-bottom-color: var(--base-color) !important;
        }
      }
    }
  }
}
</style>
