<template>
  <section class="sort-box px-1 px-sm-5 w-100">
    <v-row class="white--text" no-gutters align="center">
      <v-col cols="12" sm="9" class="d-sm-flex">
        <h3 class="mr-2 sort-text d-none d-sm-block" style="white-space: nowrap">
          <v-icon class="mb-1 sort-text">mdi-sort-ascending</v-icon> Sort By :
        </h3>

        <v-btn
          small
          class="sort-item-btn"
          :class="
            activeSort.order === 'price' && activeSort.sort === 'desc' ? 'active' : ''
          "
          @click="makeSort('price', 'desc')"
          >Highest Price</v-btn
        >
        <v-btn
          small
          class="sort-item-btn"
          :class="
            activeSort.order === 'price' && activeSort.sort === 'asc' ? 'active' : ''
          "
          @click="makeSort('price', 'asc')"
          >Lowest Price</v-btn
        >
        <v-btn
          small
          class="sort-item-btn"
          :class="
            activeSort.order === 'updated_at' && activeSort.sort === 'asc' ? 'active' : ''
          "
          @click="makeSort('updated_at', 'asc')"
          >Newest</v-btn
        >
        <v-btn
          small
          class="sort-item-btn"
          :class="
            activeSort.order === 'updated_at' && activeSort.sort === 'desc'
              ? 'active'
              : ''
          "
          @click="makeSort('updated_at', 'desc')"
          >Oldest</v-btn
        >
      </v-col>
      <v-col cols="0" sm="3" class="d-none d-md-block">
        <div class="mr-md-3 text-end">{{ total }} <span class="">Result(s)</span></div>
      </v-col>
    </v-row>
  </section>
</template>

<script>
export default {
  props: ["total"],
  data() {
    return {
      activeSort: "none",
    };
  },

  methods: {
    toggleDrawer() {
      this.$emit("toggleDrawer");
    },
    makeSort(order, sort) {
      if (this.activeSort.order === order && this.activeSort.sort === sort) {
        this.activeSort = "none";
        this.$emit("resetSort");
      } else {
        this.$emit("makeSort", order, sort);
        this.activeSort = { order: order, sort: sort };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.sort-text {
  color: var(--text-color);
}
.sort-item-btn {
  background: var(--base-color) !important;
  color: var(--secondary-color);
  font-weight: 900;
  margin: 0 5px;
  transition: all 0.4s ease;
  @media only screen and(max-width:600px) {
    width: 24%;
    font-size: 10px;
    margin: 0 0.5%;
  }
  @media only screen and(max-width:500px) {
    font-size: 8px;
    margin: 0 0.5%;
  }
  &:hover {
    box-shadow: var(--secondary-color) 0px 0px 5px;
  }
  &.active {
    background: var(--secondary-color) !important;
    color: var(--base-color);
    box-shadow: var(--secondary-color) 0px 0px 10px;
  }
}
</style>
