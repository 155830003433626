<template>
  <div class="game-mode">
    <div class="game-container">
      <div class="banner">
        <img class="img" src="../../assets/images/ractor-poster.png" alt="" />
        <StartGameButton class="start-btn"></StartGameButton>
      </div>

      <div class="actions">
        <button class="action-btn">
          Download APK <v-icon size="20" color="black">mdi-download</v-icon>
        </button>
        <button class="action-btn" style="background: #d4cdcd">
          Google play <v-icon size="20" color="black">mdi-google-play</v-icon>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import StartGameButton from "./StartGameButton.vue";
import { mapGetters } from "vuex";
export default {
  props: ["game"],
  data() {
    return {
      isStarted: false,
    };
  },
  components: {
    StartGameButton,
  },
  computed: {
    ...mapGetters(["token"]),
  },
  mounted() {},
  methods: {
    imageLink(image) {
      return `${process.env.VUE_APP_ROOT_API}/${image}`;
    },
    startGame() {
      // window.innerWidth < 1030
      //   ? window.open(
      //       `https://minigames.ecogamecenter.net/games/${this.$route.params.id}/?token=${this.token}`
      //     )
      //   : (this.isStarted = true);
      // this.isStarted = true;
      this.$router.push({ name: "play-azollite" });
    },
    // toggleFullscreen() {
    //   const el = this.$refs.iframeWrapper;
    //   if (document.fullscreenElement) {
    //     document.exitFullscreen();
    //   } else {
    //     el.requestFullscreen();
    //   }
    // },
  },
};
</script>

<style lang="scss" scoped>
.game-mode {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  .game-container {
    width: 97%;
    max-width: 800px;
    position: relative;
    height: 520px;
    overflow: hidden;
    border: 2px solid rgb(0, 213, 255);
    // box-shadow: 0 0 10px 5px black;
    background: radial-gradient(circle at left top, #f704ff, #4eb2ca, #dabc5a);
    border-radius: 5px;
    .banner {
      &::before {
        content: "";
        background: rgba(0, 0, 0, 0.432);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
      }
      .img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      .start-btn {
        position: absolute;
        font-size: 60px;
        color: #3682f5;
        font-weight: 100;
        border-radius: 50%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 10;
      }
    }

    .actions {
      position: absolute;
      left: 0;
      top: 70%;
      width: 100%;
      display: none;
      z-index: 2;
      @media only screen and (max-width: 450px) {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .action-btn {
        width: 70%;
        background: green;
        padding: 6px 0;
        border-radius: 3px;
        box-shadow: 0 0 10px 5px black;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
        i {
          margin-left: 15px;
        }
      }
    }
  }
}
</style>
