import Vue from "vue";
import App from "./App.vue";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "./assets/style/main.scss";
import LoadingSpinner from "./components/common/LoadingSpinner.vue";
import DetailCard from "./components/shop/detailCard/index/DetailCard.vue";
import QuastionCard from "./components/common/QuastionCard.vue";
import HeadingTitle from "./components/common/HeadingTitle.vue";
import LandMapShow from "./components/map/LandMapShow.vue";
import InputPagination from "./components/common/InputPagination.vue";
import Or from "./components/common/Or.vue";
import GoogleLogin from "./views/authentication/GoogleLogin.vue";
import WalletDialogCard from "./components/wallet/WalletDialogCard.vue";
import SortBox from "./components/shop/sortBox/SortBox.vue";
import MobileFilters from "./components/shop/mobileFiltersContainer/MobileFilters.vue";

Vue.config.productionTip = false;
Vue.component("loading-spinner", LoadingSpinner);
Vue.component("DetailCard", DetailCard);
Vue.component("QuastionCard", QuastionCard);
Vue.component("HeadingTitle", HeadingTitle);
Vue.component("LandMapShow", LandMapShow);
Vue.component("InputPagination", InputPagination);
Vue.component("GoogleLogin", GoogleLogin);
Vue.component("Or", Or);
Vue.component("WalletDialogCard", WalletDialogCard);
Vue.component("SortBox", SortBox);
Vue.component("MobileFilters", MobileFilters);

Sentry.init({
  Vue,
  dsn: "https://fb09bd2cb9b64a3ba7ed067f036d9a90@sentry.sepantatech.com/22", //get from sepanta
  logErrors: true,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["localhost", "*", /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
