<template>
  <v-col cols="6" sm="6" md="3" class="">
    <section class="item-card">
      <img
        src="../../../assets/images/cards/land-card-bg.png"
        class="item-card-background"
      />
      <v-img
        :src="storageImageURL(item.image)"
        class="item-card-image"
        @load="imgIsLoaded"
      >
        <v-skeleton-loader
          v-if="!imageIsLoaded"
          width="100%"
          height="100%"
          type="image"
          class="skeleton"
        ></v-skeleton-loader>
      </v-img>

      <h5 class="item-card-name-container">
        <span>{{ item.name }}</span>
      </h5>
      <h5 class="item-card-status">
        <span class="">{{ item.attributes.size }}</span>
      </h5>
      <!-- <h5 class="item-card-level">
        <span class="">{{ item.attributes.category }}</span>
      </h5> -->
      <h5 class="item-card-price">
        <span> {{ item.attributes.landscape }} </span>
      </h5>
      <h5 class="item-card-id">
        <span>{{ item.nft_id }}</span>
      </h5>

      <button
        class="item-card-shop-button"
        @click.stop="unchainNft()"
        :loading="approveLoading"
      >
        <span>Unchain NFT</span>
      </button>
    </section>
    <!--quastion dialog -->
    <v-dialog v-model="quastionDialog" width="500">
      <QuastionCard
        @cancel="quastionDialog = false"
        text="Are you sure to unchain this item ? "
        :isLoading="false"
        @accept="startAccept"
      ></QuastionCard>
    </v-dialog>
    <!--quastion dialog -->
  </v-col>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { callContractMixin } from "../../../mixins/callContractMixin.js";
import { storageImage } from "@/mixins/storageImage.js";
export default {
  mixins: [callContractMixin, storageImage],
  props: ["item"],
  data() {
    return {
      quastionDialog: false,
      interval: null,
      approveLoading: false,

      imageIsLoaded: false,
    };
  },
  beforeDestroy() {
    this.stopInterval();
  },
  computed: {
    ...mapGetters(["wallet_address", 'configs']),
  },
  methods: {
    ...mapActions(["setAlert", "depositNft"]),
    //deposit
    unchainNft() {
      this.quastionDialog = true;
    },
    async accept(approve) {
      this.quastionDialog = false;
      const info = {
        nft_id: this.item.nft_id,
      };
      //getApproved call contract
      await this.callContract(
        this.configs.nftContractAddressEth,
        "getApproved",
        [info.nft_id],
        async (res) => {
          if (res === this.configs.OracleAddress) {
            this.stopInterval();
            console.log("your front deposit done lets call api from backend");
            const depositInfo = {
              nft_id: this.item.nft_id,
              wallet_address: this.wallet_address,
              nft_address: this.configs.nftContractAddressEth
            };
            this.depositNft(depositInfo)
              .then(() => {})
              .catch(() => {})
              .finally(() => {
                this.$emit("fetchNfts");
              });
          } else {
            //if nft not approved to deposit should open the metamask and approve for that we call approve write call contract
            if (approve) {
              await this.callContract(
                this.configs.nftContractAddressEth,
                "approve",
                [this.configs.OracleAddress, info.nft_id],
                (res) => {
                  if (res) {
                    if (res.code === 4001) {
                      //rejected
                      this.setAlert({
                        message: res.message,
                        type: "error",
                      });
                    } else {
                      //all done
                      this.setAlert({
                        message:
                          "NFT Approving process started. since it was done you can DEPOSIT It.",
                        type: "success",
                      });
                      this.startIntreval();
                    }
                  } else {
                    //res is false
                    this.stopInterval();
                  }
                },
                true
              );
            }
          }
        },
        false
      );
    },
    startAccept() {
      this.accept(true);
    },
    startIntreval() {
      this.approveLoading = true;
      this.interval = setInterval(() => {
        this.accept(false);
      }, 10000);
      setTimeout(() => {
        this.stopInterval();
      }, 300000);
    },

    stopInterval() {
      this.approveLoading = false;
      clearInterval(this.interval);
    },

    imgIsLoaded() {
      this.imageIsLoaded = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.col {
  transition: all 0.3s ease;
  @media only screen and(max-width: 500px) {
    font-size: 9px;
  }
}
</style>
