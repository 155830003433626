<template>
  <div class="auth-wrapper">
    <section class="auth-card">
      <h2 class="text-center white--text">Sign Up</h2>
      <p class="text-center mb-5 white--text">Get start with create an account</p>
      <v-form @submit.prevent="validate" ref="register" v-model="valid" lazy-validation>
        <div class="form-group">
          <v-text-field
            label="Name"
            type="text"
            :rules="rules.name_rules"
            v-model="form.name"
            outlined
            dense
            placeholder="Type your name"
            dark
            class="mb-2"
          >
            <v-icon slot="append"> mdi-account </v-icon></v-text-field
          >
        </div>

        <div class="form-group">
          <v-text-field
            label="Usename"
            type="text"
            :rules="rules.required"
            v-model="form.username"
            outlined
            dense
            placeholder="Type your username"
            dark
            class="mb-2"
          >
            <v-icon slot="append"> mdi-account </v-icon></v-text-field
          >
        </div>

        <div class="form-group">
          <v-text-field
            label="Email"
            type="email"
            :rules="rules.email_rules"
            v-model="form.email"
            outlined
            dense
            dark
            placeholder="Type your email"
            class="mb-2"
            :error-messages="
              serverValidationErrorsRender(serverValidationErrors, 'email')
            "
            @input="removeServerValidation('email')"
          >
            <v-icon slot="append"> mdi-email </v-icon>
          </v-text-field>
        </div>

        <div class="form-group">
          <v-text-field
            label="Password"
            type="password"
            :rules="rules.password_rules"
            v-model="form.password"
            outlined
            dense
            dark
            placeholder="Type your password"
            class="mb-2"
          >
            <v-icon slot="append"> mdi-lock </v-icon></v-text-field
          >
        </div>
        <div class="form-group">
          <v-text-field
            label="Password Confirmation"
            type="password"
            :rules="rules.password_rules.concat(passwordConfirmationRule)"
            v-model="form.password_confirmation"
            outlined
            dense
            dark
            placeholder="Type your password again"
          >
            <v-icon slot="append">mdi-lock-check</v-icon>
          </v-text-field>
        </div>
        <div class="form-group">
          <v-text-field
            label="Referral code"
            v-model="form.referralCode"
            outlined
            dense
            dark
            placeholder="Type your password"
            class="mb-2"
          >
            <v-icon slot="append"> mdi-account-multiple-outline </v-icon></v-text-field
          >
          <div class="captcha-container d-flex">
            <VueRecaptcha
              class="captch"
              @verify="successCaptcha"
              @expired="expireCaptcha"
              :sitekey="sitekey"
            />
          </div>
        </div>

        <p style="font-size: 12px" class="error--text" v-if="isConfirmed && !captcha">
          Recaptcha is required !
        </p>

        <v-btn
          type="submit"
          class="login-btn mt-5 w-100"
          :loading="loading"
          :disabled="loading"
        >
          Sign Up
          <v-icon right> mdi-account-plus </v-icon>
        </v-btn>
      </v-form>
      <Or class="or-line"></Or>
      <GoogleLogin name="Continue with Google"></GoogleLogin>
      <div class="text-center mt-5">
        <p class="white--text">Dont have an account?</p>
        <routerLink :to="{ name: 'login' }" class="auth-link"> Sign In </routerLink>
      </div>
    </section>
  </div>
</template>

<script>
import VueRecaptcha from "vue-recaptcha";
import { captcha } from "@/mixins/captcha.js";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      loading: false,
      valid: true,
      isConfirmed: false,
      form: {
        name: "",
        username:'',
        email: "",
        password: "",
        password_confirmation: "",
        referralCode: "",
      },
    };
  },
  mixins: [captcha],
  components: { VueRecaptcha },
  computed: {
    ...mapGetters(["rules", "wallet_address", "serverValidationErrors"]),

    passwordConfirmationRule() {
      return () =>
        this.form.password === this.form.password_confirmation ||
        "Passwords dose not match!";
    },
  },
  created() {
    if (this.$route.query.referral) this.form.referralCode = this.$route.query.referral;
  },
  methods: {
    ...mapActions(["register", "removeServerValidationError", "clearServarValidations"]),
    serverValidationErrorsRender(errors, filed) {
      let currectValidateError = errors.find((element) => element.filed === filed);

      return currectValidateError ? currectValidateError.message : null;
    },
    removeServerValidation(filed) {
      this.removeServerValidationError(filed);
    },

    validate() {
      this.$refs.register.validate();
      this.$nextTick(() => {
        if (this.valid) {
          this.isConfirmed = true;
          if (this.captcha) {
            this.loading = true;
            this.form.recaptcha = this.captcha;
            this.register(this.form).then((res) => {
              if (res) {
                this.$router.push({ name: "Home" });
              }
              this.loading = false;
            });
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.auth-wrapper {
  background-image: url("../../assets/images/login/login-bg(1).jpg");
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  .auth-card {
    width: 98%;
    max-width: 400px;
    border-radius: 8px;
    padding: 20px 35px;
    background: linear-gradient(37deg, black, rgba(0, 0, 0, 0.205));
    backdrop-filter: blur(5px);
    margin: clamp(80px, 7%, 110px) 0;
    @media only screen and(max-width : 380px) {
      transform: scale(0.92);
      padding: 15px 25px;
    }
    .captcha-container {
      .captch {
        margin: 0 auto;
        @media only screen and(max-width : 380px) {
          transform: scale(0.9);
          transform-origin: left;
        }
        @media only screen and(max-width : 350px) {
          transform: scale(0.8);
        }
      }
    }
    .login-btn {
      background: var(--light-blue) !important;
      color: black;
      font-weight: bolder;
      transition: all 0.2s linear;
      box-shadow: none;
      &:hover {
        letter-spacing: 5px;
        box-shadow: 0 0 12px var(--light-blue);
      }
    }
    .auth-link {
      transition: all 0.2s linear;
      &:hover {
        letter-spacing: 3px;
      }
    }
  }
}
::v-deep .v-text-field {
  width: 100%;
  input {
    &::placeholder {
      color: rgb(255, 255, 255) !important;
      filter: brightness(0.8);
    }
  }
  fieldset {
    border: 2px solid rgb(255, 255, 255) !important;
  }
  .v-text-field__details {
    margin-bottom: 0;
  }
}
</style>
