<template>
  <LandItem
    :item="item"
    v-if="item.type === 'UserLand'"
    @showDetails="$emit('packItemShowDetails', item)"
  />
  <LootboxItem
    :item="item"
    v-else-if="item.type === 'Lootbox'"
    @showDetails="$emit('packItemShowDetails', item)"
  />
  <BuildingItem
    :item="item"
    v-else-if="item.type === 'Building'"
    @showDetails="$emit('packItemShowDetails', item)"
  />
  <DecorativeItem
    :item="item"
    v-else-if="item.type === 'Decorative'"
    @showDetails="$emit('packItemShowDetails', item)"
  />
  <UnitItem
    :item="item"
    v-else-if="item.type === 'Unit'"
    @showDetails="$emit('packItemShowDetails', item)"
  />
  <CurrencyItem
    :item="item"
    v-else-if="item.type === 'Coin'"
    @showDetails="$emit('packItemShowDetails', item)"
  />
</template>

<script>
import LandItem from "../../../../../lands/LandItem.vue";
import UnitItem from "../../../../../units/UnitItem.vue";
import BuildingItem from "../../../../../buildings/BuildingItem.vue";
import DecorativeItem from "../../../../../decoratives/DecorativeItem.vue";
import LootboxItem from "../../../../../lootboxes/LootboxItem.vue";
import CurrencyItem from "../../../../../currencies/CurrencyItem.vue";
export default {
  props: ["item"],
  components: {
    LandItem,
    UnitItem,
    BuildingItem,
    DecorativeItem,
    LootboxItem,
    CurrencyItem,
  },
  methods: {},
};
</script>
