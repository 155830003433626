<template>
  <section>
    <div class="land">
      <div class="px-3">
        <section class="d-sm-flex justify-center mt-3" v-if="item.map_info_id">
          <h4 class="mr-6">
            <span class="yellow--text">Area : </span>{{ item.land.category.title }}
          </h4>
          <h4 class="mr-6">
            <span class="yellow--text">Area id : </span>{{ item.land.category.id }}
          </h4>
          <h4 class="mr-6">
            <span class="yellow--text">Coordinate X : </span
            >{{ item.map_info.coordinate_x }}
          </h4>
          <h4 class="mr-6">
            <span class="yellow--text">Coordinate Y : </span
            >{{ item.map_info.coordinate_y }}
          </h4>
        </section>
        <h3 class="text-center mt-3" v-else>No Location!</h3>
        <v-divider class="mb-2"></v-divider>
        <LandMapShow :item="item"></LandMapShow>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: ["item"],
};
</script>

<style></style>
