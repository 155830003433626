import mainApi from "../../plugins/axios/axios.js";
const state = {};

const mutations = {};

const actions = {
  getUserLands() {
    return new Promise((resolve, reject) => {
      mainApi
        .get(`/users/lands`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((err) => {
          reject();
        });
    });
  },
  getUserLootboxes() {
    return new Promise((resolve, reject) => {
      mainApi
        .get(`/users/lootboxes`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((err) => {
          reject();
        });
    });
  },
  openLootbox(_, id) {
    return new Promise((resolve, reject) => {
      mainApi
        .post(`users/lootboxes/open/${id}`)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject();
        });
    });
  },
  getUserBuildings() {
    return new Promise((resolve, reject) => {
      mainApi
        .get(`/users/buildings`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((err) => {
          reject();
        });
    });
  },
  getUserDecoratives() {
    return new Promise((resolve, reject) => {
      mainApi
        .get(`/users/decoratives`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((err) => {
          reject();
        });
    });
  },
  getUserUnits() {
    return new Promise((resolve, reject) => {
      mainApi
        .get(`/users/units`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((err) => {
          reject();
        });
    });
  },
  getUserCurrencies() {
    return new Promise((resolve, reject) => {
      mainApi
        .get(`/users/coins`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((err) => {
          reject();
        });
    });
  },
  getUserPacks() {
    return new Promise((resolve, reject) => {
      mainApi
        .get(`/users/packs`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((err) => {
          reject();
        });
    });
  },
  getUserLegends() {
    return new Promise((resolve, reject) => {
      mainApi
        .get(`/users/legends`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((err) => {
          reject();
        });
    });
  },
  transferNftToWallet(_, info) {
    return new Promise((resolve, reject) => {
      mainApi
        .post(`users/withdraw-nft`, info)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject();
        });
    });
  },
  getUserForSales(_, params) {
    return new Promise((resolve, reject) => {
      mainApi
        .get(`/users/sales`, { params: params })
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject();
        });
    });
  },
  sellItem(_, info) {
    return new Promise((resolve, reject) => {
      mainApi
        .post(`/users/sales`, info)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject();
        });
    });
  },
  cancelSell(_, id) {
    return new Promise((resolve, reject) => {
      mainApi
        .delete(`/users/sales/${id}`)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject();
        });
    });
  },

  depositNft(_, info) {
    return new Promise((resolve, reject) => {
      mainApi
        .post(`/users/deposit-nft`, info)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject();
        });
    });
  },
};
const getters = {};

export default {
  state,
  getters,
  mutations,
  actions,
};
