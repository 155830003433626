<template>
  <v-card class="card">
    <v-card-title class="card-title">
      <h4 class="white--text">
        {{ user.google2fa ? "Deactivate" : "Activate" }} Google 2FA
      </h4>
    </v-card-title>

    <v-card-text class="mt-5 white--text">
      <div class="loading" style="height: 200px" v-if="boxLoading">
        <loading-spinner></loading-spinner>
      </div>

      <div v-if="!user.google2fa && !boxLoading">
        <img :src="qrcode" alt="qrcode" width="150" class="d-block mx-auto" />
        <h3 class="mb-4 mt-3 text-center">{{ secret }}</h3>
      </div>

      <v-form ref="googleForm">
        <v-text-field
          type="number"
          label="Code"
          :rules="rules.required"
          v-model="code"
          placeholder="Google 2Fa Code"
          outlined
          dense
          dark
        >
        </v-text-field>
      </v-form>
    </v-card-text>

    <v-divider dark></v-divider>

    <v-card-actions class="card-actions">
      <v-spacer></v-spacer>
      <v-btn color="error" text @click="$emit('close')"> Cancel </v-btn>
      <v-btn color="success" text @click="googleForm" :loading="loading">
        {{ user.google2fa ? "Deactivate" : "Activate" }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    active: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      boxLoading: false,
      loading: false,
      code: "",
      qrcode: "",
      secret: "",
    };
  },
  computed: {
    ...mapGetters(["user", "rules"]),
  },
  methods: {
    ...mapActions(["generateTwoFa", "changeTwoFaStatus"]),

    async generate_two_fa() {
      if (!this.user.google2fa) {
        this.boxLoading = true;

        await this.generateTwoFa().then((res) => {
          this.qrcode = res.auth2fa_qrcode;
          this.secret = res.google2fa_secret;
        });

        this.boxLoading = false;
      }
    },
    async googleForm() {
      this.$refs.googleForm.validate();

      if (this.$refs.googleForm.validate()) {
        // start loading
        this.loading = true;

        // set variable
        let content = {
          code: this.code,
          status: this.user.google2fa ? "inactive" : "active",
        };

        // request
        await this.changeTwoFaStatus(content).then((res) => {
          if (res) {
            this.loading = false;
            // close modal
            this.$emit("close");
          }
        });

        this.loading = false;
      }
    },
  },

  watch: {
    active() {
      if (this.active) {
        this.generate_two_fa();
      }
    },
  },

  mounted() {
    this.generate_two_fa();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/components/dialogCard.scss";
</style>
