<template>
  <section class="details">
    <v-card class="card" dark>
      <v-card-title class="card-title">
        <section class="w-100 d-flex justify-space-between align-center">
          <h3 class="white--text text-capitalize">{{ type }} Details</h3>
          <v-icon @click="close">mdi-close-circle-outline</v-icon>
        </section>
      </v-card-title>
      <transition
        :name="!PackItemDetailIsVisible ? 'slide-right' : 'slide-left'"
      >
        <PackContentDetail
          v-if="PackItemDetailIsVisible"
          @toggleVisibility="togglePackItemDetailVisibility"
          :selectedItem="selectedPackItem"
        ></PackContentDetail>
        <OriginalContent
          :isLoading="isLoading"
          :type="type"
          :selectedItem="selectedItem"
          @buy="buyItem"
          @packIShowDetails="packIShowDetails"
          v-else
        ></OriginalContent>
      </transition>

      <v-card-actions class="card-actions" v-if="!isLoading">
        <v-btn
          v-if="$route.name !== 'assets'"
          min-width="150"
          class="btn buy-btn mx-auto"
          :loading="isLoading"
          @click="buyItem({ id: selectedItem.sale.id || selectedItem.id, auto: true })"
        >
          Buy Now
          <v-icon right size="20">mdi-cart</v-icon>
        </v-btn>
        <v-btn
          v-if="$route.name === 'assets'"
          min-width="150"
          class="btn sell-btn mx-auto"
          @click="addToSale(selectedItem)"
        >
          Sell
          <v-icon right size="20">mdi-cart</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </section>
</template>

<script>
import PackContentDetail from "../content/packDetails/tabs/packContents/PackContentDetail.vue";
import OriginalContent from "./OriginalContent.vue";
export default {
  props: ["selectedItem", "isLoading", "type"],
  data() {
    return {
      PackItemDetailIsVisible: false,
      selectedPackItem: {},
    };
  },

  components: {
    PackContentDetail,
    OriginalContent,
  },
  created() {},
  methods: {
    buyItem(info) {
      this.$emit("buy", info);
    },
    addToSale(item) {
      this.$emit("sell", item);
    },
    close() {
      this.$emit("close");
      this.PackItemDetailIsVisible = false;
    },
    storageImageLink(img) {
      return `${process.env.VUE_APP_ROOT_API}/${img}`;
    },
    packIShowDetails(item) {
      this.selectedPackItem = item;
      this.togglePackItemDetailVisibility();
    },
    togglePackItemDetailVisibility() {
      this.PackItemDetailIsVisible = !this.PackItemDetailIsVisible;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/components/dialogCard.scss";
.details {
  .card {
    height: 85vh;
    overflow-x: hidden;
    .card-text {
      height: 70vh !important;
      overflow: auto;
      .wallet-address {
        @media only screen and(max-width: 480px) {
          max-width: 170px;
        }
      }
    }

    .card-actions {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 97%;
      z-index: 1000;
      .btn {
        width: 100%;
        font-weight: bolder;
        transition: all 0.5s ease;
        z-index: 10000;
        color: var(--background-color);
        &:hover {
          letter-spacing: 5px;
        }
        &.buy-btn {
          background: var(--third-color);
        }
        &.sell-btn {
          background: rgb(255, 15, 15);
        }
      }
    }
  }
}
//slide right
.slide-right-enter {
  transform: translateX(-100%);
}
.slide-right-enter-active,
.slide-right-leave-active {
  transition: all 0.7s;
}
.slide-right-enter-to,
.slide-right-leave-from {
  transform: translateX(0);
}
.slide-right-leave-to {
  transform: translateX(100%);
}

//slide left
.slide-left-enter {
  transform: translateX(100%);
}
.slide-left-enter-active,
.slide-left-leave-active {
  transition: all 0.7s;
}
.slide-left-enter-to,
.slide-left-leave-from {
  transform: translateX(0);
}
.slide-left-leave-to {
  transform: translateX(-100%);
}
</style>
