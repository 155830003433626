<template>
  <section class="story py-3 px-2">
    <h2 class="text-center yellow--text">{{ selectedLand.land.category.title }}</h2>
    <h3 class="text-center">{{ selectedLand.land.story }}</h3>
  </section>
</template>

<script>
export default {
  props: ["selectedLand"],
};
</script>

<style></style>
