<template>
  <section class="content">
    <section v-if="isLoading">
      <div class="no-item">
        <loading-spinner></loading-spinner>
      </div>
    </section>
    <section v-else>
      <div class="no-item" v-if="userBuildings.length <= 0">
        <h4 class="white--text">There is no item !</h4>
      </div>
      <v-row class="row" no-gutters v-else>
        <UserBuildingItem
          v-for="(item, i) in userBuildings"
          :key="i"
          :item="item"
          @fetchUserBuildinges="fetchUserBuildings"
        />
      </v-row>
    </section>
  </section>
</template>

<script>
import UserBuildingItem from "./UserBuildingItem.vue";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      userBuildings: [],
      isLoading: false,
    };
  },
  components: {
    UserBuildingItem,
  },

  created() {
    this.fetchUserBuildings();
  },
  methods: {
    ...mapActions(["getUserBuildings"]),
    fetchUserBuildings() {
      this.isLoading = true;
      this.getUserBuildings().then((result) => {
        this.userBuildings = result.result;
        this.isLoading = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.transfar-card {
  .card-title {
    background: grey !important;
  }
}
</style>
