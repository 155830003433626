<template>
  <v-row class="row" justify="center">
    <PackContent
      v-for="(item, i) in items"
      :key="i"
      :item="item"
      @packItemShowDetails="packItemShowDetails"
    ></PackContent>
  </v-row>
</template>

<script>
import PackContent from "./PackContent.vue";
export default {
  props: ["items"],
  components: { PackContent },
  methods: {
    packItemShowDetails(item) {
      this.$emit("packIShowDetails", item);
    },
  },
};
</script>

<style lang="scss" scoped>
.pc {
  transform: translate(1.5) !important;
}
</style>
