<template>
  <LandItem :item="item" @cancelSell="cancelSell" v-if="item.type === 'land'" />
  <LootboxItem
    :item="item"
    @cancelSell="cancelSell"
    v-else-if="item.type === 'lootbox'"
  />
  <BuildingItem
    :item="item"
    @cancelSell="cancelSell"
    v-else-if="item.type === 'building'"
  />
  <DecorativeItem
    :item="item"
    @cancelSell="cancelSell"
    v-else-if="item.type === 'decorative'"
  />
  <UnitItem
    :item="item"
    @cancelSell="cancelSell"
    v-else-if="item.type === 'unit'"
  />
  <CurrencyItem
    :item="item"
    @cancelSell="cancelSell"
    v-else-if="item.type === 'wallet'"
  />
  <LegendItem
    :item="item"
    @cancelSell="cancelSell"
    v-else-if="item.type === 'legend'"
  />
</template>

<script>
import LandItem from "../../shop/lands/LandItem.vue";
import LootboxItem from "../../shop/lootboxes/LootboxItem.vue";
import BuildingItem from "../../shop/buildings/BuildingItem.vue";
import DecorativeItem from "../../shop/decoratives/DecorativeItem.vue";
import UnitItem from "../../shop/units/UnitItem.vue";
import CurrencyItem from "../../shop/currencies/CurrencyItem.vue";
import LegendItem from "../../shop/legends/LegendItem.vue";
export default {
  props: ["item"],
  components: {
    LandItem,
    LootboxItem,
    BuildingItem,
    DecorativeItem,
    UnitItem,
    CurrencyItem,
    LegendItem,
  },
  methods: {
    cancelSell(item) {
      this.$emit("cancelSell", item);
    },
  },
};
</script>
