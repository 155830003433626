<template>
  <section class="for-sales">
    <section class="content">
      <section v-if="isLoading">
        <div class="no-item">
          <loading-spinner></loading-spinner>
        </div>
      </section>
      <section v-else>
        <div class="no-item" v-if="itemsForSale.length <= 0">
          <h4 class="white--text">There is no item !</h4>
        </div>
        <v-row class="row" no-gutters v-else>
          <SaleItem
            v-for="(item, i) in itemsForSale"
            :key="i"
            :item="item"
            @cancelSell="cancelSale"
          ></SaleItem>
        </v-row>
      </section>
    </section>
    <!--quastion dialog -->
    <v-dialog v-model="quastionDialog" width="500">
      <QuastionCard
        @cancel="quastionDialog = false"
        text="Are you sure to cancel sell this item ? "
        :isLoading="sellLoading"
        @accept="accept"
      ></QuastionCard>
    </v-dialog>
    <!--quastion dialog -->
  </section>
</template>

<script>
import SaleItem from "./SaleItem.vue";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      itemsForSale: [],
      isLoading: false,
      quastionDialog: false,
      selectedItem: null,
      sellLoading: false,
    };
  },
  components: {
    SaleItem,
  },
  created() {
    this.fetchUserForSales();
  },
  methods: {
    ...mapActions(["getUserForSales", "cancelSell"]),
    fetchUserForSales() {
      this.isLoading = true;
      const params = new URLSearchParams({});
      this.getUserForSales(params)
        .then((result) => {
          this.itemsForSale = result.data.data;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    cancelSale(item) {
      this.selectedItem = item;
      this.quastionDialog = true;
    },
    accept() {
      this.sellLoading = true;
      this.cancelSell(this.selectedItem.id).finally(() => {
        this.fetchUserForSales();
        this.sellLoading = false;
        this.quastionDialog = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.for-sales {
  .content {
    .row {
      .shop-button {
        background: rgb(132, 0, 0);
      }
    }
  }
}
</style>
