<template>
  <NoBalanceError
    :currencyName="selectedItem.name"
    @close="$emit('close')"
    v-if="type === 'wallet' && !selectedItem.amount"
  ></NoBalanceError>

  <v-card class="card" v-else>
    <v-card-title class="card-title">
      <h4 class="white--text">Transfer NFT to wallet</h4>
    </v-card-title>

    <v-card-text class="mt-5">
      <v-row>
        <v-col cols="6" class="d-flex flex-column justify-center">
          <div class="details d-flex justify-space-between">
            <h3 class="yellow--text">
              Type: <span class="grey--text">{{ type }}</span>
            </h3>
            <h3 class="yellow--text">
              ID: <span class="grey--text">{{ selectedItem.id }}</span>
            </h3>
          </div>
          <div
            style="
              width: 100%;
              height: 20px;
              border: 2px solid grey;
              border-radius: 5px;
              color: white;
            "
            class="d-flex justify-space-around my-5"
          >
            <v-icon color="white">mdi-arrow-down</v-icon>
            <h3>To</h3>
            <v-icon color="white">mdi-arrow-down</v-icon>
          </div>
          <v-form ref="form">
            <h3 class="yellow--text">
              Amount:
              <v-text-field
                type="number"
                :rules="amount_rules"
                v-model.trim="amount"
                placeholder="Enter your amount"
                outlined
                dense
                dark
                style="font-size: 14px"
                v-if="type === 'wallet'"
              >
                <template v-slot:append>
                  <i
                    class="fa-solid fa-hands-holding-circle"
                    style="font-size: 20px"
                  ></i>
                </template>
              </v-text-field>
              Wallet Address:
              <v-text-field
                :rules="rules.wallet_address_rules"
                v-model.trim="enteredWalletAddress"
                placeholder="Enter your wallet address"
                outlined
                dense
                dark
                append-icon="mdi-wallet"
                style="font-size: 10px"
              >
              </v-text-field>
            </h3>
          </v-form>

          <Or class="ma-0" v-if="!wallet_address"></Or>
          <v-btn
            color="success"
            class="w-100"
            text
            @click="walletConnect"
            v-if="!wallet_address"
            >Connect to wallet <v-icon right small>mdi-wallet</v-icon></v-btn
          >
        </v-col>
        <v-col cols="6" class="my-auto">
          <v-img
            v-if="selectedItem.image || selectedItem[type].image"
            :src="
              selectedItem.image
                ? storageImageURL(selectedItem.image)
                : storageImageURL(selectedItem[type].image)
            "
            width="100%"
            class="rounded"
            contain
            max-height="150"
          ></v-img>
          <v-img
            v-else
            src="https://www.salonlfc.com/wp-content/uploads/2018/01/image-not-found-1-scaled-1150x647.png"
            width="100%"
            class="rounded"
            contain
            max-height="150"
          ></v-img>
          <h4 class="white--text mt-2 text-center">
            {{
              selectedItem.name ? selectedItem.name : selectedItem[type].name
            }}
          </h4>
        </v-col>
      </v-row>
    </v-card-text>

    <v-divider dark></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="error" text @click="close"> Cancel </v-btn>
      <v-btn color="success" text @click="submitTransfer" :loading="isLoading">
        Transfer
      </v-btn>
    </v-card-actions>
    <!--wallet dialog -->
    <v-dialog v-model="walletDialog" width="500">
      <WalletDialogCard @close="walletDialog = false"></WalletDialogCard>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import NoBalanceError from "../currencies/NoBalanceError.vue";
import { storageImage } from "@/mixins/storageImage.js";
export default {
  mixins: [storageImage],
  props: ["selectedItem", "type"],
  data() {
    return {
      walletDialog: false,
      isLoading: false,
      enteredWalletAddress: null,
      amount: 1,
    };
  },
  components: {
    NoBalanceError,
  },
  computed: {
    ...mapGetters(["rules", "wallet_address"]),
    amount_rules() {
      return [
        (v) => !!v || "This Field is required",
        (v) => v <= this.selectedItem.amount || "Insufficent balance !",
      ];
    },
  },
  created() {
    if (this.wallet_address) {
      this.enteredWalletAddress = this.wallet_address;
    }
  },
  watch: {
    wallet_address(val) {
      this.enteredWalletAddress = val;
    },
  },
  methods: {
    ...mapActions(["transferNftToWallet"]),
    close() {
      this.$emit("close");
    },
    submitTransfer() {
      if (this.$refs.form.validate()) {
        let sendType = this.type
        let info = {
          withdrawable_id: this.selectedItem.id,
          withdrawable_type: `User${sendType.charAt(0).toUpperCase() + sendType.slice(1)}`,
          wallet_address: this.enteredWalletAddress,
          amount: this.amount,
        };
        this.isLoading = true;
        this.transferNftToWallet(info)
          .then(() => {
            this.$refs.form.reset();
            this.$refs.form.resetValidation();
          })
          .finally(() => {
            this.close();
            this.$emit("fetchAgain");
            this.isLoading = false;
          });
      }
    },
    walletConnect() {
      this.close();
      this.walletDialog = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/components/dialogCard.scss";
</style>
