<template>
  <v-col>
    <v-data-table
      :headers="headers"
      :items="localPayments"
      :loading="isLoading"
      :items-per-page="15"
      hide-default-footer
      class="table elevation-1 mt-3 white--text"
      dark
    >
      <template v-slot:top>
        <section class="head d-flex justify-center pa-3 white--text">
          <h3>Payment List</h3>
        </section>
      </template>
      <template v-slot:item.created_at="{ item }">
        {{ formatDate(item.created_at) }}
      </template>
    </v-data-table>

    <div class="table-paginate-box">
      <div class="table-pagination-total">
        {{ total }} <span class="">result(s)</span>
      </div>
      <div class="paginate-box">
        <v-pagination
          v-model="page"
          class="pagination"
          :length="lastPage"
          :total-visible="6"
          @input="fetchPayments()"
          color="blue"
          circle
        ></v-pagination>
      </div>
    </div>
  </v-col>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      isLoading: false,
      localPayments: [],
      page: 1,
      lastPage: null,
      total: 0,
      headers: [
        {
          text: "ID",
          align: "start",
          sortable: false,
          value: "id",
          class: "white--text",
        },
        { text: "Coin", value: "coin", class: "white--text" },
        { text: "Amount", value: "amount", class: "white--text" },
        { text: "Created at", value: "created_at", class: "white--text" },
        { text: "Status", value: "status", class: "white--text" },
      ],
    };
  },
  created() {
    this.fetchPayments();
  },
  methods: {
    ...mapActions(["getPaymentList"]),
    fetchPayments() {
      this.isLoading = true;
      this.getPaymentList(this.page).then((result) => {
        this.localPayments = result.data;
        this.total = result.meta.total;
        this.lastPage = result.meta.last_page;
        this.isLoading = false;
      });
    },
    formatDate(date) {
      if (date) {
        let newDate = new Date(date);
        return newDate.toLocaleString();
      }
    },
  },
};
</script>
