<template>
  <section>
    <div class="tags">
      <section class="px-5">
        <h5 v-if="tags.length <= 0">No items ...</h5>
        <div v-for="(item, i) in tags" :key="i">
          <section class="text-center mt-2">
            <h3 class="mr-1 yellow--text">{{ item.title }}</h3>
            <v-spacer></v-spacer>
            <p class="mr-5 yellow--text">
              <span class="grey--text">{{ item.description }}</span>
            </p>
          </section>
          <v-divider></v-divider>
        </div>
      </section>
    </div>
  </section>
</template>

<script>
export default {
  props: ["tags"],
  created() {},
};
</script>

<style></style>
