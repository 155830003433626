<template>
  <v-card class="card">
    <v-card-title class="card-title">
      <h3 class="white--text">Notice message !</h3>
      <v-spacer></v-spacer>
      <v-icon color="white" @click="$emit('close')">mdi-close-circle</v-icon>
    </v-card-title>
    <v-card-text class="py-5">
      <p class="orange--text text-center">
        <v-icon class="orange--text" small>mdi-alert-circle</v-icon> you have not any
        {{ currencyName }} to this action. {{ currencyName }} can only be collected by
        playing the
        <router-link :to="{ name: 'game' }">Lands of azollite</router-link>
      </p>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: ["currencyName"],
};
</script>

<style></style>
