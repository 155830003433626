<template>
  <section class="pack-item-details">
    <v-btn icon class="back-button" @click="$emit('toggleVisibility')">
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn>
    <v-img
      :src="
        selectedItem.details.image
          ? selectedItem.details.image
          : selectedItem.details.icon
      "
      class="image-bg"
      contain
    >
    </v-img>
    <main>
      <header>
        <v-img
          :src="
            selectedItem.details.image
              ? selectedItem.details.image
              : selectedItem.details.icon
          "
          class="image mx-auto mb-2"
          contain
        >
        </v-img>
        <h3 class="text-center">
          {{ selectedItem.amount && selectedItem.amount > 1 ? selectedItem.amount : "" }}
          {{ selectedItem.details.name }}
        </h3>
        <h5 class="text-center mb-2" style="color: #d4d4d4">
          {{ selectedItem.details.description ? selectedItem.details.description : "" }}
        </h5>
      </header>
      <div class="informations" v-if="selectedItem.type === 'Unit'">
        <h4 class="item">
          <span class="yellow--text">Housing space</span>
          <span>{{ selectedItem.details.housing_space }}</span>
        </h4>
        <h4 class="item">
          <span class="yellow--text">Attack index</span>
          <span>{{ selectedItem.details.attack_index }}</span>
        </h4>
        <h4 class="item">
          <span class="yellow--text">Defence index</span>
          <span>{{ selectedItem.details.defence_index }}</span>
        </h4>
      </div>
      <div class="informations" v-if="selectedItem.type === 'UserLand'">
        <h4 class="item">
          <span class="yellow--text">Country</span>
          <span>{{ selectedItem.details.parentCategory }}</span>
        </h4>
        <h4 class="item">
          <span class="yellow--text">Area</span>
          <span>{{ selectedItem.details.category }}</span>
        </h4>
        <h4 class="item">
          <span class="yellow--text">Landscape</span>
          <span>{{ selectedItem.details.landscape }}</span>
        </h4>
        <h4 class="item">
          <span class="yellow--text">Specifications</span>
          <span v-for="(tag, i) in selectedItem.details.tags" :key="i">{{ tag }}</span>
        </h4>
      </div>
      <div class="informations" v-if="selectedItem.type === 'Building'">
        <h4 class="item">
          <span class="yellow--text">Boost cost</span>
          <span
            >{{ selectedItem.details.boost_cost.value }}
            {{ selectedItem.details.boost_cost.coin }}</span
          >
        </h4>
        <h4 class="item">
          <span class="yellow--text">Construction cost</span>
          <span
            >{{ selectedItem.details.construction_cost.value }}
            {{ selectedItem.details.construction_cost.coin }}</span
          >
        </h4>
        <h4 class="item">
          <span class="yellow--text">Destruction cost</span>
          <span
            >{{ selectedItem.details.destruction_cost.value }}
            {{ selectedItem.details.destruction_cost.coin }}</span
          >
        </h4>
        <h4 class="item">
          <span class="yellow--text">Construction time</span>
          <span>{{ selectedItem.details.construction_time }}</span>
        </h4>
        <h4 class="item">
          <span class="yellow--text">Depreciation time</span>
          <span>{{ selectedItem.details.depreciation_time }}</span>
        </h4>
        <h4 class="item">
          <span class="yellow--text">Destruction time</span>
          <span>{{ selectedItem.details.destruction_time }}</span>
        </h4>
        <h4 class="item">
          <span class="yellow--text">Experience</span>
          <span>{{ selectedItem.details.experience }}</span>
        </h4>
        <h4 class="item">
          <span class="yellow--text">Functionality</span>
          <span>{{ selectedItem.details.functionality }}</span>
        </h4>
        <h4 class="item">
          <span class="yellow--text">Power</span>
          <span>{{ selectedItem.details.power }}</span>
        </h4>
        <h4 class="item">
          <span class="yellow--text">Prestige</span>
          <span>{{ selectedItem.details.prestige }}</span>
        </h4>
      </div>
      <div class="informations" v-if="selectedItem.type === 'Lootbox'">
        <v-row class="pt-2" no-gutters v-if="selectedItem.details.awards.length">
          <Award
            v-for="(award, i) in selectedItem.details.awards"
            :key="i"
            :award="award"
          ></Award>
        </v-row>
      </div>
      <div class="informations" v-if="selectedItem.type === 'Coin'">
        <h4 class="item">
          <span class="yellow--text">Amount</span>
          <span>{{ selectedItem.amount }}</span>
        </h4>
        <h4 class="item">
          <span class="yellow--text">Symbol</span>
          <span>{{ selectedItem.details.symbol }}</span>
        </h4>
      </div>
    </main>
  </section>
</template>

<script>
import Award from "../../../lootboxDetails/tabs/award/Award.vue";
export default {
  props: ["selectedItem"],
  data() {
    return {};
  },
  components: {
    Award,
  },
  created() {
    
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.pack-item-details {
  position: absolute;
  display: flex;
  flex-direction: column;
  height: 88%;
  max-height: 88%;
  overflow-y: auto;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 50px;
  .image-bg {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: 0.5;
    filter: blur(5px);
  }
  main {
    z-index: 10;
    display: flex;
    flex-direction: column;
    .image {
      width: 100%;
      max-width: 230px;
      border-radius: 5px;
    }
    .informations {
      .item {
        display: flex;
        justify-content: space-between;
        margin-top: 1%;
        padding: 5px 20px;
        border-radius: 5px;
        background: linear-gradient(to right, transparent, #131a39, transparent);
        @media only screen and(max-width: 500px) {
          font-size: 12px;
        }
      }
    }
  }
  .back-button {
    position: absolute;
    left: 2%;
    top: 2%;
    z-index: 1000;
    background: rgb(12, 68, 96);
    border-radius: 50%;
    padding: 5px;
    cursor: pointer;
  }
}
</style>
